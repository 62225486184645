import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { getCompanyData } from 'src/app/_model/company';
import { VesselStepper, getAddons, getVesselData, getapplications, ids, postVesselApplications, postVesselData, vesselForm } from 'src/app/_model/vessel';
import { CompanyService } from 'src/app/_service/company.service';
import { UserService } from 'src/app/_service/user.service';
import { VesselService } from 'src/app/_service/vessel.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { TreeNodeSelectEvent } from 'primeng/tree';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as routing from 'src/app/_model/routing';
import { VesselEditComponent } from '../vessel-edit/vessel-edit.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-vessel-register',
  templateUrl: './vessel-register.component.html',
  styleUrls: ['./vessel-register.component.css'],
})
export class VesselRegisterComponent implements OnInit, OnDestroy {
  @Output() dataChange = new EventEmitter();
  token = localStorage.getItem('token');

  constructor(
    private location: Location,
    private companyService: CompanyService,
    private vesselService: VesselService,
    private userService: UserService,
    private http: HttpClient,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private router: Router,
    private dialog: MatDialog,

  ) {
    this.companyList = this.companyService.companyList;
    this.iconRegistry.addSvgIcon(
      'vessel',
         sanitizer.bypassSecurityTrustResourceUrl('/assets/vessel.svg')
    );
    this.iconRegistry.addSvgIcon(
      'zoom',
         sanitizer.bypassSecurityTrustResourceUrl('/assets/zoom.svg')
    );
    this.iconRegistry.addSvgIcon(
      'zoomin',
         sanitizer.bypassSecurityTrustResourceUrl('/assets/zoomin.svg')
    );
  }
  vesselStepper = new VesselStepper();
  sectionOneForm = this.vesselStepper.sectionOneFormGroup;
  sectionTwoForm = this.vesselStepper.sectionTwoFormGroup;
  sectionTreeForm = this.vesselStepper.sectionThreeFormGroup;
  sectionFourForm = this.vesselStepper.sectionFourFormGroup;
  sectionFiveForm = this.vesselStepper.sectionFiveFormGroup;
  sectionSixForm = this.vesselStepper.sectionSixFormGroup;
  sectionEightFrom = this.vesselStepper.sectionEightFormGroup;
  businessActivityies = this.vesselStepper.businesSection;
  form = new vesselForm;
  vesselForm = this.form.defaultForm;
  editVesselData!: postVesselData;
  editMode = false;
  shortID ='';
  status: "initial" | "uploading" | "success" | "fail" = "initial";
  statusImg: "initial" | "uploading" | "success" | "fail" = "initial";
  statusApp: "initial" | "uploading" | "success" | "fail" = "initial";
  file: File | null = null;
  image: File | null = null;
  selectedImage!: string ;
  isImageChanged = false;
  companyList: getCompanyData[];
  selectedManager!: '';
  selectedOwner!: '';
  selectedBuilder!: '';
  selectedMaintainer!: '';
  selectedexowner!: '';
  selectedexmanager!: '';
  isReadOnly: boolean = true;
  selectedVesselType: any = '';
  isSubmitting = false;
  updated = false;
  mainList!: any[];
  primeNgDataset:  any[]=[]
  primeNgTreeDataSet:  any[]=[]
  isCompleted: boolean = false;
  applicationList: getapplications[]= [];
  addonList : getAddons[] = [];
  nodes: any[] = [];
  selectedNodes:any[] = [];
  condition = true;
  selectedSocity1!: '';
  selectedSocity2!: '';
  selectedMEType: any = '';
  // selectedList = new Map();
  selectedList: Map<string, string> = new Map<string, string>();
  selectedcapacityList: Map<string, string> = new Map<string, string>();
  vesselTypes= [
    {"type": "Vessel"},
    {"type": "SEP"}];
  engineTypes = [
    {"type":"V"},
    {"type":"S"}
  ]
  structuralDesignTypes=[
    {"type": "Monohull"},
    {"type":"Multihull"},
    {"type": "Double Hull"},
  ]
  operationalStatus=[
    {"type": "In operation"},
    {"type":"Not in operation"}
  ]
  RudderTypes=[
    {"type": "Balanced"},
    {"type": "Spade"},
    {"type": "Skeg Hung"},
    {"type": "Full Spade"}
  ]
  PropellerTypes =[
    {"type": "CPP"},
    {"type": "VPP"},
  ]
  classificationSocietyType=[
    {"type": "NK"},
    {"type": "DNV"},
    {"type": "ABS"},
    {"type": "BV"},
    {"type": "CCS"},
    {"type": "CR"},
    {"type": "JG"},
    {"type": "KR"},
    {"type": "LR"},
    {"type": "RINA"},
    {"type": "Other"},
  ]
  engineFoUnitType=[
    {"type": "l (liter)", "value": "l"},
    {"type": "gal (gallon)", "value": "gal"},
    {"type": "bar (barrel)", "value": "bar"},
    {"type": "drum", "value": "drum"},
  ]
  ngOnInit(): void {
    this.getApplicationList()
    this.getAddons()
    if(this.vesselService.editVesselFlag){
      this.setEditVesselData(this.vesselService.editVesselData);
      // this.addToTempData(this.vesselService.editVesselData);
      this.editMode = true;
      this.getImage()
      this.getVesselApplications();
    }else{
      this.editMode = false;
      // this.getUserList()
    }
  }

  ngOnDestroy(): void {
    this.vesselForm.reset();
  }
  compareVesselTypes(type1: any, type2: any): boolean {
    return type1 && type2 ? type1.name === type2.name : type1 === type2;
  }
  tempdata!:postVesselData;

  // addToTempData(data: any):any{
  //   this.tempdata={
  //     name: data.name,
  //     otherName: data.otherName,
  //     imo: data.imo,
  //     vesselNumber: data.vesselNumber,
  //     firstClass: data.firstClass,
  //     secondClass: data.secondClass,
  //     thumbnailExtension: data.thumbnailExtension,
  //     owner_id: data.owner_id,
  //     manager_id: data.manager_id,
  //     builder_id: data.builder_id,
  //     maintener_id: data.maintener_id,
  //     exowner_id: data.exowner_id,
  //     exmanager_id:data.exmanager_id,
  //     METype: data.METype,
  //     NoME: data.NoME,
  //     NoDG: data.NoDG,
  //     NoSG: data.NoSG,
  //     MEEngineType: data.MEEngineType,
  //     bore: data.bore,
  //     stroke: data.stroke,
  //     cylinder: data.cylinder,
  //     cycle: data.cycle,
  //     meMaxKw: data.meMaxKw,
  //     normalMeMaxKw: data.meMaxKw,
  //     meMaxRpm: data.meMaxRpm,
  //     normalMeMaxRpm: data.normalMeMaxRpm,
  //     meMinRpm: data.meMinRpm,
  //     geMaxKw: data.geMaxKw,
  //     sgMaxKw: data.sgMaxKw,
  //     engineFoUnit: data.engineFoUnit,
  //     engineFoUnitFactor: data.engineFoUnitFactor,
  //     vesselType: data.vesselType,
  //     NoLeg: data.NoLeg,
  //     NoCrane: data.NoCrane,
  //     NoThruster: data.NoThruster,
  //     MEngine: data?.MEngine,
  //     exName: data.exName,
  //     mmsi: data.mmsi,
  //     officialNumber: data.officialNumber,
  //     signalLetters: data.signalLetters,
  //     flag: data.flag,
  //     portOfRegistry: data.portOfRegistry,
  //     builtPlace: data.builtPlace,
  //     structuralDesignType:data.structuralDesignType,
  //     purpose: data.purpose,
  //     operationalStatus: data.operationalStatus,
  //     NoCrewMembers: data.NoCrewMembers,
  //     notation: data.notation,
  //     steelCuttingDate: data.steelCuttingDate,
  //     keelLayingDate: data.keelLayingDate,
  //     launchDate: data.launchDate,
  //     deliveryDate: data.deliveryDate,
  //     meFuelConsumptionRate: data.meFuelConsumptionRate,
  //     geFuelConsumptionRate: data.geFuelConsumptionRate,
  //     NoRudders: data.NoRudders,
  //     rudderType: data.rudderType,
  //     propellerType: data.propellerType,
  //     bladeDiameter: data.bladeDiameter,
  //     NoBlades: data.NoBlades,
  //     minCPPAngle: data.minCPPAngle,
  //     maxCPPAngle:data.maxCPPAngle,
  //     NoTank: data.NoTank,
  //     totalLength: data.totalLength,
  //     waterlineLength: data.waterlineLength,
  //     LBP: data.LBP,
  //     width: data.width,
  //     draft: data.draft,
  //     mouldedDepth: data.mouldedDepth,
  //     netTonnage: data.netTonnage,
  //     grossTonnage: data.grossTonnage,
  //     deadweightTonnage: data.deadweightTonnage,
  //     // cargoCapacityUnit: data.cargoCapacityUnit,//need to remove
  //     // cargoCapacity: data.cargoCapacity,//need to remove
  //     passengerCapacity: data.passengerCapacity,
  //     // tankCapacity: data.tankCapacity,//need to remove
  //     bulbousBow: data.bulbousBow,
  //     meBuiltYear: data.meBuiltYear,
  //     L_w:data.L_w,
  //     C_B: data.C_B,
  //     eta_D: data.eta_D,
  //     maxSpeedKn: data.maxSpeedKn,
  //     trialSpeedList: data.trialSpeedList,
  //     trialPowerList: data.trialPowerList,
  //     hotelPower: data.hotelPower,
  //     shipType:data.shipType,
  //     builtYear:data.builtYear,
  //     callsign:data.callsign,
  //     salesRepresentativeName:data.salesRepresentativeName,
  //     salesAddress:data.salesAddress,
  //     salesEmail:data.salesEmail,
  //     salesTelephone:data.salesTelephone,
  //     salesAgentName:data.salesAgentName,
  //     salesAgentAddress:data.salesAgentAddress,
  //     salesAgentEmail:data.salesAgentEmail,
  //     salesAgentTelephone:data.salesAgentTelephone,
  //     vesselWarrantyStartDate:data.vesselWarrantyStartDate,
  //     vesselWarrantyBaseExpDate:data.vesselWarrantyBaseExpDate,
  //     vesselWarrantyExtExpDate:data.vesselWarrantyExtExpDate,
  //     vesselWarrantyExpDate:data.vesselWarrantyExpDate,
  //     cargoBaleM3: data.cargoBaleM3,
  //     cargoGrainM3: data.cargoGrainM3,
  //     cargoLiquidM3: data.cargoLiquidM3,
  //     cargoNoBulldozer: data.cargoNoBulldozer,
  //     cargoNoCar: data.cargoNoCar,
  //     cargoNoChassis: data.cargoNoChassis,
  //     cargoNoFeu: data.cargoNoFeu,
  //     cargoNoForklift: data.cargoNoForklift,
  //     cargoNoTeu: data.cargoNoTeu,
  //     cargoNoTrailer: data.cargoNoTrailer,
  //     cargoNoTrainCar: data.cargoNoTrainCar,
  //     cargoNoTruck: data.cargoNoTruck,
  //     cargoOtherCapacity: data.cargoOtherCapacity,
  //     cargoOtherUnit: data.cargoOtherUnit,
  //     cargoRefM3: data.cargoRefM3,
  //     tankCapacityFoM3: data.tankCapacityFoM3,
  //     tankCapacityFwM3: data.tankCapacityFwM3,
  //     tankCapacityWbM3: data.tankCapacityWbM3,
  //   };
  // }
  Onclick(){
    // this.router.navigate([routing.UserEdit]);
    this.vesselService.editVesselFlag=true;
    this.vesselService.editVesselData =this.tempdata;
    // this.router.navigate([routing.UserList]);

    this.dialog.open(VesselEditComponent,{
      width:'744px',
      height: '85vh',
      // panelClass: 'fullscreen-dialog'
    })
  }
  setEditVesselData(editData: postVesselData) {
    this.vesselForm.patchValue(editData);
  }

  getSelectedVesselId() {
    this.vesselService.uuidlongtoshort(this.newVesselId).subscribe({
      next: (value:any) => {
        this.shortID = value.shortuuid;
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        this.onUpload()
      }
    });
  }

  // manager selection
onmanagerSelection(): void {
    const company = this.companyList.find(company => company.name === this.selectedManager);

    this.vesselForm.patchValue({
      manager_id: company?.id.toString(),
    })
  }
//owner selection
ownerSelection(){
  const company = this.companyList.find(company => company.name === this.selectedOwner);


  this.vesselForm.patchValue({
    owner_id: company?.id.toString(),
  })
}
//builder selection
builderSelection(){
  const company = this.companyList.find(company => company.name === this.selectedBuilder);


  this.vesselForm.patchValue({
    builder_id: company?.id.toString(),
  })
}
//maintainer selection
maintainerSelection(){
  const company = this.companyList.find(company => company.name === this.selectedMaintainer);
  this.vesselForm.patchValue({
    maintener_id: company?.id.toString(),
  })
}
//exowner selection
exownerSelection(){
  const company = this.companyList.find(company => company.name === this.selectedexowner);
  this.vesselForm.patchValue({
    exowner_id: company?.id.toString(),
  })
}
//exmanager selection
exmanagerSelection(){
  const company = this.companyList.find(company => company.name === this.selectedexmanager);
  this.vesselForm.patchValue({
    exmanager_id: company?.id.toString(),
  })
}
//Upload image
  onSelect(event:any){
    this.statusImg = "initial";
    const file: File = event.target.files[0];
    if(file){
      this.image = file;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedImage = e.target.result;
        this.isImageChanged =true;
      };
      reader.readAsDataURL(file);

    }
  }
  onImageUpload(){
    this.statusImg = 'uploading';
    if(this.image){
      const uploadImage = this.vesselService.putVesselThumbnail(this.image);
      uploadImage.subscribe({
        error:(err) =>{
          console.log("Error Occured: " + err);
          this.statusImg = 'fail';
        },
        complete:() =>{
          console.log("Image Uploaded");
          this.statusImg = 'success';
        },

      })
    }
  }
  getImage() {
    this.vesselService.getVesselThumbnail().subscribe(
      {
        next: (response: any) => {
          const blob = new Blob([response], { type: 'image/png' });
          const url = window.URL.createObjectURL(blob);

          const img = new Image();
          img.src = url;
          this.selectedImage= url;
          const container = document.getElementById('imageContainer');
          if (container && img) {
            container.innerHTML = '';
            container.appendChild(img);
          }
          if(img){
            this.isImageChanged =true;
          }
        },
        error: (error: any) => {
          console.error('Error loading image', error);
        },
        complete: () => {
          console.log('Image loading complete');
        },
      }
    );
  }



  //vessel mapping
  onChange(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      this.status = "initial";
      this.file = file;
    }
  }
  onUpload() {
    if (this.file && this.shortID) {
      const upload$ = this.vesselService.uploadFile(this.file, this.shortID);
      this.status = 'uploading';

      upload$.subscribe({
        next: () => {
          this.status = 'success';
        },
        error: () => {
          this.status = 'fail';
        },
      });
    }
  }

  // get appplication list from api
  getApplicationList(){
    this.vesselService.getApplications().subscribe({
      next: (value) => {
        value.forEach(element => {this.applicationList.push(element)});
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        this.isCompleted=true
      }
    })
  }

  //get addon list from the api
  getAddons(){
    this.vesselService.getAddon().subscribe({
      next: (value:any) => {
        value.forEach((element:any) => {this.addonList.push(element)});
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        if(this.isCompleted){
          this.mainList= this.createTreeFormat(this.applicationList,this.addonList)
          this.primeNgDataset = this.createTreeFormatPrimeNg(this.applicationList,this.addonList)
        }
      }
    })
  }

 //create a tree structure from the application list and addon- for dropdown
createTreeFormat(applicationList: getapplications[], addonList: getAddons[]) {
  let main_len = applicationList.length;
  const treeRoots: any[] = [];

  for (let i = 0; i < main_len; i++) {
    let treeRoot = {
      key: i.toString(),
      id: applicationList[i].id,
      name: applicationList[i].name,
      description: applicationList[i].description,
      parent:'Subscription',
      children: addonList.map((addon, j) => ({
        key: `${i}${j}`,
        id: addon.id,
        name: addon.name,
        description: addon.description,
        company: addon.company,
      })),
    };

    treeRoots.push(treeRoot);
  }

  return treeRoots;
};

//for primeNg- for dropdown
createTreeFormatPrimeNg(applicationList: getapplications[], addonList: getAddons[]) {
  let main_len = applicationList.length;
  const treeRoots: any[] = [];

  for (let i = 0; i < main_len; i++) {
    let treeRoot = {
      key: i.toString(),
      data: applicationList[i].id,
      label: applicationList[i].name,
      parent:'Subscription',
      children: addonList.map((addon, j) => ({
        key: `${i}${j}`,
        data: addon.id,
        label: addon.name,
        company: addon.company,
      })),
    };
    treeRoots.push(treeRoot);
  }
  return treeRoots;
};

//emitters
onNodeSelect(event: TreeNodeSelectEvent) {
  const selectedNode = event.node;
  // Check if parent label is not null
  if (selectedNode.parent?.label != null) {
      const newRoot = {
          key: `${this.primeNgTreeDataSet.length}`,
          label: selectedNode.parent.label,
          data: selectedNode.parent.data,
          children: [
              {
                  key: `${this.primeNgTreeDataSet.length}-0`,
                  label: selectedNode.label,
                  data: selectedNode.data,
              }
          ]
      };

      // If the dataset is empty, push the new root directly
      if (this.primeNgTreeDataSet.length === 0) {
          this.primeNgTreeDataSet.push(newRoot);
      } else {
          // Check the availability of the parent
          const position = this.primeNgTreeDataSet.findIndex(item => item.label === selectedNode.parent?.label);

          if (position !== -1) {
              // Parent is available, check availability of the child
              const childPosition = this.primeNgTreeDataSet[position].children.findIndex((item: any) => item.label === selectedNode.label);

              if (childPosition === -1) {
                  // Child is not available, add the child
                  const childRoot = {
                      key: `${position}-${this.primeNgTreeDataSet[position].children.length}`,
                      label: selectedNode.label,
                      data: selectedNode.data,
                  };
                  this.primeNgTreeDataSet[position].children.push(childRoot);
              }
          } else {
              // Parent is not available, add the new root
              this.primeNgTreeDataSet.push(newRoot);
          }
      }


  }
}

//when chnages in selected list
editSelection(event: TreeNodeSelectEvent) {
  const selectedNode = event.node;
  const parentIndex = this.primeNgTreeDataSet.findIndex(item => item.label === selectedNode.parent?.label);
  if (parentIndex !== -1) {
      const childIndex = this.primeNgTreeDataSet[parentIndex].children.findIndex(
          (child: any) => child.label === selectedNode.parent?.children?.[0]?.label
      );
      if (childIndex !== -1) {
          // Get the label of the item being removed
          const removedLabel = this.primeNgTreeDataSet[parentIndex].children[childIndex].label;
          // Remove the item from the children array
          this.primeNgTreeDataSet[parentIndex].children.splice(childIndex, 1);
          // Remove the parent if it has no children
          if (this.primeNgTreeDataSet[parentIndex].children.length === 0) {
              this.primeNgTreeDataSet.splice(parentIndex, 1);
          }
          // Remove the item from selectedNodes array
        if (this.selectedNodes.length > 0) {
          const selectedNodesChildIndex = this.selectedNodes.findIndex(node => node.parent?.label === selectedNode.parent?.label);

          this.selectedNodes = this.selectedNodes.filter(e =>
            !(e.parent?.label === selectedNode.parent?.label && e.label === removedLabel)
          );
          if (this.primeNgTreeDataSet.length === 0) {
            this.selectedNodes = [];
          }
        }
      }
  }
}

//outputs from the UIsx
selectedIds: ids[] = [];
selectedItemSetup(dataset: any[]) {this.statusApp = "initial";
  dataset.forEach(item => {
      let childrenIds: any[] = [];
      if (item.children && item.children.length > 0) {
          childrenIds = item.children.map((child: any) => child.data);
      }
      const data  = {
           application_id: item.data,
          addon: childrenIds
      };
      this.selectedIds.push(data)
    //  return this.selectedIds;
    this.addApplications()
  });
}

// add application to the vesselapi

addApplications() {
  this.selectedIds.forEach((applicationId) => {
    const data: postVesselApplications = {
      vessel_id: this.newVesselId,
      application_id: applicationId.application_id,
      addon: applicationId.addon
    };
    this.vesselService.submitVesselApplications(data).subscribe({
      next: (value) => {
        this.statusApp = 'uploading';
      },
      error: (error) => {
        console.error(error);
        this.statusApp = 'fail';
      },
      complete: () => {
        this.statusApp = 'success';
      }
    });
  });
}

//get the application details for the edit form
receiveData: any[] =[];
getVesselApplications(){
  this.vesselService.getvesselApplicationList().subscribe({
    next: (value:any) => {
      value.forEach((a:any) => {
        this.receiveData.push(a)
      });
    },
    error: (error) => {
      console.error(error);
    },
    complete: () => {
      this.getDetails()
    }
  })
}

getDetails() {
  this.receiveData.forEach((a: any, i) => {
    let label;
    const matchedApplication = this.applicationList.find((b: any) => b.id === a.application_id);
    if (matchedApplication) {
    label = matchedApplication.name;
    }

    const matchingAddonsLabels = a.addon.map((c: any) => {
      const matchingAddon = this.addonList.find((d: any) => d.id === c);
      return matchingAddon ? matchingAddon.name : null;
    });

    let temp:any[]=[]
    matchingAddonsLabels.forEach((b: any, index:any) => {
      const children = {
        key: `${i}-${index}`,
        label: b,
        data: a.addon[index],
      }
      temp.push(children)
    })
    const node = {
      key: `${i}`,
      label: label,
      data: a.application_id,
      children: temp
    }
    this.primeNgTreeDataSet.push(node)
  });
}

onNodeUnselect(event: TreeNodeSelectEvent) {
  const unselectedNode = event.node;

  // Handle node unselection and update the tree dataset
  const parentIndex = this.primeNgTreeDataSet.findIndex(item => item.label === unselectedNode.parent?.label);

  if (parentIndex !== -1) {
    const childIndex = this.primeNgTreeDataSet[parentIndex].children.findIndex(
      (child: any) => child.label === unselectedNode.label
    );

    if (childIndex !== -1) {
      // Remove the unselected item from the children array
      this.primeNgTreeDataSet[parentIndex].children.splice(childIndex, 1);

      // Remove the parent if it has no children
      if (this.primeNgTreeDataSet[parentIndex].children.length === 0) {
        this.primeNgTreeDataSet.splice(parentIndex, 1);
      }
    }
  }
}

jumpCreateVessel() {
  this.router.navigate([routing.VesselRegistration]);
}

moveNext :boolean = false;
newVesselId!:string;
sd(){
  console.log( this.businessActivityies.value)
}

longIdToShort() {
  this.vesselService.uuidlongtoshort(this.newVesselId).subscribe({
    next: (value:any) => {
      this.shortID = value.shortuuid;
    },
  });
}
//create a alert
createAlert(){
  const body={
    emails: [
      "cagdas@jrcs.co.jp",
      "koga@jrcs.co.jp"
    ],
    offlineStatusMessage: {
      title: this.sectionEightFrom.value.offlineTitle,
      content: this.sectionEightFrom.value.offlineContent,
      htmlContent: this.sectionEightFrom.value.offlinehtmlContnet,
    },
    onlineStatusMessage: {
      title: this.sectionEightFrom.value.onlineTitle,
      content: this.sectionEightFrom.value.onlineContent,
      htmlContent: this.sectionEightFrom.value.onlinehtmlContnet,
    },
    delayDurationInSec: 3600
  }
  this.vesselService.createAlert(this.shortID, body).subscribe({
    next: (value:any) => {
      console.log(value);
    },
    error: (error) => {
      // console.error(error,body);
    },
    complete: () => {
      console.log('complete');
    }
  })
}
patchData(){
  this.ownerSelection();
  this.onmanagerSelection();
  this.builderSelection();
  this.maintainerSelection();
  this.exownerSelection();
  this.exmanagerSelection();
  this.vesselForm.patchValue({
    vesselType: this.selectedVesselType.type,
    MEEngineType: this.selectedMEType.type
  })

}
onNext(){
  this.patchData();
  this.isSubmitting = true;
  this.vesselForm.patchValue(this.sectionOneForm.value)
  this.vesselForm.patchValue(this.sectionTwoForm.value)
  this.vesselForm.patchValue(this.sectionTreeForm.value)
  this.vesselForm.patchValue(this.sectionFourForm.value)
  this.vesselForm.patchValue(this.sectionFiveForm.value)
  this.vesselForm.patchValue(this.sectionSixForm.value)
  this.patchData();
  this.vesselService.submitNewVessel(this.vesselForm.value).subscribe({
    next: (value: any) => {
      // this.isSubmitting= true;
      this.newVesselId = value.id;
    },
    error: (err) => {  window.alert('Something Went Wrong!'); this.isSubmitting= false;this.moveNext = false},
    complete: () => {
      window.alert('Vessel Created');
      this.updated = true;
      this.dataChange.emit();
      this.isSubmitting= false;
      this.moveNext = true;
      this.longIdToShort();
      this.submitBusinessActivities()
    }
  })
}
submitBusinessActivities(){
  const body ={
    'vessel_id': this.newVesselId,
    'remarks': this.businessActivityies.value.remarks,
    'update':this.businessActivityies.value.update,
    'budgetperiod':this.businessActivityies.value.budgetperiod,
    "activities": this.businessActivityies.value.activities,
    "salesmonth": this.businessActivityies.value.salesmonth,
    "proposaldetails": this.businessActivityies.value.proposaldetails,
    "completedinput": this.businessActivityies.value.completedinput,
    "lcstconfirm": this.businessActivityies.value.lcstconfirm,
    "nextrenewalsurveydate": this.businessActivityies.value.nextrenewalsurveydate,
    "nextrenewalsurveydetails": this.businessActivityies.value.nextrenewalsurveydetails,
    "nextdockdate": this.businessActivityies.value.nextdockdate,
    "nextdockdetails": this.businessActivityies.value.nextdockdetails,
  }
  this.vesselService.postBusinessActivity(body).subscribe({
    next: (value:any) => {
      console.log(value);
    },
    error: (error) => {
      console.error(error);
    },
    complete: () => {
      console.log('complete');
    }
  })
}
// file uploading section
upload_applications(){
  this.selectedItemSetup(this.primeNgTreeDataSet);
}
  onSubmit() {
    if (this.checkSubmit()) {
      if (this.editMode) {
        this.onImageUpload();
        // update existing vessel
        this.vesselService.submitExistingVessel(this.vesselForm.value)
          .subscribe({
            error: (err) => {  window.alert('Error Occurred'); },
            complete: () => {
              window.alert('Vessel Updated');
              this.updated = true;
              this.dataChange.emit();
            }
          });
      } else {
        // register new vessel
        // this.isSubmitting = true;
        // this.selectedItemSetup(this.primeNgTreeDataSet);
        // this.vesselService.submitNewVessel(this.vesselForm.value)
        //   .subscribe({
        //     next: (value: any) => {
        //       // this.isSubmitting= true;
        //       const newVesselId = value.id;
        //       // this.uploadnewImage(newVesselId);
        //       // this.addApplications(newVesselId);
        //     },
        //     error: (err) => { window.alert('Error Occurred');this.isSubmitting= false; },
        //     complete: () => {
        //       this.isSubmitting= false;
        //       window.alert('Vessel Registered');
        //       this.vesselForm.reset();
        //       this.selectedNodes = [];
        //       this.primeNgTreeDataSet= [];
        //       this.dataChange.emit();
        //     }
        //   });
      }
    }
  }

  uploadnewImage() {
    this.vesselService.selectedVesselID = this.newVesselId;
    this.onImageUpload();
  }

  checkSubmit() {
    if(window.confirm('Can I register this?')){
      return true;
    }else{
      return false;
    }
  }
  onClose(){
    this.vesselService.editVesselFlag= false;
    this.location.back();
  }

  goBack() {
    this.location.back();
  }

}
