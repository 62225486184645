{{vesselID}}
{{vesselApplicationID}}
<form [formGroup]="vesselApplicationForm" (ngSubmit)="onSubmit()" autocomplete="off">
  <mat-form-field appearance="fill">
    <mat-label>applicationID</mat-label>
    <input type="text" matInput formControlName="application_id">
  </mat-form-field>
  <div class="button-area">
    <button mat-raised-button color="primary" type="submit" [disabled]="!vesselApplicationForm.valid">Submit</button>
    <div>
      <button mat-raised-button color="basic" type="button" (click)="goBack()">Cancel</button>
    </div>
  </div>
</form>