import { FormControl, FormArray, FormGroup, Validators } from '@angular/forms'
export interface getVesselData {
    id: string,
    name: string,
    otherName: string,
    imo: string,
    vesselNumber: string,
    firstClass: string,
    secondClass: string,
    thumbnailExtension: string,
    owner_id: string,
    manager_id: string,
    builder_id: string,
    maintener_id: string,
    exowner_id: string,
    exmanager_id:string,
    METype: string,
    NoME: number,
    NoDG: number,
    NoSG: number,
    MEEngineType: string,
    bore: number,
    stroke: number,
    cylinder: number,
    cycle: number,
    meMaxKw: number,
    normalMeMaxKw: number,
    meMaxRpm: number,
    normalMeMaxRpm: number,
    meMinRpm: number,
    geMaxKw: number,
    sgMaxKw: number,
    engineFoUnit: string,
    engineFoUnitFactor: number,
    vesselType: string,
    NoLeg: string,
    NoCrane: string,
    NoThruster: string,
    MEngine: object | null,
    exName: string,
    mmsi: string,
    officialNumber: string,
    signalLetters: string,
    flag: string,
    portOfRegistry: string,
    builtPlace: string,
    structuralDesignType:string,
    purpose: string,
    operationalStatus: string,
    NoCrewMembers: string,
    notation: string,
    steelCuttingDate: string,
    keelLayingDate: string,
    launchDate: string,
    deliveryDate: string,
    meFuelConsumptionRate: number,
    geFuelConsumptionRate: number,
    NoRudders: number,
    rudderType: string,
    propellerType: string,
    bladeDiameter: number,
    NoBlades: number,
    minCPPAngle: number,
    maxCPPAngle:number,
    NoTank: string,
    totalLength: number,
    waterlineLength: number,
    LBP: number,
    width: number,
    draft: number,
    mouldedDepth: number,
    netTonnage: number,
    grossTonnage: number,
    deadweightTonnage: number,
    // cargoCapacityUnit: string,
    // cargoCapacity: number,
    passengerCapacity: number,
    // tankCapacity: number
    bulbousBow: number,
    meBuiltYear: number,
    L_w:number,
    C_B: number,
    eta_D: number,
    maxSpeedKn: number,
    trialSpeedList: number[]|null,
    trialPowerList: number[]|null,
    hotelPower: number,
    shipType:string,
    builtYear:number,
    callsign:string,
    salesRepresentativeName:string,
    salesAddress:string,
    salesEmail:string,
    salesTelephone:string,
    salesAgentName:string,
    salesAgentAddress:string,
    salesAgentEmail:string,
    salesAgentTelephone:string,
    vesselWarrantyStartDate:string,
    vesselWarrantyBaseExpDate:string,
    vesselWarrantyExtExpDate:string,
    vesselWarrantyExpDate:string,
    cargoBaleM3: number,
    cargoGrainM3: number,
    cargoLiquidM3: number,
    cargoNoBulldozer: number,
    cargoNoCar: number,
    cargoNoChassis: number,
    cargoNoFeu: number,
    cargoNoForklift: number,
    cargoNoTeu: number,
    cargoNoTrailer: number,
    cargoNoTrainCar: number,
    cargoNoTruck: number,
    cargoOtherCapacity: number,
    cargoOtherUnit: string,
    cargoRefM3: number,
    tankCapacityFoM3: number,
    tankCapacityFwM3: number,
    tankCapacityWbM3: number,
    createdAt: string,
    updatedAt: string,
    vesselManagement: {
      id:string,
      name: string,
      otherName: string,
      ceo: string,
      address: string,
      addressLineTwo: string,
      city: string,
      zipCode: string,
      telephone: string,
      fax: string,
      email: string,
      thumbnailExtension: string,
      businessContents: string,
      rank: string,
      country: string,
      typeOwner: number,
      typeManagement: number,
      typeBuilder: number,
      typeManufacturer: number,
      typeMaintainer: number,
      typeExOwner: number,
      typeExManagement: number,
      createdAt: string,
      updatedAt: string
    } | null,
    vesselMaintainer: {
        id:string,
        name: string,
        otherName: string,
        ceo: string,
        address: string,
        addressLineTwo: string,
        city: string,
        zipCode: string,
        telephone: string,
        fax: string,
        email: string,
        thumbnailExtension: string,
        businessContents: string,
        rank: string,
        country: string,
        typeOwner: number,
        typeManagement: number,
        typeBuilder: number,
        typeManufacturer: number,
        typeMaintainer: number,
        typeExOwner: number,
        typeExManagement: number,
        createdAt: string,
        updatedAt: string
    } | null,
    vesselOwner: {
      id:string,
      name: string,
      otherName: string,
      ceo: string,
      address: string,
      addressLineTwo: string,
      city: string,
      zipCode: string,
      telephone: string,
      fax: string,
      email: string,
      thumbnailExtension: string,
      businessContents: string,
      rank: string,
      country: string,
      typeOwner: number,
      typeManagement: number,
      typeBuilder: number,
      typeManufacturer: number,
      typeMaintainer: number,
      typeExOwner: number,
      typeExManagement: number,
      createdAt: string,
      updatedAt: string
    },
    vesselBuilder: {
      id:string,
      name: string,
      otherName: string,
      ceo: string,
      address: string,
      addressLineTwo: string,
      city: string,
      zipCode: string,
      telephone: string,
      fax: string,
      email: string,
      thumbnailExtension: string,
      businessContents: string,
      rank: string,
      country: string,
      typeOwner: number,
      typeManagement: number,
      typeBuilder: number,
      typeManufacturer: number,
      typeMaintainer: number,
      typeExOwner: number,
      typeExManagement: number,
      createdAt: string,
      updatedAt: string
    } | null,
    shortUuid: string
}

export interface postVesselData {
    name: string,
    otherName: string,
    imo: string,
    vesselNumber: string,
    firstClass: string,
    secondClass: string,
    thumbnailExtension: string,
    owner_id: string,
    manager_id: string,
    builder_id: string,
    maintener_id: string,
    exowner_id: string,
    exmanager_id:string,
    METype: string,
    NoME: number,
    NoDG: number,
    NoSG: number,
    MEEngineType: string,
    bore: number,
    stroke: number,
    cylinder: number,
    cycle: number,
    meMaxKw: number,
    normalMeMaxKw: number,
    meMaxRpm: number,
    normalMeMaxRpm: number,
    meMinRpm: number,
    geMaxKw: number,
    sgMaxKw: number,
    engineFoUnit: string,
    engineFoUnitFactor: number,
    vesselType: string,
    NoLeg: string,
    NoCrane: string,
    NoThruster: string,
    MEngine: object | null,
    exName: string,
    mmsi: string,
    officialNumber: string,
    signalLetters: string,
    flag: string,
    portOfRegistry: string,
    builtPlace: string,
    structuralDesignType:string,
    purpose: string,
    operationalStatus: string,
    NoCrewMembers: string,
    notation: string,
    steelCuttingDate: string,
    keelLayingDate: string,
    launchDate: string,
    deliveryDate: string,
    meFuelConsumptionRate: number,
    geFuelConsumptionRate: number,
    NoRudders: number,
    rudderType: string,
    propellerType: string,
    bladeDiameter: number,
    NoBlades: number,
    minCPPAngle: number,
    maxCPPAngle:number,
    NoTank: string,
    totalLength: number,
    waterlineLength: number,
    LBP: number,
    width: number,
    draft: number,
    mouldedDepth: number,
    netTonnage: number
    grossTonnage: number,
    deadweightTonnage: number,
    // cargoCapacityUnit: string,
    // cargoCapacity: number,
    passengerCapacity: number,
    // tankCapacity: number
    bulbousBow: number,
    meBuiltYear: number,
    L_w:number,
    C_B: number,
    eta_D: number,
    maxSpeedKn: number,
    trialSpeedList: number[]|null,
    trialPowerList: number[]|null,
    hotelPower: number,
    shipType:string,
    builtYear:number,
    callsign:string,
    salesRepresentativeName:string,
    salesAddress:string,
    salesEmail:string,
    salesTelephone:string,
    salesAgentName:string,
    salesAgentAddress:string,
    salesAgentEmail:string,
    salesAgentTelephone:string,
    vesselWarrantyStartDate:string,
    vesselWarrantyBaseExpDate:string,
    vesselWarrantyExtExpDate:string,
    vesselWarrantyExpDate:string,
    cargoBaleM3: number,
    cargoGrainM3: number,
    cargoLiquidM3: number,
    cargoNoBulldozer: number,
    cargoNoCar: number,
    cargoNoChassis: number,
    cargoNoFeu: number,
    cargoNoForklift: number,
    cargoNoTeu: number,
    cargoNoTrailer: number,
    cargoNoTrainCar: number,
    cargoNoTruck: number,
    cargoOtherCapacity: number,
    cargoOtherUnit: string,
    cargoRefM3: number,
    tankCapacityFoM3: number,
    tankCapacityFwM3: number,
    tankCapacityWbM3: number,
}

export interface showVesselData {

}

export interface vesselApplication {
    vessel_id: string,
    application_id: string,
    createdAt: string,
    updatedAt: string
}

export interface getapplications{
    id:string,
    name: string,
    redirectUrl: string,
    description: string,
    createdAt: string,
    updatedAt: string
}
export interface getAddons{
    id:string,
    name: string,
    description: string,
    company: string
}
export interface postVesselApplications{
    vessel_id: string,
    application_id: string,
    addon:any[]
}
export interface TreeNode {
    key: string;
    id: string;
    name: string;
    description: string;
    redirectUrl:string;
    children?: TreeNode[];
  }
export interface NewTree{
    parent: string;
    data: string;
    children: []
}
export interface ids{
    application_id:string,
    addon:any []
}

export class vesselForm {
    defaultForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        otherName: new FormControl(''),
        imo: new FormControl(''),
        vesselNumber: new FormControl(''),
        firstClass: new FormControl(''),
        secondClass: new FormControl(''),
        thumbnailExtension: new FormControl(''),
        owner_id: new FormControl('',[Validators.required]),
        exowner_id:new FormControl('',[Validators.required]),
        exmanager_id:new FormControl('',[Validators.required]),
        manager_id: new FormControl(''),
        builder_id: new FormControl(''),
        maintener_id: new FormControl(''),
        METype: new FormControl(''),
        NoME: new FormControl(-1),
        NoDG: new FormControl(-1),
        NoSG: new FormControl(-1),
        MEEngineType: new FormControl(''),
        bore: new FormControl(-1),
        stroke: new FormControl(-1),
        cylinder: new FormControl(-1),
        cycle: new FormControl(-1),
        meMaxKw: new FormControl(-1),
        normalMeMaxKw: new FormControl(-1),
        meMaxRpm: new FormControl(-1),
        normalMeMaxRpm: new FormControl(-1),
        meMinRpm: new FormControl(-1),
        geMaxKw: new FormControl(-1),
        sgMaxKw: new FormControl(-1),
        engineFoUnit: new FormControl(''),
        engineFoUnitFactor: new FormControl(-1),
        vesselType: new FormControl(''),
        NoLeg: new FormControl(''),
        NoCrane: new FormControl(''),
        NoThruster: new FormControl(''),
        MEngine: new FormControl(),
        exName: new FormControl(''),
        mmsi: new FormControl(''),
        officialNumber: new FormControl(''),
        signalLetters: new FormControl(''),
        flag: new FormControl(''),
        portOfRegistry: new FormControl(''),
        builtPlace: new FormControl(''),
        structuralDesignType: new FormControl(''),
        purpose: new FormControl(''),
        operationalStatus: new FormControl(''),
        NoCrewMembers: new FormControl('-1'),
        notation: new FormControl(''),
        steelCuttingDate: new FormControl(''),
        keelLayingDate: new FormControl(''),
        launchDate: new FormControl(''),
        deliveryDate: new FormControl(''),
        meFuelConsumptionRate: new FormControl(-1),
        geFuelConsumptionRate: new FormControl(-1),
        NoRudders: new FormControl(-1),
        rudderType: new FormControl(''),
        propellerType: new FormControl(''),
        bladeDiameter: new FormControl(-1),
        NoBlades: new FormControl(-1),
        minCPPAngle: new FormControl(-1),
        maxCPPAngle: new FormControl(-1),
        NoTank: new FormControl('-1'),
        totalLength: new FormControl(-1),
        waterlineLength: new FormControl(-1),
        LBP: new FormControl(-1),
        width: new FormControl(-1),
        draft: new FormControl(-1),
        mouldedDepth: new FormControl(-1),
        netTonnage: new FormControl(-1),
        grossTonnage: new FormControl(-1),
        deadweightTonnage: new FormControl(-1),
        // cargoCapacityUnit: new FormControl(''),
        // cargoCapacity: new FormControl(-1),
        passengerCapacity: new FormControl(-1),
        // tankCapacity: new FormControl(-1),
        bulbousBow: new FormControl(-1),
        meBuiltYear: new FormControl(-1),
        L_w: new FormControl(-1),
        C_B: new FormControl(-1),
        eta_D: new FormControl(-1),
        maxSpeedKn: new FormControl(-1),
        trialSpeedList: new FormControl(),//new FormArray<FormControl<number>>([]),
        trialPowerList: new FormControl(),//new FormArray<FormControl<number>>([]),
        hotelPower: new FormControl(-1),
        shipType:new FormControl(''),
        builtYear:new FormControl(-1),
        callsign:new FormControl(''),
        salesRepresentativeName:new FormControl(''),
        salesAddress:new FormControl(''),
        salesEmail:new FormControl(''),
        salesTelephone:new FormControl(''),
        salesAgentName:new FormControl(''),
        salesAgentAddress:new FormControl(''),
        salesAgentEmail:new FormControl(''),
        salesAgentTelephone:new FormControl(''),
        vesselWarrantyStartDate:new FormControl(''),
        vesselWarrantyBaseExpDate:new FormControl(''),
        vesselWarrantyExtExpDate:new FormControl(''),
        vesselWarrantyExpDate:new FormControl(''),
        cargoBaleM3: new FormControl(-1),
        cargoGrainM3: new FormControl(-1),
        cargoLiquidM3: new FormControl(-1),
        cargoNoBulldozer: new FormControl(-1),
        cargoNoCar:new FormControl(-1),
        cargoNoChassis: new FormControl(-1),
        cargoNoFeu: new FormControl(-1),
        cargoNoForklift: new FormControl(-1),
        cargoNoTeu:new FormControl(-1),
        cargoNoTrailer: new FormControl(-1),
        cargoNoTrainCar: new FormControl(-1),
        cargoNoTruck: new FormControl(-1),
        cargoOtherCapacity: new FormControl(-1),
        cargoOtherUnit: new FormControl(''),
        cargoRefM3: new FormControl(-1),
        tankCapacityFoM3: new FormControl(-1),
        tankCapacityFwM3: new FormControl(-1),
        tankCapacityWbM3: new FormControl(-1),

    })
}

export class VesselStepper {
    sectionOneFormGroup = new FormGroup({
        name: new FormControl(''),
        otherName: new FormControl(''),
        imo: new FormControl(''),
        vesselNumber: new FormControl(''),
        vesselType: new FormControl(''),
        shipType:new FormControl(''),
        builtYear:new FormControl(-1),
        callsign:new FormControl(''),
        hotelPower: new FormControl(-1),
        NoLeg: new FormControl(''),
        NoCrane: new FormControl(''),
        NoThruster: new FormControl(''),
        NoME: new FormControl(-1),
        firstClass: new FormControl(''),
        secondClass: new FormControl(''),
        owner_id: new FormControl(''),
        exowner_id:new FormControl(''),
        exmanager_id:new FormControl(''),
        manager_id: new FormControl(''),
        builder_id: new FormControl(''),
        maintener_id: new FormControl(''),
        exName: new FormControl(''),
        mmsi: new FormControl(''),
        officialNumber: new FormControl(''),
        signalLetters: new FormControl(''),
        flag: new FormControl(''),
        portOfRegistry: new FormControl('')
    });

    sectionTwoFormGroup = new FormGroup({
        totalLength: new FormControl(-1),
        waterlineLength: new FormControl(-1),
        LBP: new FormControl(-1),
        width: new FormControl(-1),
        draft: new FormControl(-1),
        mouldedDepth: new FormControl(-1),
        netTonnage: new FormControl(-1),
        grossTonnage: new FormControl(-1),
        deadweightTonnage: new FormControl(-1),
        bulbousBow: new FormControl(-1),
        meBuiltYear: new FormControl(-1),
        L_w: new FormControl(-1),
        C_B: new FormControl(-1),
        eta_D: new FormControl(-1),
        maxSpeedKn: new FormControl(-1),
        builtPlace: new FormControl(''),
        structuralDesignType: new FormControl(''),
        purpose: new FormControl(''),
        operationalStatus: new FormControl(''),
    });

    sectionThreeFormGroup = new FormGroup({
        METype: new FormControl(''),
        MEEngineType: new FormControl(''),
        NoDG: new FormControl(-1),
        NoSG: new FormControl(-1),
        bore: new FormControl(-1),
        stroke: new FormControl(-1),
        cylinder: new FormControl(-1),
        cycle: new FormControl(-1),
        meMaxKw: new FormControl(-1),
        normalMeMaxKw: new FormControl(-1),
        meMaxRpm: new FormControl(-1),
        normalMeMaxRpm: new FormControl(-1),
        meMinRpm: new FormControl(-1),
        engineFoUnit: new FormControl(''),
        engineFoUnitFactor: new FormControl(-1),
        geMaxKw: new FormControl(-1),
        sgMaxKw: new FormControl(-1),
        meFuelConsumptionRate: new FormControl(-1),
        geFuelConsumptionRate: new FormControl(-1),
        NoRudders: new FormControl(-1),
        rudderType: new FormControl(''),
        propellerType: new FormControl(''),
        bladeDiameter: new FormControl(-1),
        NoBlades: new FormControl(-1),
        minCPPAngle: new FormControl(-1),
        maxCPPAngle: new FormControl(-1),
    });

    sectionFourFormGroup = new FormGroup({
        cargoBaleM3: new FormControl(-1),
        cargoGrainM3: new FormControl(-1),
        cargoLiquidM3: new FormControl(-1),
        cargoNoBulldozer: new FormControl(-1),
        cargoNoCar:new FormControl(-1),
        cargoNoChassis: new FormControl(-1),
        cargoNoFeu: new FormControl(-1),
        cargoNoForklift: new FormControl(-1),
        cargoNoTeu:new FormControl(-1),
        cargoNoTrailer: new FormControl(-1),
        cargoNoTrainCar: new FormControl(-1),
        cargoNoTruck: new FormControl(-1),
        cargoOtherCapacity: new FormControl(-1),
        cargoOtherUnit: new FormControl(''),
        cargoRefM3: new FormControl(-1),
        tankCapacityFoM3: new FormControl(-1),
        tankCapacityFwM3: new FormControl(-1),
        tankCapacityWbM3: new FormControl(-1),
        passengerCapacity: new FormControl(-1),
        NoTank: new FormControl('-1'),


    });

    sectionFiveFormGroup = new FormGroup({
        NoCrewMembers: new FormControl('-1'),
        notation: new FormControl(''),
        steelCuttingDate: new FormControl(''),
        keelLayingDate: new FormControl(''),
        launchDate: new FormControl(''),
        deliveryDate: new FormControl(''),
    });

    sectionSixFormGroup = new FormGroup({
        salesRepresentativeName:new FormControl(''),
        salesAddress:new FormControl(''),
        salesEmail:new FormControl(''),
        salesTelephone:new FormControl(''),
        salesAgentName:new FormControl(''),
        salesAgentAddress:new FormControl(''),
        salesAgentEmail:new FormControl(''),
        salesAgentTelephone:new FormControl(''),
        vesselWarrantyStartDate:new FormControl('',[Validators.required]),
        vesselWarrantyBaseExpDate:new FormControl('',[Validators.required]),
        vesselWarrantyExtExpDate:new FormControl('',[Validators.required]),
        vesselWarrantyExpDate:new FormControl('',[Validators.required]),
    });
    sectionSevenFormGroup = new FormGroup({
        thumbnailExtension: new FormControl(''),
    });

    sectionEightFormGroup = new FormGroup({
        offlineTitle: new FormControl(''),
        offlineContent: new FormControl(''),
        offlinehtmlContnet: new FormControl(''),
        onlineTitle: new FormControl(''),
        onlineContent: new FormControl(''),
        onlinehtmlContnet: new FormControl(''),
    });
    businesSection = new FormGroup({
        remarks: new FormControl(''),
        update: new FormControl(''),
        budgetperiod: new FormControl(''),
        activities: new FormControl(''),
        salesmonth: new FormControl(''),
        proposaldetails: new FormControl(''),
        completedinput: new FormControl(''),
        lcstconfirm: new FormControl(''),
        nextrenewalsurveydate: new FormControl(''),
        nextrenewalsurveydetails: new FormControl(''),
        nextdockdate: new FormControl(''),
        nextdockdetails: new FormControl('')
    })
    }

export class vesselApplicationForm {
    defaultForm = new FormGroup({
        application_id: new FormControl('', [Validators.required])
    })
}
