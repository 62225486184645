import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CompanyService } from 'src/app/_service/company.service';
import { VesselService } from 'src/app/_service/vessel.service';

import * as routing from '../../../_model/routing';
import { getVesselData, postVesselData } from 'src/app/_model/vessel';
import { VesselRegisterComponent } from '../vessel-register/vessel-register.component';
import { StreamingHttpUtil } from 'src/app/_common/common.streaminghttp';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatSort } from '@angular/material/sort';
import { VesselEditComponent } from '../vessel-edit/vessel-edit.component';
import { LazyLoadEvent } from 'primeng/api';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

interface Column {
  field: string;
  header: string;
}
@Component({
  selector: 'app-vessel-list',
  templateUrl: './vessel-list.component.html',
  styleUrls: ['./vessel-list.component.css']
})
export class VesselListComponent implements OnInit{
  @Output() dataChange = new EventEmitter();
  @ViewChild('table', {read: ElementRef}) table!: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  selectedRowIndex: number = -1;
  // dataSource: MatTableDataSource<getVesselData>;
  vesselList: any = [];
  isSearched: boolean = false;
  isSearching: boolean = false;
  vesselCache: any = {};
  dataload: boolean = true;
  cols!: Column[];
  private vesselCountSubscription!: Subscription;
  private vesselListSubscription!: Subscription;
  vesselCount!: number;
  vesselCounter = 0;
  constructor(
    private location: Location,
    private router: Router,
    private companyService: CompanyService,
    private vesselService: VesselService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {
  }

  selectedRow: any;
  vesselData!: postVesselData;
  vesselID = '';
  selected: any;
  pageEvent: any;
  pageIndex: any;
  limit = 50;
  length: number = 0;
  loading: boolean = false;
  completed: boolean = false;
  ngOnInit(): void {
    this.vesselService.resetSelectedVesselID();
    //testing
    this.cols = [
      { field: 'name', header: 'Vessel Name' },
      { field: 'tele', header: 'Contact Information' },
      { field: 'owner_id', header: 'Owner ID' },
      { field: 'manager_id', header: 'Manager ID' },
      { field: 'vesselNumber', header: 'Shipyard ID' },
      { field: 'updatedAt', header: 'Last Updated' }
    ];
    this.pageIndex = 0;
    this.getVesselListPageOne();
    this.vesselService.getVesselChanged().subscribe(vesselChanged => {
      if (vesselChanged) {
        this.vesselCache = {};
        this.updatePageData(this.pageIndex, this.limit);
        this.vesselService.vesselListUpdate();
      }
    });
  }
  getVesselCount() {
    this.vesselCountSubscription = this.vesselService.getVesselCount().subscribe({
      next: (value) => {
        this.vesselCount = value.count;
        this.vesselCounter = value.count;
      },
      error: (error) => {
        console.error('Error fetching vessel count:', error);
        this.loading = false;
      },
      complete: () => {
        this.completed=true
      }
    });
  }

  getVesselListPageOne() {
    this.vesselService.getVesselCount().subscribe({
      next: (value) => {
        this.vesselCount = value.count;
        this.length = value.count;
        this.fetchOnePageData(0, this.limit);
      }
    });
  }

  onPageChange(event?: PageEvent) {
    this.pageIndex = event?.pageIndex;
    this.fetchOnePageData(this.pageIndex, this.limit);
  }

  updatePageData(pageIndex: number, limit: number) {
    const offset = pageIndex * limit;
    this.vesselListSubscription = this.vesselService.getVesselWithLimitAndOffset(offset, limit).subscribe({
      next: async(value:any) => {
        this.loading = false;
        this.dataload = true;
        this.vesselList = value
        this.vesselCache[pageIndex] = value;
      },
      error: (error) => {
        console.error('Error fetching vessels:', error);
        this.loading = false;
      },
    });
  }

  fetchOnePageData(pageIndex: number, limit: number) {
    if (pageIndex in this.vesselCache) {
      this.vesselList = this.vesselCache[pageIndex];
    }
    else {
      if (!this.isSearched)
        this.loading = true;
      const offset = pageIndex * limit;
      this.vesselListSubscription = this.vesselService.getVesselWithLimitAndOffset(offset, limit).subscribe({
        next: async(value:any) => {
          this.dataload = true;
          if (!this.isSearched)
            this.vesselList = value;
          this.vesselCache[pageIndex] = value;
        },
        error: (error) => {
          console.error('Error fetching vessels:', error);
        },
        complete: () => {
          this.loading = false;
        },
      });
    }
  }

  onButtonClick(selectedData: any){
    this.vesselID = selectedData.id;
    this.vesselService.selectedVesselID = this.vesselID;
    this.addToVesselData(selectedData);
  }
  addToVesselData(data:getVesselData){
    this.vesselData={
      name: data.name,
      otherName: data.otherName,
      imo: data.imo,
      vesselNumber: data.vesselNumber,
      firstClass: data.firstClass,
      secondClass: data.secondClass,
      thumbnailExtension: data.thumbnailExtension,
      owner_id: data.owner_id,
      manager_id: data.manager_id,
      builder_id: data.builder_id,
      maintener_id: data.maintener_id,
      exowner_id: data.exowner_id,
      exmanager_id:data.exmanager_id,
      METype: data.METype,
      NoME: data.NoME,
      NoDG: data.NoDG,
      NoSG: data.NoSG,
      MEEngineType: data.MEEngineType,
      bore: data.bore,
      stroke: data.stroke,
      cylinder: data.cylinder,
      cycle: data.cycle,
      meMaxKw: data.meMaxKw,
      normalMeMaxKw: data.meMaxKw,
      meMaxRpm: data.meMaxRpm,
      normalMeMaxRpm: data.normalMeMaxRpm,
      meMinRpm: data.meMinRpm,
      geMaxKw: data.geMaxKw,
      sgMaxKw: data.sgMaxKw,
      engineFoUnit: data.engineFoUnit,
      engineFoUnitFactor: data.engineFoUnitFactor,
      vesselType: data.vesselType,
      NoLeg: data.NoLeg,
      NoCrane: data.NoCrane,
      NoThruster: data.NoThruster,
      MEngine: data?.MEngine,
      exName: data.exName,
      mmsi: data.mmsi,
      officialNumber: data.officialNumber,
      signalLetters: data.signalLetters,
      flag: data.flag,
      portOfRegistry: data.portOfRegistry,
      builtPlace: data.builtPlace,
      structuralDesignType:data.structuralDesignType,
      purpose: data.purpose,
      operationalStatus: data.operationalStatus,
      NoCrewMembers: data.NoCrewMembers,
      notation: data.notation,
      steelCuttingDate: data.steelCuttingDate,
      keelLayingDate: data.keelLayingDate,
      launchDate: data.launchDate,
      deliveryDate: data.deliveryDate,
      meFuelConsumptionRate: data.meFuelConsumptionRate,
      geFuelConsumptionRate: data.geFuelConsumptionRate,
      NoRudders: data.NoRudders,
      rudderType: data.rudderType,
      propellerType: data.propellerType,
      bladeDiameter: data.bladeDiameter,
      NoBlades: data.NoBlades,
      minCPPAngle: data.minCPPAngle,
      maxCPPAngle:data.maxCPPAngle,
      NoTank: data.NoTank,
      totalLength: data.totalLength,
      waterlineLength: data.waterlineLength,
      LBP: data.LBP,
      width: data.width,
      draft: data.draft,
      mouldedDepth: data.mouldedDepth,
      netTonnage: data.netTonnage,
      grossTonnage: data.grossTonnage,
      deadweightTonnage: data.deadweightTonnage,
      // cargoCapacityUnit: data.cargoCapacityUnit,//need to remove
      // cargoCapacity: data.cargoCapacity,//need to remove
      passengerCapacity: data.passengerCapacity,
      // tankCapacity: data.tankCapacity,//need to remove
      bulbousBow: data.bulbousBow,
      meBuiltYear: data.meBuiltYear,
      L_w:data.L_w,
      C_B: data.C_B,
      eta_D: data.eta_D,
      maxSpeedKn: data.maxSpeedKn,
      trialSpeedList: data?.trialSpeedList,
      trialPowerList: data?.trialPowerList,
      hotelPower: data.hotelPower,
      shipType:data.shipType,
      builtYear:data?.builtYear,
      callsign:data.callsign,
      salesRepresentativeName:data.salesRepresentativeName,
      salesAddress:data.salesAddress,
      salesEmail:data.salesEmail,
      salesTelephone:data.salesTelephone,
      salesAgentName:data.salesAgentName,
      salesAgentAddress:data.salesAgentAddress,
      salesAgentEmail:data.salesAgentEmail,
      salesAgentTelephone:data.salesAgentTelephone,
      vesselWarrantyStartDate:data.vesselWarrantyStartDate,
      vesselWarrantyBaseExpDate:data.vesselWarrantyBaseExpDate,
      vesselWarrantyExtExpDate:data.vesselWarrantyExtExpDate,
      vesselWarrantyExpDate:data.vesselWarrantyExpDate,
      cargoBaleM3: data.cargoBaleM3,
      cargoGrainM3: data.cargoGrainM3,
      cargoLiquidM3: data.cargoLiquidM3,
      cargoNoBulldozer: data.cargoNoBulldozer,
      cargoNoCar: data.cargoNoCar,
      cargoNoChassis: data.cargoNoChassis,
      cargoNoFeu: data.cargoNoFeu,
      cargoNoForklift: data.cargoNoForklift,
      cargoNoTeu: data.cargoNoTeu,
      cargoNoTrailer: data.cargoNoTrailer,
      cargoNoTrainCar: data.cargoNoTrainCar,
      cargoNoTruck: data.cargoNoTruck,
      cargoOtherCapacity: data.cargoOtherCapacity,
      cargoOtherUnit: data.cargoOtherUnit,
      cargoRefM3: data.cargoRefM3,
      tankCapacityFoM3: data.tankCapacityFoM3,
      tankCapacityFwM3: data.tankCapacityFwM3,
      tankCapacityWbM3: data.tankCapacityWbM3,
    };
    this.vesselService.editVesselFlag = true;
    this.vesselService.editVesselData = this.vesselData;
    this.editVesselData();
  }
  editVesselData(){
    // this.dialog.open(VesselEditComponent,{
    //     width:'760px',
    //     height: '90vh'
    // });
    this.router.navigate([routing.VesselEdit])
  }
  jumpCreateVessel() {
    this.router.navigate([routing.VesselRegistration]);
  }

  searchVessel(vesselName: HTMLInputElement, vesselOwner: HTMLInputElement, vesselManager: HTMLInputElement) {
    if (!vesselName.value && !vesselOwner.value && !vesselManager.value) {
      if (this.vesselCache[0])
        this.vesselList = this.vesselCache[0];
      else
        this.fetchOnePageData(0, this.limit);
      this.isSearched = false;
      return;
    }
    this.loading = true;
    this.isSearching = true;
    this.isSearched = true;
    this.vesselService.getVesselByNameAndCompany(vesselName.value, vesselOwner.value, vesselManager.value).subscribe({
      next: async(value:any) => {
        this.dataload = true;
        this.vesselList = value
      },
      error: (error) => {
        console.error('Error fetching vessels:', error);
      },
      complete: () => {
        this.isSearching = false;
        this.loading = false;
      }
    });
  }
  clearSearch(vesselName: HTMLInputElement, vesselOwner: HTMLInputElement, vesselManager: HTMLInputElement) {
    vesselName.value = '';
    vesselOwner.value = '';
    vesselManager.value = '';
    if (this.vesselCache[this.pageIndex])
      this.vesselList = this.vesselCache[this.pageIndex];
    else
      this.fetchOnePageData(this.pageIndex, this.limit);
    this.isSearched = false;
  }
  getCompanyNameById(id: string) {
    const company = this.companyService.companyList.find(v => v.id == id);
    return company ? (company.name || company.otherName) : id;
  }
  copyVesselId(id: string) {
    navigator.clipboard.writeText(id);

    this.translate.get("copyMsg").subscribe((translatedText: string) => {
      this.snackBar.open(translatedText, "Close", {
        duration: 1300,
        panelClass: ["copy-msg"],
      });
    });
  }
  goBack() {
    this.companyService.skipListFlag = true;
    this.location.back();
  }
  ngOnDestroy():void{
    if (this.vesselCountSubscription) {
      this.vesselCountSubscription.unsubscribe();
    }
    if (this.vesselListSubscription) {
      this.vesselListSubscription.unsubscribe();
    }
  }
}
