export const environment = {
  production: false,
  msalConfig: {
    auth: {
      clientId: 'b1e3fa6b-9104-4275-ab2d-8342585f02c6',
      authority: 'https://login.microsoftonline.com/kikuho.onmicrosoft.com',
      redirectUri: 'https://dev-iportal.infinity-jrcs.com'
    }
  },
  msalResourceMap: [
    {resourceURL: "https://graph.microsoft.com/v1.0/me", scopes: ['user.read']},
    {resourceURL: "https://dev-apigateway.infinity-jrcs.com", scopes: ["openid", "profile", "email", "offline_access"]}
    ]
}