<div class="main">
    <div mat-dialog-title class="header">
        <h1>{{'editCompany' | translate}}</h1>
        <button *ngIf="editMode" (click)="onClose()" [mat-dialog-close] class="close-btn"><i class="pi pi-times" style="font-size: 19.89px; color: #828282ee"></i></button>
      </div>
      <hr class="styled-line">
      <br>
        <form [formGroup]="companyForm" (ngSubmit)="onSubmit()" autocomplete="off">
        <section class="scrollable-section">
          <div class="content">
            <div class="label-class">{{'companyInformation' | translate}}</div>   
            <div class="field-class">
              <div class="required-fields">
                <span class="field-label">*{{'companyName' | translate}}</span>
                <input type="text" class="input-field" matInput id="name" formControlName="name" placeholder="Enter a Company Name">
                <div *ngIf="companyForm.get('name')?.invalid && companyForm.get('name')?.touched" class="error-message">
                  <div *ngIf="companyForm.get('name')?.hasError('required')">This Field is Required</div>
                </div>
              </div>
            </div>
            <div class="field-class">
              <span class="field-label">{{'japaneseCompanyOtherName' | translate}}</span>
              <input type="text" class="input-field" matInput id="otherName" placeholder="Enter a Japanese Name" formControlName="otherName">
            </div>  
            <div class="field-class">
              <span class="field-label">{{'ceo' | translate}}</span>
              <input type="text" class="input-field" matInput id="ceo" placeholder="Enter a CEO name" formControlName="ceo">
            </div>  
            <div class="field-class">
              <span class="field-label">*{{'email' | translate}}</span>
              <input type="text" class="input-field" matInput required id="email" placeholder="example@email.com" formControlName="email">
              <div *ngIf="companyForm.get('email')?.invalid && companyForm.get('email')?.touched" class="error-message">
                <div *ngIf="companyForm.get('email')?.hasError('required')">This Field is required</div>
              </div>
            </div>  
            <div class="field-class">
              <div class="subgrid-container">
                <div>
                  <span class="field-label">{{'phone' | translate}}</span>
                  <input type="text" class="custom-input-sub" matInput id="telephone" placeholder="(+01)-0000-0000" formControlName="telephone"> 
                </div>
                <div>       
                  <span class="field-label">{{'fax' | translate}}</span>
                  <input type="text" class="custom-input-sub" matInput id="fax" placeholder="Enter Fax Number" formControlName="fax">
                </div>
              </div>
            </div>
            <div class="field-class">
              <mat-label class="field-label" formControlName="types" multiple ngDefaultControl>*Types</mat-label> 
              <div class="check-boxes" *ngFor="let companyType of companyTypes">
                <div class="checkbox-column">
                  <input type="checkbox" class="check" id="{{companyType.value}}" [name]=companyType.display [value]= companyType.value [checked]="companyForm.get(companyType.value)?.value == 1" (change)="onChange($event)">
                </div>
                <div class="label-column">
                  <label class="container" for="{{companyType.value}}">{{companyType.display}}</label>
                </div>
              </div>
            </div>
            <hr class="styled-line">
            <label class="label-class">{{'companyAddress' | translate}}</label>
            <div class="field-class">
                <span class="field-label">Address Line 1</span>
                <input type="text" class="input-field" matInput id="Address Line 1" placeholder="Enter Street Address" formControlName="address">
              </div>  
              <div class="field-class">
                <span class="field-label">{{'addressLine2' | translate}}</span>
                <input type="text" class="input-field" matInput id="address2" placeholder="Apartment, etc. (optional)" formControlName="addressLineTwo">
              </div> 
              <div class="field-class">
                <div class="subgrid-container">
                  <div style="display: flex;flex-direction: column;">
                    <span class="field-label">{{'city' | translate}}</span>
                    <input type="text" class="custom-input-sub" matInput id="city" placeholder="Enter City" formControlName="city"> 
                  </div>
                  <div>       
                    <span class="field-label">{{'zipCode' | translate}}</span>
                       <input type="text" class="custom-input-sub" matInput id="zip" placeholder="Enter Zip Code" formControlName="zipCode">
                  </div>
                </div>
              </div>
              <div class="field-class">
                <span class="field-label">{{'country' | translate}}</span>
                <input type="text" class="input-field" matInput id="country" placeholder="Enter Country" formControlName="country">
              </div>        
          </div>
        </section>
        <div class="btn-area">   
          <div style="display: flex;flex-direction: row; margin-right: 50px; margin-bottom: 20px; margin-top: -20px;">
            <button type="button" class="delete" (click)="onDelete()">{{'delete' | translate}}</button>
            <button type="button" (click)="onClose()" [mat-dialog-close]  class="cancel">Cancel</button>
            <button type="submit" class="submit">Save</button>
          </div>
        </div>
      </form>
    </div>
    