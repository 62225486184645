import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, retry } from 'rxjs';
import * as constants from '../_model/string_literal';
import { getAddons, getVesselData, getapplications, postVesselApplications, postVesselData, vesselApplication } from '../_model/vessel';
import { fromFetch } from 'rxjs/fetch';

@Injectable({
  providedIn: 'root'
})
export class VesselService {
  vesselList: getVesselData[] = [];
  associateVesselList: any[] = [];
  _vesselDataChanged = new BehaviorSubject<boolean>(false);
  vesselDataChanged: boolean = false
  vesselApplicationList: vesselApplication[] = [];
  selectedVesselID = '';
  editVesselData!: postVesselData;
  editVesselFlag = false;
  skipListFlag = false;
  isVesselPage = new BehaviorSubject<boolean>(false);

  token = localStorage.getItem('token');

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  httpOptionsAuth = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization':  this.token ? `Bearer ${JSON.parse(this.token)["token"]}` : ''
    })
  };
  httpOptionsWithUpload = {
    headers: new HttpHeaders({
      'Authorization': this.token ? `Bearer ${JSON.parse(this.token)["token"]}` : ''
    })
  };
  httpOptionsWithGetImage = {
    headers: new HttpHeaders({
      'Content-Type': 'image/png',
      'Authorization': this.token ? `Bearer ${JSON.parse(this.token)["token"]}` : ''
    })
  };
  constructor(
    private http: HttpClient,
  ) { }

  getVesselList() {
    return fromFetch(constants.IAPI_ENDPOINT + '/vessel', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.token ? `Bearer ${JSON.parse(this.token)["token"]}` : ''
      },
    });
    //return this.http.get<getVesselData[]>(constants.IAPI_ENDPOINT + '/vessel', this.httpOptionsAuth);
  }
  getVesselChanged() {
    return this._vesselDataChanged.asObservable();
  }
  vesselListUpdate() {
    this.vesselDataChanged = !this.vesselDataChanged;
    this._vesselDataChanged.next(this.vesselDataChanged);
  }
  //with offset and limit
  getVesselWithLimitAndOffset(offset: any, limit:any){
    return this.http.get(constants.IAPI_ENDPOINT + `/vessel?offset=${offset}&limit=${limit}&order=name%3Aasc`, this.httpOptionsAuth);
  }
  getVesselByNameAndCompany(name: string, owner: string, manager: string) {
    return this.http.get(constants.IAPI_ENDPOINT + `/vessel?name=${name}&vesselOwner=${owner}&vesselManagement=${manager}`, this.httpOptionsAuth);
  }
  getVesselListWithQuery(queryName:string, queryParam: string) {
    return this.http.get<getVesselData[]>(constants.IAPI_ENDPOINT + '/vessel?' + queryName + '=' + queryParam, this.httpOptionsAuth);
  }
  // vessel image implemetations
  putVesselThumbnail(file:File){
    const formData: FormData = new FormData();
    formData.append('file',file, file.name);
    return this.http.put(constants.IAPI_ENDPOINT + `/vessel/${this.selectedVesselID}/thumbnail`,formData, this.httpOptionsWithUpload);
  }
  //get Vessel image Implementation
  getVesselThumbnail(){
    return this.http.get(constants.IAPI_ENDPOINT + `/vessel/${this.selectedVesselID}/thumbnail`,{ ...this.httpOptionsWithGetImage, responseType: 'blob' as 'json'});
  }
  //vessel mapping file upload
  uuidlongtoshort(id:any){
    return this.http.get(constants.IAPI_ENDPOINT + `/vessel/uuidlongtoshort/${id}`, this.httpOptionsAuth);
  }
  uploadFile(file:File, ssid:string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(constants.IAPI_ENDPOINT + `/api/channelmapping/${ssid}`, formData,this.httpOptionsWithUpload);
  }
  getVesselData(id:any) {
    return this.http.get(constants.IAPI_ENDPOINT + `/vessel/${id}`, this.httpOptionsAuth);
  }

  getVesselCount() {
    return this.http.get<{count:number}>(constants.IAPI_ENDPOINT + '/vessel/count', this.httpOptionsAuth);
  }

  getVesselUserRole() {
    return this.http.get(constants.IAPI_ENDPOINT + '/vesselUserRole', this.httpOptionsAuth);
  }

  getVesselApplication() {
    return this.http.get<vesselApplication[]>(constants.IAPI_ENDPOINT + '/vesselApplication', this.httpOptionsAuth);
  }
  getApplications() {
    return this.http.get<getapplications[]>(constants.IAPI_ENDPOINT + '/application', this.httpOptionsAuth);
  }

  getAddon(){
    return this.http.get<getAddons>(constants.IAPI_ENDPOINT + '/addon', this.httpOptionsAuth);
  }

  getvesselApplicationList() {
    return this.http.get(constants.IAPI_ENDPOINT + `/vesselApplication?vessel_id=${this.selectedVesselID}`, this.httpOptionsAuth);
  }

  putVesselApplication(){
    
  }

  deleteVesselApplication() {
    
  }

  createAlert(shortId: any, data: any) {
    return this.http.post(constants.IAPI_ENDPOINT + `/api/alert/config/${shortId}`,data, this.httpOptionsAuth);
  }

  postBusinessActivity(data:any){
    return this.http.post(constants.IAPI_ENDPOINT + '/management/bizactivity',data, this.httpOptionsAuth);

  }
  submitVesselApplications(data: postVesselApplications){
    return this.http.post(constants.IAPI_ENDPOINT + '/vesselApplication', data,this.httpOptionsAuth);
  }
  submitNewVessel(vesselData: any) {
    return this.http.post(constants.IAPI_ENDPOINT + '/vessel/', JSON.stringify(vesselData), this.httpOptionsAuth);
  }

  submitExistingVessel(vesselData: any) {
    return this.http.put(constants.IAPI_ENDPOINT + '/vessel/' + `${this.selectedVesselID}`, JSON.stringify(vesselData), this.httpOptionsAuth);
  }

  submitVesselApplication(applicationData: any) {
    return this.http.post(constants.IAPI_ENDPOINT + '/vesselApplication', JSON.stringify(applicationData), this.httpOptionsAuth);
  }

  deleteVesselData() {
    return this.http.delete(constants.IAPI_ENDPOINT + '/vessel/' + `${this.selectedVesselID}`, this.httpOptionsAuth);
  }

  resetSelectedVesselID() {
    this.selectedVesselID = '';
  }

  get isVesselPageChanges() {
    return this.isVesselPage.asObservable();
  }

  checkVisitVesselPage(value: boolean) {
    this.isVesselPage.next(value);
  }
}
