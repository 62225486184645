import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { RoutingService } from './routing.service';

import { MicrosoftAccount } from '../_model/microsoft_account';
import { UserData } from '../_model/user_data';
import * as constants from '../_model/string_literal'
import { UserService } from './user.service';

export let userData: UserData;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userOID = '';
  userName = '';
  email = '';
  isAdmin = false;
  isUser = false;
  isThirdParty = false;
  isGlobalAdmin = false;
  notRegistered = true;

  constructor(
    private http: HttpClient,
    private router: RoutingService,
    private userService: UserService,
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  getUserData() {
    this.http.get<MicrosoftAccount>(constants.MICROSOFT_ACCOUNT_ENDPOINT)
      .subscribe({
      next: (data)  => {
        this.userOID = data.id;
      },
      error:(err) => {
        console.log(err);
      },
      complete: () => {
        this.loginToiAPI().subscribe({
          next: (data) => { localStorage.setItem('token', JSON.stringify({'token':data}))},
          error: (err) => {this.router.routingDashboard(true)},
          complete: () => {
            this.getLoginUserData()
            ?.subscribe({
              next: (userData) => {this.userName = userData.name;
                this.email = userData.email;
                this.checkRole(userData);
                this.router.routingDashboard(this.notRegistered);
              },             
            })
          }
        })
      }
    })
  }

  checkRole(data:UserData) {
    switch(data.role_id) {
      case 0:
        // this.isAdmin = true;
        this.notRegistered = true;
        break;
      case 1:
        // this.isUser = true;
        this.notRegistered = true;
        break;
      case 2:
        // this.isThirdParty = true;
        this.notRegistered = true;
        break;
      case 3:
        this.isGlobalAdmin = true;
        this.notRegistered = false;
        break;
      default:
        this.notRegistered = true;
    }
  }

  loginToiAPI() {
    return this.http.post<{oid: string}>(constants.IAPI_ENDPOINT + '/api/auth/login/oid', JSON.stringify({oid: this.userOID}), this.httpOptions)
  }
  getLoginUserData(){
    const token = localStorage.getItem('token');
    if (token === null || token === undefined){
      return
    }
    return this.http.get<UserData>(constants.IAPI_ENDPOINT + '/user/logged/user',{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':  `Bearer ${JSON.parse(token)["token"]}`
      })
    } );
  }
}
