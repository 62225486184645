import { FormControl, FormGroup, Validators } from "@angular/forms"

export interface UserData {
  address: string
  ast_setuser: string
  client_secret: string
  cmn_company: object
  company_id: string
  createdAt: string
  email: string
  fax: string
  first_name: string
  id: string
  last_name: string
  name: string
  oid: string
  pincode: string|number
  position: string
  role_id: number
  telephone: string
  thumbnailExtension: string
  token_expiration: string
  updatedAt: string
}

export interface inviteUserData {
  name: string,
  first_name: string
  last_name: string
  pincode: string
  email: string
  role_id: number
  companyId: string
  vessel_id: string
}

export interface postUserData {
  name: string,
  first_name: string,
  last_name: string | undefined,
  pincode: string | number,
  company_id: string,
  role_id: number,
  position: string | undefined,
  address: string | undefined,
  telephone: string | undefined,
  fax: string | undefined,
  email: string | undefined,
  thumbnailExtension: string | undefined,
  oid: string | undefined,
}

export class editUserForm {
  defaultForm = new FormGroup({
  name: new FormControl(''),
  first_name: new FormControl(''),
  last_name: new FormControl(''),
  pincode: new FormControl(''),
  position: new FormControl(''),
  address: new FormControl(''),
  telephone: new FormControl(''),
  fax: new FormControl(''),
  email: new FormControl(''),
  thumbnailExtension: new FormControl(''),
  oid: new FormControl(''),
  country: new FormControl(''),
  city:new FormControl(''),
  zip: new FormControl(''),
  address2: new FormControl(''),
  jobposition: new FormControl(''),
  role_id: new FormControl(''),
  company_id: new FormControl(''),
  vessel_id: new FormControl(''),
  })
}

export class userForm {
  defaultForm = new FormGroup({
  name: new FormControl('', [Validators.required]),
  first_name: new FormControl('', [Validators.required]),
  last_name: new FormControl(''),
  pincode: new FormControl(''),
  email: new FormControl('', [Validators.required]),
  role_id: new FormControl('', [Validators.required]),
  company_id: new FormControl(''),
  vessel_id: new FormControl(''),
  country: new FormControl(''),
  city:new FormControl(''),
  zip: new FormControl(''),
  address2: new FormControl(''),
  address: new FormControl(''),
  tele: new FormControl(''),
  fax: new FormControl(''),
  jobposition: new FormControl(''),
  })
}