import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../_service/auth.service';

@Injectable({
  providedIn: 'root'
})

export class AdminGuard implements CanActivateChild {

  constructor (
    private auth: AuthService
  ) {}
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this.auth.isGlobalAdmin){
        return true;
      }else{
        return false;
      }
  }
  
}
