import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { vesselApplicationForm } from 'src/app/_model/vessel';
import { VesselService } from 'src/app/_service/vessel.service';



@Component({
  selector: 'app-vessel-application-register',
  templateUrl: './vessel-application-register.component.html',
  styleUrls: ['./vessel-application-register.component.css']
})
export class VesselApplicationRegisterComponent implements OnInit {
  @Output() dataChange = new EventEmitter();

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private vesselService: VesselService
  ) { }

  vesselID = '';
  vesselApplicationID = '';
  form = new vesselApplicationForm
  vesselApplicationForm = this.form.defaultForm;

  ngOnInit(): void {
    let vessel_id = this.route.snapshot.paramMap.get('vesselID');
    let vessel_application_id = this.route.snapshot.paramMap.get('applicationID');
    if(vessel_id===null){
      this.location.back();
    }else{
     this.vesselID = vessel_id;
    }
    if(vessel_application_id===null){
      this.vesselApplicationID = '';
    }else{
      this.vesselApplicationID = vessel_application_id;
    }
    this.vesselApplicationForm.patchValue({application_id: this.vesselApplicationID});
  }

  onSubmit() {
    if(this.checkSubmit()){
      this.vesselService.submitVesselApplication({'vessel_id': this.vesselID, 'application_id': this.vesselApplicationForm.value.application_id})
      .subscribe({
        error: (err) => {console.log(err)},
        complete: () => this.dataChange.emit()
      });
    }
  }

  checkSubmit() {
    if(window.confirm('Can I register this?')){
      return true;
    }else{
      return false;
    }
  }

  
  goBack() {
    this.location.back();
  }

}
