<div class="sticky inset-x-0 top-0  bg-white flex mb-4 container">
  <mat-icon  svgIcon="company" class="icon mt-16 ml-16"></mat-icon>
  <h1  class="text-tColor font-sans text-3xl font-bold mt-16 ">{{'addNewCompany' | translate}}</h1>
</div>
<hr>

<form [formGroup]="companyForm" (ngSubmit)="onSubmit()" autocomplete="off" [ngClass]="{'edit-mode-styles': editMode}">
  <div class="overscroll-y-contain object-scale-down">
    <div class="sm:max-w-2xl">
      <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
        <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'companyInformation' | translate}}</div>   
        <div class="col-span-2 flex flex-col gap-5">
            <div class="flex flex-col">
              <span class="text-lColor font-medium text-base leading-normal font-sans">{{'* ' + ('companyName' | translate)}}</span>
              <input type="text" pInputText placeholder="Enter a Company Name" id="name" formControlName="name" class=" h-12 border border-lColor rounded px-4"/>
              <small class="p-error block" *ngIf="companyForm.get('name')?.invalid && companyForm.get('name')?.dirty">This Field is required

              </small>
          </div>
          <div class="flex flex-col">
              <span class="text-lColor font-medium text-base leading-normal font-sans">{{'japaneseCompanyOtherName' | translate}}</span>
              <input type="text" pInputText class=" h-12 border border-lColor rounded px-4"  id="otherName" placeholder="Enter a Japanese Name" formControlName="otherName">
          </div>
          <div class="flex flex-col">
              <span class="text-lColor font-medium text-base leading-normal font-sans">{{'ceo' | translate}}</span>
              <input type="text" pInputText class=" h-12 border border-lColor rounded px-4" id="ceo" placeholder="Enter a CEO name" formControlName="ceo">
          </div>
          <div class="flex flex-col">
              <span class="text-lColor font-medium text-base leading-normal font-sans">{{'* ' + ('email' | translate)}}</span>
              <input type="email"pInputText class=" h-12 border border-lColor rounded px-4" id="email" placeholder="example@email.com" formControlName="email">
              <small class="p-error block" *ngIf="companyForm.get('email')?.invalid && companyForm.get('email')?.dirty">This Field is required</small>
          </div>
          <div>
            <div class="grid grid-cols-2 gap-1">
              <div>
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'phone' | translate}}</span>
                <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" id="telephone" placeholder="(+01)-0000-0000" formControlName="telephone"> 
              </div>
              <div>       
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'fax' | translate}}</span>
                <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" id="fax" placeholder="Enter Fax Number" formControlName="fax">
              </div> 
            </div>
          </div>
          <div class="font-sans text-lColor font-medium text-base leading-normal mr-4">
            <mat-label formControlName="types" multiple ngDefaultControl class="mb-2">*{{'types' | translate}}</mat-label> 
            <br/>  
            <div class="flex flex-row mb-6" *ngFor="let companyType of companyTypes">
              <div>
                <input type="checkbox" class="h-5 w-5 mr-3" id="{{companyType.value}}" [value]= companyType.value [checked]="companyForm!.value.types!.includes(companyType.value)" (change)="onChange($event)">
              </div>
              <div class="label-column">
                <label class="container" for="{{companyType.value}}">{{companyType.display}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>  
  <br/>  
  <hr class="w-full">
  <div class="sm:max-w-2xl">
    <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
      <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'companyAddress' | translate}}</div>   
      <div class="col-span-2 flex flex-col gap-5">
          <div class="flex flex-col">
            <span class="text-lColor font-medium text-base leading-normal font-sans">{{'addressLine1' | translate}}</span>
            <input type="text" pInputText id="address" placeholder="Enter Street Address" formControlName="address" class=" h-12 border border-lColor rounded px-4"/>
            
        </div>
        <div class="flex flex-col">
            <span class="text-lColor font-medium text-base leading-normal font-sans">{{'addressLine2' | translate}}</span>
            <input type="text" pInputText class=" h-12 border border-lColor rounded px-4"  id="address2" placeholder="Apartment, etc. (optional)" formControlName="addressLineTwo">
        </div>
        <div>
          <div class="grid grid-cols-2 gap-1">
            <div>
              <span class="text-lColor font-medium text-base leading-normal font-sans">{{'city' | translate}}</span>
              <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" id="city" placeholder="Enter City" formControlName="city"> 
            </div>
            <div>       
              <span class="text-lColor font-medium text-base leading-normal font-sans">{{'zipCode' | translate}}</span>
              <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" id="zip" placeholder="Enter Zip Code" formControlName="zipCode">
            </div> 
          </div>
        </div>
        <div class="flex flex-col">
          <span class="text-lColor font-medium text-base leading-normal font-sans">{{'country' | translate}}</span>
          <input type="text" pInputText class=" h-12 border border-lColor rounded px-4"  id="country" placeholder="Enter Country" formControlName="country">
      </div>
      </div>
    </div>
  </div> 
</div>
<!-- <div class="sticky inset-x-0 bottom-0 h-28 max-w-2xl space-x-4 al-end bg-white flex justify-end">
  <button  type="button" *ngIf="!editMode" (click)="goBack()" class="bg-white outline-none text-tColor h-5">{{'cancel' | translate}}</button>
  <button type="submit" *ngIf="!editMode" [disabled]="!companyForm.valid" class="text-white font-sans text-lg font-bold rounded-full bg-blue p-2 h-9">{{'addThisCompany' | translate}}</button>
</div> -->
<div class="sticky inset-x-0 bottom-0 h-28 max-w-2xl space-x-4 al-end bg-white flex justify-end pl-21.20vw mt-1">
  <button type="button" *ngIf="!editMode" (click)="goBack()" class="bg-white outline-none text-tColor h-10">{{ 'cancel' | translate }}</button>
  <button type="submit" *ngIf="!editMode" [disabled]="!companyForm.valid" class="text-white font-sans text-lg font-bold rounded-full bg-blue p-3 h-10 flex items-center justify-center cursor-pointer">{{ 'addThisCompany' | translate }}</button>
</div>

</form>
<!-- <button  type="button" *ngIf="!editMode" (click)="goBack()" class="cancel">{{'cancel' | translate}}</button>
<button type="submit" *ngIf="!editMode" [disabled]="!companyForm.valid" class="submit">{{'addThisCompany' | translate}}</button>
<div *ngIf="companyForm.invalid" class="error-message">
  {{'completeAllRequiredFields' | translate}}
</div> -->

