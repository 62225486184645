import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as routing from 'src/app/_model/routing'
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CompanyListComponent } from '../company-list/company-list.component';
import { Router } from '@angular/router';
import { getCompanyData, postCompanyData, companyForm, companyType } from 'src/app/_model/company';
import { CompanyService } from 'src/app/_service/company.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrl: './company-edit.component.css'
})
export class CompanyEditComponent {
  @Output() dataChange = new EventEmitter();
  form = new companyForm;
  companyForm = this.form.defaultForm;
  editCompanyData!: postCompanyData;
  editMode = false;
  isSubmitting = false;

  companyTypes: companyType[] = [
    {value: 'typeOwner', display: 'Owner'},
    {value: 'typeExOwner', display: 'ExOwner'},
    {value: 'typeManagement', display: 'Manager'},
    {value: 'typeExManagement', display: 'ExManagement'},
    {value: 'typeBuilder', display: 'Builder'},
    {value: 'typeManufacturer', display: 'Manufacturer'},
    {value: 'typeMaintainer', display: 'Maintainer'}
  ]
  constructor(
    private location: Location,
    private companyService: CompanyService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<CompanyListComponent>,
    public router: Router,

  ) { 
    this.iconRegistry.addSvgIcon(
    'company',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/company.svg')
    );
    this.iconRegistry.addSvgIcon(
    'zoomin',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/zoomin.svg')
    );
    this.iconRegistry.addSvgIcon(
      'zoom',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/zoom.svg')
    );
  }

  ngOnInit(): void {
    if(this.companyService.editCompanyFlag){
      this.setEditCompanyData(this.companyService.editCompanyData);
      this.editMode = true;
    }else{
      this.editMode = false;
      this.companyForm.patchValue({types: []});
    }
  }

  ngOnDestroy(): void {
    this.companyService.resetSelectedCompanyID();
    this.companyService.resetEditCompany();
    this.companyForm.reset();
  }

  setEditCompanyData(editCompanyData: postCompanyData){
    this.companyForm.patchValue(editCompanyData);
  }
  
  onSubmit() {
    this.companyForm.get('types')?.setValue([]);
    // console.log(this.companyForm.value);
    if(this.checkSubmit()){
      if(this.editMode){
        //update existing company
        this.companyService.submitExistingCompany(this.companyForm.value)
        .subscribe({
          error: (err) => {window.alert('error occurred');},
          complete: () => {
            window.alert('Company data updated');
            this.dialogRef.close()
            this.companyForm.reset();
            this.dataChange.emit();
            this.companyService.companyListUpdate();
          
          }
        })
      }else{
        // console.log(this.types,this.companyForm.value)
        // register new company
        this.isSubmitting = true;
        this.companyService.submitNewCompany(this.companyForm.value)
        .subscribe({
          error: (err) => {
            window.alert('error occurred');
            this.isSubmitting = false;

          },
          complete: () => {
            window.alert(' company registered');
            this.companyForm.reset();
            this.isSubmitting= false;
            this.dataChange.emit();
          }
        })
      }
    }
  }
  
  onChange($event:any) {
    // console.log($event.target.value,$event.target.checked)
    let type = $event.target.value;
    let isChecked = $event.target.checked;
    this.companyForm.get(type)?.setValue(isChecked ? 1 : 0);
  }
  onClose(){
    this.companyService.editCompanyFlag= false;
  }
  checkSubmit() {
    console.log(this.companyForm.value)
    if(window.confirm('Can I register this?')){
      return true;
    }else{
      //this.companyForm.get('types')?.setValue(this.types);
      return false;
    }
  }

  onDelete() {
    if (this.checkDelete()) {
      this.companyService.deleteCompanyData()
      .subscribe({
        error: () => {
          window.alert('Error occurred');
        },
        complete: () => {
          window.alert('Company ' + this.companyForm.value.name + ' deleted');
          this.dialogRef.close();
        }
      });
    }
  }

  checkDelete() {
    if (window.confirm('Are you sure you want to delete the company?')) {
      return true;
    } else {
      return false;
    }
  }

  goBack(){
    this.location.back();
  }
}
