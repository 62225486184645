<div class="main" style="width: 744px;">
<div mat-dialog-title class="header">
  <h1>{{'editUser' | translate}}</h1>
  <button *ngIf="editMode" (click)="onClose()" [mat-dialog-close] class="close-btn"><i class="pi pi-times" style="font-size: 19.89px; color: #828282ee"></i></button>
</div>
<hr class="styled-line">

<form [formGroup]="editUserForm" (ngSubmit)="onSubmit()" autocomplete="off" style="width: 744px;">
  <section class="scrollable-section">
    <div class="img-upload">
      <img src="../assets/images/icon-photo.png">
      <label>{{'changeUserPhoto' | translate}}</label>
    </div>
    <br>
    <div class="content" style="width: 744px;">
      <!-- <div class="two-side"> -->
        <label class="label-class">{{'userInfo' | translate}}</label>
        <div class="field-class">
          <span class="field-label">*{{'userName' | translate}}</span>
          <input type="text" class="input-field" matInput id="name" placeholder="User name" formControlName="name"> 
        </div>
        <div class="field-class">
          <div class="subgrid-container">
            <div class="sub">
              <span class="field-label">*{{'firstName' | translate}}</span>
              <input type="text" class="custom-input-sub" matInput id="first_name" placeholder="First name" formControlName="first_name"> 
            </div>
            <div class="sub">       
                <span class="field-label">*{{'lastName' | translate}}</span>
                 <input type="text" class="custom-input-sub" matInput id="last_name" placeholder="Last name" formControlName="last_name">
            </div>
          </div>
        </div>
        <div class="field-class">
          <span class="field-label">*{{'email'|translate}}</span>
          <input type="text" class="input-field" matInput id="email" placeholder="example@email.com" formControlName="email" readonly>
        </div> 
        <!-- <div class="fields">
          <div class="sub-direction">
            <div class="label-line">
              <mat-label class="custom-mat-label" style="margin-right: 130px;">*First Name</mat-label>
              <mat-label class="custom-mat-label">*Last Name</mat-label>
            </div>
            <div class="input-line">
              <input matInput id="name" type="text" formControlName="name" class="custom-input" placeholder="First name">
              <input matInput id="first_name" type="text" formControlName="first_name" class="custom-input" placeholder="Last name">
            </div>
          </div>
           <mat-label class="custom-mat-label">Job Position</mat-label>
          <input matInput id="job-position" type="text" formControlName="position" class="custom-input" placeholder="Enter Job Position"> -->
          <!-- <mat-label class="custom-mat-label">*Email</mat-label>
          <input id="email" type="text" formControlName="email" class="custom-input" matInput placeholder="example@email.com">  -->
          <!-- <div class="sub-direction">
            <div class="label-line">
              <mat-label class="custom-mat-label">Phone</mat-label>
              <mat-label class="custom-mat-label">Fax</mat-label>
            </div>
            <div class="input-line">
              <input matInput type="text" formControlName="telephone" class="custom-input"  placeholder="(+01)-0000-0000">
              <input matInput type="text" formControlName="fax" class="custom-input"  placeholder="Enter Fax Number">
            </div>
          </div> -->
       
      <hr class="styled-line">
      <!-- <div class="two-side">
        <label class="infoes">User Address</label>
        <div class="fields">
          <mat-label class="custom-mat-label">Address Line 1</mat-label>
          <input id="Address Line 1" type="text" formControlName="address" class="custom-input" matInput placeholder="Enter Street Address"> 
          <mat-label class="custom-mat-label">Address Line 2</mat-label>
          <input id="Address Line 2" type="text" formControlName="address2" class="custom-input" matInput placeholder="Apartment, etc. (optional)"> 
          <div class="sub-direction">
            <div class="label-line">
              <mat-label class="custom-mat-label">City</mat-label>
              <mat-label class="custom-mat-label">Zip Code</mat-label>
            </div>
            <div class="input-line">
              <input id="city" type="text" formControlName="city" class="custom-input" matInput placeholder="Enter City">
              <input id="zip" type="text" formControlName="zip" class="custom-input" matInput placeholder="Enter Zip Code">
            </div>
          </div>
          <mat-label class="custom-mat-label">Country</mat-label>
          <input id="country" type="text" formControlName="country" class="custom-input" matInput placeholder="Enter Country">            
        </div>
      </div>       
      <hr class="styled-line"> -->
      <label class="label-class">{{'userInfo' | translate}} 2</label>
      <div class="field-class">
        <span class="field-label">*{{'oid' | translate}}</span>
        <input type="text" class="input-field" matInput id="oid"  formControlName="oid" readonly>
      </div> 
      <div class="field-class">
        <span class="field-label">{{'pincode' | translate}}</span>
        <input type="text" class="input-field" matInput id="pincode" placeholder="Only for Changing Pincode" formControlName="pincode">
      </div> 
      <div class="field-class">
        <span class="field-label">{{'role' | translate}}</span>
        <mat-select class="input-field" formControlName="role_id" placeholder="Role" #singleSelect>
          <mat-option  *ngFor="let role of roles" [value]="role.id" >{{role.name}}</mat-option>
        </mat-select>
      </div> 
      <div class="field-class">
        <span class="field-label">{{'vessel' | translate}}</span>
        <mat-select class="input-field" formControlName="vessel_id" placeholder="Vessel" #singleSelect>
          <input type="text" (input)="filterVessel($event)" class="searches" placeholder="Search by name">
          <mat-option *ngFor="let vessel of vesselList" [value]="vessel.id">{{vessel.name}}</mat-option>
        </mat-select>
      </div> 
    </div>
  </section>
  <div class="btn-area">  
    <button type="button" class="delete" (click)="onDelete()">{{'delete' | translate}}</button>    
    <button type="button" [mat-dialog-close] (click)="onClose()"  class="cancel">{{'cancel' | translate}}</button>
    <button type="submit" class="submit">{{'save' | translate}}</button>
  </div>
</form>

