<div *ngIf="!isBlocked">
  <div class="dashboard" *ngIf="router.url === '/dashboard'">
    <div class="header-text">
      <img src="../assets/images/defaults/homepage_graphic.svg" style="min-width: 250px; max-width: 250px"/>
    </div>
    <div class="grid-container">
      <span class="welcome-text">Welcome to</span>
      <img class="welcome-logo grid-item"/>
    </div>
    <div class='new-entry-text'>{{'newEntry'|translate}}</div>
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-2 mx-4 md:mx-20 m-20" style="margin: 30px 0 0 105px; position:relative; width: 85%;">
      <div class="new-entry-btn" (click)="jumpCreateCompany()">
        <img class="new-entry-icon-company"/>
        <div class="new-entry-txt">{{'newCompany'|translate}}</div>
      </div>
      <div class="new-entry-btn" (click)="jumpCreateVessel()">
        <img class="new-entry-icon-vessel"/>
        <div class="new-entry-txt">{{'newVessel'|translate}}</div>
      </div>
      <div class="new-entry-btn" (click)="jumpCreateUser()">
        <img class="new-entry-icon-customer"/>
        <div class="new-entry-txt">{{'newUser'|translate}}</div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isBlocked">
  <app-not-registered></app-not-registered>
</div>
<div class="components">
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>
