import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService, userData } from '../_service/auth.service';
import { CompanyService } from '../_service/company.service';
import * as routing from '../_model/routing'
import { VesselService } from '../_service/vessel.service';
import { UserService } from '../_service/user.service';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { getCompanyData } from '../_model/company';
import { StreamingHttpUtil } from '../_common/common.streaminghttp';
import { getVesselData } from '../_model/vessel';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RoutingService } from '../_service/routing.service';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject();
  isBlocked = false;
  mode = "day";
  constructor(
    public router: Router,
    private check: RoutingService,
    private auth: AuthService,
    private companyService: CompanyService,
    private vesselService: VesselService,
    private userService: UserService,
    private route: ActivatedRoute,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private authService: MsalService,
  ) { this.isBlocked = this.check.isBlocked;
    
    this.iconRegistry.addSvgIcon(
      'plus',
      sanitizer.bypassSecurityTrustResourceUrl('assets/plus.svg')
    );
  }

  companyCounter = 0;
  vesselCounter = 0;
  userCounter = 0;
  initCompanyListFlag = false;
  initVesselListFlag = false;
  isCompanyPage = false;
  isVesselPage = false;
  selectedTab: string = 'tab1';
  inkBarPosition: string = '0%';
  ngOnInit(): void {
    // this.getAllCount();
    this.initAllList();
    this.getIsCompanyPage();
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(1);
  }

  getAllCount() {
    this.getCompanyCount();
    this.getVesselCount();
    this.getUserCount();
  }

  initAllList() {
    this.initCompanyList();
    
    this.initVesselList();
  }
  selectTab(tab: string): void {
    this.selectedTab = tab;
    if (tab === 'tab1') {
      this.inkBarPosition = '0%';
      // this.router.navigate([routing.AllEntries]);
    } else{
      this.inkBarPosition = '100%';
    }
  }
  getCompanyCount() {
    this.companyService.getCompanyCount()
    .subscribe({
      next: (value) => {this.companyCounter = value.count;}
    })
  }

  getVesselCount() {
    this.vesselService.getVesselCount()
    .subscribe({
      next: (value) => {this.vesselCounter = value.count}
    })
  }

  initVesselList() {
    this.vesselService.vesselList = [];
    this.vesselService.getVesselList()
    .subscribe({
      next: async(value) => {
        const streamingJson = await StreamingHttpUtil.getStream(value);
        const vessels:getVesselData[] = JSON.parse(streamingJson);
        this.vesselService.vesselList.push(...vessels);
      },
      complete: () => {
        this.initVesselListFlag = true;
      }
    });
  }

  initCompanyList() {
    this.companyService.companyList = [];
    this.companyService.getCompanyList()
    .subscribe({
      next: async(value:any) => {
        
        const streamingJson = await StreamingHttpUtil.getStream(value);
        const companies:getCompanyData[] = JSON.parse(streamingJson);
        this.companyService.companyList.push(...companies);
      },
      complete: () => {
        this.initCompanyListFlag = true;
      }
    })
  }



  getUserCount() {
    this.userService.getUserCount()
    .subscribe({
      next: (value) => {this.userCounter = value.count}
    })
  }

  jumpCreateCompany() {
    this.companyService.skipListFlag = true;
    if(!this.isBlocked){
      this.router.navigate([routing.CompanyRegistration]);
    }
    
  }

  jumpCreateVessel() { 
    this.vesselService.skipListFlag = true;
    if(!this.isBlocked){ 
      this.router.navigate([routing.VesselRegistration]);}
   
  }

  jumpCreateUser() {
    this.userService.skipListFlag = true;
    if(!this.isBlocked){ 
      this.router.navigate([routing.UserRegistration]);}
   
  }

  goCompanyPage() {
    if(!this.isCompanyPage){
      this.router.navigate([routing.CompanyList]);
    }
  }

  goVesselPage() {
    if(!this.isVesselPage){
      this.router.navigate([routing.Vessel]);
    }
  }

  goUserPage() {
    this.router.navigate([routing.UserList]);
  }
  logout() {
    this.authService.logoutRedirect({
      postLogoutRedirectUri: environment.msalConfig.auth.redirectUri
    });
  }

  get isAdmin() {
    return this.auth.isAdmin || this.auth.isGlobalAdmin || this.auth.isUser;
  }

  getIsCompanyPage() {
    this.companyService.isCompanyPageChanges
    .pipe(takeUntil(this.onDestroy$))
    .subscribe({
      next: (value) => {
        this.isCompanyPage = value}
    })
  }

  getIsVesselPage() {
    this.vesselService.isVesselPageChanges
    .pipe(takeUntil(this.onDestroy$))
    .subscribe({
      next: (value) => {
        this.isVesselPage = value;
      }
    })
  }

  onActivate(elementRef: any) {
    elementRef.dataChange.subscribe((event:any) => {
      this.getAllCount();
    })
  }

}
