import { MsalService, MsalBroadcastConfiguration, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TranslateService } from '@ngx-translate/core';

import { AuthService, userData } from './_service/auth.service';
import { Router } from '@angular/router';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RoutingService } from './_service/routing.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [DashboardComponent]
})
export class AppComponent implements OnInit{
  title = 'iPortal';
  isIframe = false;
  loginDisplay = false;
  isSidenavOpened = true;
  mode="day";
  selectedTab: string = 'dashboard'; 
  private readonly _destroying$ = new Subject<void>();
  selectedLanguage!: string;

  public onDestroy$ = this._destroying$.asObservable();
  isBlocking:boolean = false;
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGurdConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private broadcastService: MsalBroadcastService,
    private auth: AuthService,
    private router: Router,
    private dashboardComponent: DashboardComponent,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private check: RoutingService,

    ) {
      translate.setDefaultLang('en');
      translate.use('en');
      this.selectedLanguage= 'en';
      this.isBlocking=check.isBlocked;
      this.iconRegistry.addSvgIcon(
     'main',
        sanitizer.bypassSecurityTrustResourceUrl('/assets/main.svg')
      );
      this.iconRegistry.addSvgIcon(
        'home',
        sanitizer.bypassSecurityTrustResourceUrl('assets/home_light.svg')
      );
      this.iconRegistry.addSvgIcon(
        'subs',
        sanitizer.bypassSecurityTrustResourceUrl('assets/subs.svg')
      );
      this.iconRegistry.addSvgIcon(
        'user',
        sanitizer.bypassSecurityTrustResourceUrl('assets/user.svg')
      );
      this.iconRegistry.addSvgIcon(
        'user_select',
        sanitizer.bypassSecurityTrustResourceUrl('assets/customer_light.svg')
      );
      this.iconRegistry.addSvgIcon(
        'company',
        sanitizer.bypassSecurityTrustResourceUrl('assets/company_light.svg')
      );
      this.iconRegistry.addSvgIcon(
        'vessel',
        sanitizer.bypassSecurityTrustResourceUrl('assets/vessel_light.svg')
      );
      this.iconRegistry.addSvgIcon(
        'exit',
        sanitizer.bypassSecurityTrustResourceUrl('assets/exit.svg')
      );
     }

  ngOnInit() {
    const token = localStorage.getItem('token');
    if (!token) {
      localStorage.setItem('token', JSON.stringify({'token':''}));
    }
    this.isIframe = window !== window.parent && !window.opener;

    this.broadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$),
    )
    .subscribe(() => {
      this.setLoginDisplay();
    })
  }
  switchLanguage() {
    const currentLang = this.translate.currentLang;
    const newLang = currentLang === 'en' ? 'jp' : 'en';

    this.translate.use(newLang);
    this.selectedLanguage=newLang;
  }
  switchMode() {
    const currColor = document.documentElement.style.getPropertyValue("--text-color");
    const currColorGray = document.documentElement.style.getPropertyValue("--text-color-gray");
    const currColorDB = document.documentElement.style.getPropertyValue("--text-color-dark-blue");
    const currColorNEBtn = document.documentElement.style.getPropertyValue("--ne-bg-color");
    const customerURL = document.documentElement.style.getPropertyValue("--icon-customer");
    const companyURL = document.documentElement.style.getPropertyValue("--icon-company");
    const vesselURL = document.documentElement.style.getPropertyValue("--icon-vessel");
    const logoURL = document.documentElement.style.getPropertyValue("--logo");
    const currColorListBG = document.documentElement.style.getPropertyValue("--list-bg-color");
    const currColorListBorder = document.documentElement.style.getPropertyValue("--list-border-color");

    document.documentElement.style.setProperty("--text-color", currColor == "#fff" ? "#000" : "#fff");
    document.documentElement.style.setProperty("--text-color-gray", currColorGray == "#fff" ? "#595959" : "#fff");
    document.documentElement.style.setProperty("--text-color-dark-blue", currColorDB == "#20b4e6" ? "#0a2f6b" : "#20b4e6");
    document.documentElement.style.setProperty("--ne-bg-color", currColorNEBtn == "#202730" ? "#e5eef8" : "#202730");
    document.documentElement.style.setProperty("--icon-customer", customerURL === 'url("/assets/customer_dark2.svg")' ? 'url("/assets/customer_light.svg")' : 'url("/assets/customer_dark2.svg")');
    document.documentElement.style.setProperty("--icon-company", companyURL === 'url("/assets/company_dark2.svg")' ? 'url("/assets/company_light.svg")' : 'url("/assets/company_dark2.svg")');
    document.documentElement.style.setProperty("--icon-vessel", vesselURL === 'url("/assets/vessel_dark2.svg")' ? 'url("/assets/vessel_light.svg")' : 'url("/assets/vessel_dark2.svg")');
    document.documentElement.style.setProperty("--logo", logoURL === 'url("../assets/images/defaults/iportal_logo_night.svg")' ? 'url("../assets/images/defaults/iportal_logo.svg")' : 'url("../assets/images/defaults/iportal_logo_night.svg")');
    document.documentElement.style.setProperty("--list-bg-color", currColorListBG == "#202730" ? "#f2f2f2" : "#202730");
    document.documentElement.style.setProperty("--list-border-color", currColorListBorder == "#323d4c" ? "#b1b1b1" : "#323d4c");
    
    this.mode = this.mode == "day" ? "night" : "day";
  }
  login() {
    if(this.msalGurdConfig.authRequest){
      this.authService.loginRedirect({...this.msalGurdConfig.authRequest} as RedirectRequest);
    }else{
      this.authService.loginRedirect();
    }
  }

  logout() {
    this.authService.logoutRedirect({
      postLogoutRedirectUri: environment.msalConfig.auth.redirectUri
    });
  }

  toggleSidenav() {
    this.isSidenavOpened = this.isSidenavOpened;
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if(this.loginDisplay){
      this.auth.getUserData();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  goUserPage() {
    this.selectedTab = 'users';
    this.dashboardComponent.goUserPage();
  }

  goDashboardPage() {
    
    this.selectedTab = 'dashboard';
    this.router.navigate(['/dashboard']);
  }

  goCompanyPage() {
    this.selectedTab = 'companies';
    this.dashboardComponent.goCompanyPage();
  }

  goVesselPage() {
    this.selectedTab = 'vessel';
    this.router.navigate(['/dashboard/vessel/vessel-list']);
  }

  get userName() {
    return this.auth.userName;
  }

  get userEmail() {
    return this.auth.email;
  }

  isAdmin() {
    if(this.auth.isAdmin){
      return '(admin user)';
    }
    else if(this.auth.isGlobalAdmin){
      return '(Global admin user)';
    }
    else if(this.auth.isUser){
      return '(user)';
    }else{
      return;
    }
  }
}
