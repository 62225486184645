import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { CompanyService } from 'src/app/_service/company.service';

import { getCompanyData, postCompanyData } from 'src/app/_model/company';
import * as routing from 'src/app/_model/routing';
import { VesselService } from 'src/app/_service/vessel.service';
import { MatTableDataSource } from '@angular/material/table';
import { getVesselData } from 'src/app/_model/vessel';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.css']
})
export class CompanyDetailComponent implements OnInit {
  @Output() dataChange = new EventEmitter();

  companyID = '';
  companyData!: postCompanyData;
  columnName = ['key', 'value'];
  vessel_list: getVesselData[] = [];
  tableData: Array<object> = [];
  dataSource:any;
  finishCounter = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private companyService: CompanyService,
    private vesselService: VesselService,
  ) {  }

  ngOnInit(): void {
    this.companyService.skipListFlag = false;
    this.tableData = [];
    this.getCompanyID();
    this.getCompanyData(this.companyID);
    this.getAssociatedVesselList();
  }

  getCompanyID() {
    let id = this.route.snapshot.paramMap.get('id');
    if(id===null){
      this.location.back();
    }else{
      this.companyID = id;
      this.companyService.selectedCompanyID = this.companyID;
    }
  }

  getCompanyData(id:string){
    const data = this.companyService.companyList.find(e => e.id === id);
    if(data===undefined){
      this.location.back();
    }else{
      this.changeGetToPostCompanyData(data);
    }
  }

  getAssociatedVesselList() {
    this.vesselService.associateVesselList = [];
    this.getAssociateCompanyData('owner_id');
    // this.getAssociateCompanyData('manager_id');
    this.getAssociateCompanyData('builder_id');
    // this.getAssociateCompanyData('maintener_id');
  }
//Selected Company: 1qbAoFERsLkr8HgHnRdKYv


  getAssociateCompanyData(queryName: string) {
    this.vesselService.getVesselListWithQuery(queryName, this.companyService.selectedCompanyID)
    .subscribe({
      next: (value) => {
        value.forEach(e => {
          //remove duplicate element
          if(!(this.vesselService.associateVesselList.some(elem => elem.id === e.id))){
            this.vesselService.associateVesselList.push(e);
          }
        })},
      complete: () => {
        if(this.finishCounter === 1){
          this.finishCounter = 0;
          this.setTableData();
        }else{
          this.finishCounter++;
        }}
    });
  }

  editCompanyData() {
    this.companyService.editCompanyFlag = true;
    this.companyService.editCompanyData = this.companyData;
    this.router.navigate([routing.CompanyRegister]);
  }

  deleteCompanyData() {
    if(this.checkSubmit()){
      this.companyService.deleteCompanyData()
      .subscribe({
        complete: () => {this.dataChange.emit();}
      })
    }
  }

  changeGetToPostCompanyData(data: getCompanyData) {
    this.companyData = {
      name: data.name,
      otherName: data.otherName,
      ceo: data.ceo,
      address: data.address,
      addressLineTwo: data.addressLineTwo,
      city: data.city,
      zipCode: data.zipCode,
      telephone: data.telephone,
      fax: data.fax,
      email: data.email,
      thumbnailExtension: data.thumbnailExtension,
      businessContents: data.businessContents,
      rank: data.rank,
      country:data.country,
      types: this.companyService.getCompanyType(data),
      typeOwner: data.typeOwner,
      typeManagement: data.typeManagement,
      typeBuilder: data.typeBuilder,
      typeManufacturer: data.typeManufacturer,
      typeMaintainer: data.typeMaintainer,
      typeExOwner: data.typeExOwner,
      typeExManagement: data.typeExManagement,
    };
  }

  setTableData() {
    const keyArray = Object.keys(this.companyData);
    const valueArray =  Object.values(this.companyData);
    for(let i=0; i<keyArray.length;i++){
      this.tableData.push({'key': keyArray[i], 'value': valueArray[i]});
    }
    this.tableData.push({key: 'Vessel Count', value: this.vesselService.associateVesselList.length});
    this.dataSource = new MatTableDataSource(this.tableData);
  }

  goBackList() {
    this.vesselService.skipListFlag = true;
    this.location.back();
  }

  checkSubmit() {
    if(window.confirm('Can I delete this Company Data?')){
      return true;
    }else{
      return false;
    }
  }

  onRowClick(row: any) {
    if(row.key == 'Vessel Count'){
      this.router.navigate([routing.VesselList]);
    }
  }
}
