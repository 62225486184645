//angular library
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

//angular material
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatInputModule } from '@angular/material/input';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TableModule } from 'primeng/table';
import { MatSortModule } from '@angular/material/sort';
import {MatTabsModule} from '@angular/material/tabs';
import {MatStepperModule} from '@angular/material/stepper';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

// import { MatMomentDateModule } from "@angular/material-moment-adapter";

//third party
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MsalModule, MsalRedirectComponent, MsalGuard, MsalInterceptor } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
//primeng modules
import { TreeSelectModule } from 'primeng/treeselect';
import { TreeModule } from 'primeng/tree';
import { InputMaskModule } from 'primeng/inputmask';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
//component & service & etc
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NotRegisteredComponent } from './not-registered/not-registered.component';
import { CompaniesComponent } from './dashboard/companies/companies.component';
import { VesselsComponent } from './dashboard/vessels/vessels.component';
import { UserComponent } from './dashboard/user/user.component';
import { AdminGuard } from './_guard/admin.guard';
import { CompanyDetailComponent } from './dashboard/companies/company-detail/company-detail.component';
import { CompanyRegisterComponent } from './dashboard/companies/company-register/company-register.component';
import { CompanyListComponent } from './dashboard/companies/company-list/company-list.component';
import { UserListComponent } from './dashboard/user/user-list/user-list.component';
import { UserDetailComponent } from './dashboard/user/user-detail/user-detail.component';
import { UserRegisterComponent } from './dashboard/user/user-register/user-register.component';
import { UserEditComponent } from './dashboard/user/user-edit/user-edit.component';
import { IportalHomeComponent } from './iportal-home/iportal-home.component';
import { environment } from 'src/environments/environment';
import { VesselListComponent } from './dashboard/vessels/vessel-list/vessel-list.component';
import { VesselDetailComponent } from './dashboard/vessels/vessel-detail/vessel-detail.component';
import { VesselRegisterComponent } from './dashboard/vessels/vessel-register/vessel-register.component';
import { VesselApplicationRegisterComponent } from './dashboard/vessels/vessel-application-register/vessel-application-register.component';
import { CompanyEditComponent } from './dashboard/companies/company-edit/company-edit.component';
import { VesselEditComponent } from './dashboard/vessels/vessel-edit/vessel-edit.component';

export function CreateTranslateFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const isIE = window.navigator.userAgent.indexOf('MSIE') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
const protectedResourceMap = new Map<string, Array<string>>();
const conf = environment.msalResourceMap;
conf.forEach(e => {
  protectedResourceMap.set(e.resourceURL, e.scopes);
});

@NgModule({ declarations: [
        AppComponent,
        //LoginComponent,
        IportalHomeComponent,
        //ProfileComponent,
        DashboardComponent,
        NotRegisteredComponent,
        CompaniesComponent,
        VesselsComponent,
        UserComponent,
        CompanyDetailComponent,
        CompanyRegisterComponent,
        CompanyListComponent,
        UserListComponent,
        UserDetailComponent,
        UserRegisterComponent,
        UserEditComponent,
        VesselListComponent,
        VesselDetailComponent,
        VesselRegisterComponent,
        VesselApplicationRegisterComponent,
        CompanyEditComponent,
        VesselEditComponent,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MatButtonModule,
        MatToolbarModule,
        MatListModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        ReactiveFormsModule,
        MatTableModule,
        ScrollingModule,
        MatSidenavModule,
        MatAutocompleteModule,
        FormsModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatCardModule,
        MatDatepickerModule,
        MatButtonModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatGridListModule,
        MatIconModule,
        MatPaginatorModule,
        TableModule,
        TreeSelectModule,
        TreeModule,
        InputMaskModule,
        MatSortModule,
        DialogModule,
        MatStepperModule,
        MatTabsModule,
        MatButtonToggleModule,
        InputTextModule,
        CheckboxModule,
        DropdownModule,
        // MatMomentDateModule,
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: 'b1e3fa6b-9104-4275-ab2d-8342585f02c6',
                authority: 'https://login.microsoftonline.com/kikuho.onmicrosoft.com',
                redirectUri: environment.msalConfig.auth.redirectUri
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: isIE,
            }
        }), {
            interactionType: InteractionType.Redirect
        }, {
            interactionType: InteractionType.Redirect,
            protectedResourceMap: new Map([
                [environment.msalResourceMap[0].resourceURL, environment.msalResourceMap[0].scopes]
            ])
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (CreateTranslateFactory),
                deps: [HttpClient]
            },
            defaultLanguage: 'en'
        })], providers: [
        MsalGuard,
        AdminGuard,
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
}
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}