import { Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {FormControl} from '@angular/forms';

import { CompanyService } from 'src/app/_service/company.service';
import { VesselService } from 'src/app/_service/vessel.service';
import { UserService } from 'src/app/_service/user.service';
import { UserData, postUserData, userForm } from 'src/app/_model/user_data';
import { getCompanyData } from 'src/app/_model/company';
import { getVesselData } from 'src/app/_model/vessel';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import * as routing from 'src/app/_model/routing'
import { UserEditComponent } from '../user-edit/user-edit.component';
 
@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.css'],
})

export class UserRegisterComponent implements OnInit, OnDestroy {
  @Output() dataChange = new EventEmitter();
  form = new userForm;
  userForm = this.form.defaultForm;
  editUserData!:postUserData;
  editMode = false;
  selectedVessel: any;
  selectedCompany: any;
  selectedRole: any;
  userID = '';
  userData!: postUserData;
  isSubmitting = false;

  constructor(
    private location: Location,
    private userService: UserService,
    private companyService: CompanyService,
    private vesselService: VesselService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public router: Router,
    private dialog: MatDialog,
  ) {
    this.companyList = this.companyService.companyList;
    this.vesselList = this.vesselService.vesselList;
    this.iconRegistry.addSvgIcon(
      'company',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/company.svg')
    );
    this.iconRegistry.addSvgIcon(
      'zoomin',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/zoomin.svg')
    );
    this.iconRegistry.addSvgIcon(
      'zoom',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/zoom.svg')
    );
  }

  companyList:getCompanyData[];
  vesselList:getVesselData[];
  roles = [
    {
      "id": 0,
      "name": "Admin"
    },
    {
      "id": 1,
      "name": "User"
    },
    {
      "id": 2,
      "name": "Third Party"
    },
    {
      "id": 3,
      "name": "Global Admin"
    },
  ]

  ngOnInit(): void {
    if(this.userService.editUserFlag){
      this.setEditUserData(this.userService.editUserData);
      this.addToTempdata(this.userService.editUserData)
      this.editMode = true;
    }else{
      this.editMode = false;
      //this.userForm.patchValue({types: []});
    }
  }
  tempdata!:postUserData;
  addToTempdata(data:postUserData){
    this.tempdata = {
      name: data!.name,
      first_name: data!.first_name,
      last_name: data!.last_name,
      pincode: data!.pincode,
      position: data!.position,
      address: data!.address,
      telephone: data!.telephone,
      fax: data!.fax,
      email: data!.email,
      thumbnailExtension: data!.thumbnailExtension,
      oid: data!.oid,
      company_id: data!.company_id,
      role_id: data!.role_id
      // company
    };
  }
  ngOnDestroy(): void {
    this.userService.resetSelectedUserID();
    this.userService.resetEditUser();
    this.userForm.reset();
  }

  Onclick(){
    
    // this.router.navigate([routing.UserEdit]);
    this.userService.editUserFlag=true;
    this.userService.editUserData =this.tempdata;  
    // this.router.navigate([routing.UserList]);

    this.dialog.open(UserEditComponent,{
      width:'744px',
      height: '85vh',
      // panelClass: 'fullscreen-dialog'
    })
  }  
  setEditUserData(editUserData: postUserData){
    this.userForm.patchValue(<any>editUserData);
  }

  onSubmit() {
    if(this.checkSubmit()){
      /*if(this.editMode){
        //update existing company
        this.userService.submitExistingUser(this.userForm.value)
        .subscribe({
          error: (err) => {window.alert('error occurred');},
          complete: () => {
            this.userForm.reset();
            this.dataChange.emit();
          }
        })
      }else{*/
        //invite new user
        this.isSubmitting = true;
        const vessel= this.vesselList.find(vessel => vessel.name=== this.selectedVessel ? true : vessel.id)
        const company= this.companyList.find(company => company.name=== this.selectedCompany ? true : company.id)
        const role= this.roles.find(role => role.name=== this.selectedRole ? true : role.id)
        this.userForm.patchValue({vessel_id :vessel?.id, company_id :company?.id, role_id :role?.id.toString()})
        this.userService.submitNewUser(this.userForm.value)
        .subscribe({
          error: (err) => {
            window.alert('error occurred');
            this.isSubmitting =false;
          },
          complete: () => {
            this.isSubmitting = false;
            {window.alert('User Registered');}
            this.userForm.reset();
            this.dataChange.emit();
          }
        })
    }
  }

  checkSubmit() {
    if(window.confirm('Can I register this?')){
      return true;
    }else{
      return false;
    }
  }

  goBack(){
    this.location.back();
  }
}
