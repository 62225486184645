<div class="sticky top-0 bg-white flex mb-4 container z-50">
  <mat-icon  svgIcon="vessel" class="icon mt-16 ml-16"></mat-icon>
  <h1 *ngIf="!editMode" class="text-tColor font-sans text-3xl font-bold mt-16 ">{{'addNewVessel' | translate}}</h1>
  <h1 *ngIf="editMode" class="text-tColor font-sans text-3xl font-bold mt-16 ">{{'editVessel' | translate}}</h1>
  <mat-icon svgIcon="zoomin" *ngIf="!editMode" class="zoom-out"></mat-icon> 
  <button *ngIf="editMode" style="background-color: transparent; border: none;" (click)="Onclick()"><mat-icon svgIcon="zoomin"  class="zoom-out"></mat-icon></button>
</div>
<hr>
<mat-stepper linear="isLinear" #stepper class="stepper">
  <mat-step [stepControl]="vesselStepper.sectionOneFormGroup">
    <form [formGroup]="vesselStepper.sectionOneFormGroup" (ngSubmit)="onSubmit()" autocomplete="off" class="overflow-y-auto max-h-[calc(100vh-16rem)]">
      <ng-template matStepLabel>Section 1</ng-template>
      <div class="overscroll-y-contain object-scale-down">
        <div class="sm:max-w-2xl mb-5">
          <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
            <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'vesselInformation' | translate}}</div>   
            <div class="col-span-2 flex flex-col gap-5">
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'* ' + ('vesselName' | translate)}}</span>
                <input type="text" pInputText id="name" placeholder="Enter Vessel Name" formControlName="name" class=" h-12 border border-lColor rounded px-4"/>
                <div *ngIf="sectionOneForm.get('name')?.hasError('required') && sectionOneForm.get('name')?.touched" class="error-message">
                  This Field is Required
                </div>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'otherName' | translate}}</span>
                <input type="text" pInputText id="otherName" placeholder="Enter Other Name" formControlName="otherName" class=" h-12 border border-lColor rounded px-4"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'imo' | translate}}</span>
                <input type="text" pInputText id="imo" placeholder="Enter IMO Number" formControlName="imo" class=" h-12 border border-lColor rounded px-4"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'vesselNumber' | translate}}</span>
                <input type="text" pInputText id="vesselNumber" placeholder="Enter Vessel Number" formControlName="vesselNumber" class=" h-12 border border-lColor rounded px-4"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'vesselType' | translate}}</span>
                <p-dropdown 
                [options]="vesselTypes" 
                [(ngModel)]="selectedVesselType" 
                formControlName="vesselType"
                optionLabel="type" 
                placeholder="Select the Vessel Type" 
                [style]="{'background-color': 'white', 'height': '3rem', 'border': '1px solid #828282', 'border-radius': '0.25rem', 'padding': '0 0.5rem', 'width': '100%'}"></p-dropdown>

              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'shipType' | translate}}</span>
                <input type="text" pInputText id="shipType"  placeholder="Enter Ship Type" formControlName="shipType" class=" h-12 border border-lColor rounded px-4"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'builtYear' | translate}}</span>
                <input type="text" pInputText id="builtYear" placeholder="Enter Built Year" formControlName="builtYear" class=" h-12 border border-lColor rounded px-4"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'callSign' | translate}}</span>
                <input type="text" pInputText id="callsign" placeholder="Enter Call Sign" formControlName="callsign" class=" h-12 border border-lColor rounded px-4"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'hotelPower' | translate}}</span>
                <input type="text" pInputText id="hotelPower" placeholder="Enter Hotel Power" formControlName="hotelPower" class=" h-12 border border-lColor rounded px-4"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'numOfThrusters' | translate}}</span>
                <input type="text" pInputText id="NoThruster" placeholder="Enter No. of Thruster" formControlName="NoThruster" class=" h-12 border border-lColor rounded px-4"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'numOfCrane' | translate}}</span>
                <input type="text" pInputText id="NoCrane"  placeholder="Enter No. of Crane" formControlName="NoCrane" class=" h-12 border border-lColor rounded px-4"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'numOfLeg' | translate}}</span>
                <input type="text" pInputText id="NoLeg" placeholder="Enter No. of Leg" formControlName="NoLeg" class=" h-12 border border-lColor rounded px-4"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'numOfMainEngines' | translate}}</span>
                <input type="text" pInputText id="NoME" placeholder="Enter No. of Main Engines" formControlName="NoME" class=" h-12 border border-lColor rounded px-4"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'firstClassificationSociety' | translate}}</span>
                <input pInputText type="tel" class=" h-12 border border-lColor rounded px-4" id="firstClass" [matAutocomplete]="auto4" formControlName="firstClass" placeholder="Enter a First Class"> 
                    <mat-autocomplete #auto4="matAutocomplete">
                      <mat-option *ngFor="let type of classificationSocietyType" [value]="type.type">
                        {{ type.type }}
                      </mat-option>
                    </mat-autocomplete>
              </div>
              
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'secondClassificationSociety' | translate}}</span>
                <input type="text" pInputText id="secondClass" [matAutocomplete]="auto5" formControlName="secondClass" placeholder="Enter a Second Class" class=" h-12 border border-lColor rounded px-4"/>
                <mat-autocomplete #auto5="matAutocomplete">
                  <mat-option *ngFor="let type of classificationSocietyType" [value]="type.type">
                    {{ type.type }}
                  </mat-option>
                </mat-autocomplete> 
              </div>
            </div>
          </div>
        </div>
        <hr class="w-full">
        <div class="sm:max-w-2xl mb-5">
          <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
            <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'idInfo' | translate}}</div>   
            <div class="col-span-2 flex flex-col gap-5">
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">* {{'ownerId'|translate}}</span>
                <p-dropdown 
                    [options]="companyList" 
                    placeholder="Select a Company" 
                    [filter]="true" 
                    formControlName="owner_id"
                    filterBy="name" 
                    [showClear]="true" 
                    displayProperty = "id"
                    [(ngModel)]="selectedOwner" 
                    optionLabel="id"
                    optionValue="name"
                    [virtualScroll]="true" 
                    [virtualScrollItemSize]="38"
                    [required]="true"
                    [style]="{'background-color': 'white', 'height': '3rem', 'border': '1px solid #828282', 'border-radius': '0.25rem', 'padding': '0 0.5rem', 'width': '100%'}">
                    <ng-template pTemplate="selectedItem" let-selectedOption >
                      <div>{{ selectedOwner}}</div>
                    </ng-template>
                    <ng-template let-company pTemplate="item" >
                      {{company.name}}
                    </ng-template>
                </p-dropdown>
             </div>
            <div class="flex flex-col">
              <span class="text-lColor font-medium text-base leading-normal font-sans">* {{'managerId' | translate}}</span>
              <p-dropdown 
                  [options]="companyList" 
                  placeholder="Select a Company" 
                  [filter]="true" 
                  formControlName="manager_id"
                  filterBy="name" 
                  [showClear]="true" 
                  displayProperty = "id"
                  [(ngModel)]="selectedManager" 
                  optionLabel="id"
                  optionValue="name"
                  [virtualScroll]="true" 
                  [virtualScrollItemSize]="38"
                  [required]="true"
                  [style]="{'background-color': 'white', 'height': '3rem', 'border': '1px solid #828282', 'border-radius': '0.25rem', 'padding': '0 0.5rem', 'width': '100%'}">
                  <ng-template pTemplate="selectedItem" let-selectedOption >
                    <div>{{ selectedManager}}</div>
                  </ng-template>
                  <ng-template let-company pTemplate="item" >
                    {{company.name}}
                  </ng-template>
              </p-dropdown>
            </div>
            <div class="flex flex-col">
              <span class="text-lColor font-medium text-base leading-normal font-sans">{{'exOwnerId' | translate}}</span>
              <p-dropdown 
                  [options]="companyList" 
                  placeholder="Select a Company" 
                  [filter]="true" 
                  formControlName="exowner_id"
                  filterBy="name" 
                  [showClear]="true" 
                  displayProperty = "id"
                  [(ngModel)]="selectedexowner" 
                  optionLabel="id"
                  optionValue="name"
                  [virtualScroll]="true" 
                  [virtualScrollItemSize]="38"
                  [required]="false"
                  [style]="{'background-color': 'white', 'height': '3rem', 'border': '1px solid #828282', 'border-radius': '0.25rem', 'padding': '0 0.5rem', 'width': '100%'}">
                  <ng-template pTemplate="selectedItem" let-selectedOption >
                    <div>{{ selectedexowner }}</div>
                  </ng-template>
                  <ng-template let-company pTemplate="item" >
                    {{company.name}}
                  </ng-template>
              </p-dropdown>
            </div>
            <div class="flex flex-col">
              <span class="text-lColor font-medium text-base leading-normal font-sans">{{'exManagerId' | translate}}</span>
              <p-dropdown 
                  [options]="companyList" 
                  placeholder="Select a Company" 
                  [filter]="true" 
                  formControlName="exmanager_id"
                  filterBy="name" 
                  [showClear]="true" 
                  displayProperty = "id"
                  [(ngModel)]="selectedexmanager" 
                  optionLabel="id"
                  optionValue="name"
                  [virtualScroll]="true" 
                  [virtualScrollItemSize]="38"
                  [required]="false"
                  [style]="{'background-color': 'white', 'height': '3rem', 'border': '1px solid #828282', 'border-radius': '0.25rem', 'padding': '0 0.5rem', 'width': '100%'}">
                  <ng-template pTemplate="selectedItem" let-selectedOption >
                    <div>{{ selectedexmanager}}</div>
                  </ng-template>
                  <ng-template let-company pTemplate="item" >
                    {{company.name}}
                  </ng-template>
              </p-dropdown>
            </div>
            <div class="flex flex-col">
              <span class="text-lColor font-medium text-base leading-normal font-sans">* {{'builderId' | translate}}</span>
              <p-dropdown 
                  [options]="companyList" 
                  placeholder="Select a Company" 
                  [filter]="true" 
                  formControlName="builder_id"
                  filterBy="name" 
                  [showClear]="true" 
                  displayProperty = "id"
                  [(ngModel)]="selectedBuilder" 
                  optionLabel="id"
                  optionValue="name"
                  [virtualScroll]="true" 
                  [virtualScrollItemSize]="38"
                  [required]="true"
                  [style]="{'background-color': 'white', 'height': '3rem', 'border': '1px solid #828282', 'border-radius': '0.25rem', 'padding': '0 0.5rem', 'width': '100%'}">
                  <ng-template pTemplate="selectedItem" let-selectedOption >
                    <div>{{ selectedBuilder}}</div>
                  </ng-template>
                  <ng-template let-company pTemplate="item" >
                    {{company.name}}
                  </ng-template>
              </p-dropdown>
            </div>
            <div class="flex flex-col">
              <span class="text-lColor font-medium text-base leading-normal font-sans">* {{'maintainerId' | translate}}</span>
              <p-dropdown 
                  [options]="companyList" 
                  placeholder="Select a Company" 
                  [filter]="true" 
                  formControlName="maintener_id"
                  filterBy="name" 
                  [showClear]="true" 
                  displayProperty = "id"
                  [(ngModel)]="selectedMaintainer" 
                  optionLabel="id"
                  optionValue="name"
                  [virtualScroll]="true" 
                  [virtualScrollItemSize]="38"
                  [required]="true"
                  [style]="{'background-color': 'white', 'height': '3rem', 'border': '1px solid #828282', 'border-radius': '0.25rem', 'padding': '0 0.5rem', 'width': '100%'}">
                  <ng-template pTemplate="selectedItem" let-selectedOption >
                    <div>{{ selectedMaintainer}}</div>
                  </ng-template>
                  <ng-template let-company pTemplate="item" >
                    {{company.name}}
                  </ng-template>
              </p-dropdown>
            </div>
          </div>
        </div>  </div>
        <hr class="w-full">
        <div class="sm:max-w-2xl mb-5">
          <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
            <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'vesselIdentification' | translate}}</div>   
              <div class="col-span-2 flex flex-col gap-5">
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'exName' | translate}}</span>
                  <input type="text" pInputText id="exName" formControlName="exName" placeholder="Enter Ex.Name" class=" h-12 border border-lColor rounded px-4"/>
                </div>
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'mmsi' | translate}}</span>
                  <input type="text" pInputText id="mmsi" formControlName="mmsi" placeholder="Enter MMSI" class=" h-12 border border-lColor rounded px-4"/>
                </div>
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'officialNumber' | translate}}</span>
                  <input type="text" pInputText id="officialNumber" formControlName="officialNumber" placeholder="Enter Official Number" class=" h-12 border border-lColor rounded px-4"/>
                </div>
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'signalLetter' | translate}}</span>
                  <input type="text" pInputText id="signalLetters" formControlName="signalLetters" placeholder="Enter Signal Letters" class=" h-12 border border-lColor rounded px-4"/>
                </div>
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'flag' | translate}}</span>
                  <input type="text" pInputText id="flag" formControlName="flag" placeholder="Enter Flag" class=" h-12 border border-lColor rounded px-4"/>
                </div>
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'portOfRegistry' | translate}}</span>
                  <input type="text" pInputText id="portOfRegistry" formControlName="portOfRegistry" placeholder="Enter Port of Registry" class=" h-12 border border-lColor rounded px-4"/>
                </div>
                <br><br>
              </div>
            </div>   
        </div>
      </div>
      <div class="fixed bottom-0 bg-white flex mt-8 mb-1 w-full ">
        <button type="button" class="bg-white font-sans text-lg text-lColor py-2 px-4 cursor-pointer ml-80" (click)="onClose()">{{'cancel' | translate}}</button>
        <button type="button" class="bg-white text-tColor font-sans text-lg py-2 px-4 cursor-pointer"  matStepperNext>{{'next' | translate}}</button>
      </div>
      
    </form>
  </mat-step>
  <mat-step [stepControl]="vesselStepper.sectionTwoFormGroup">
    <form [formGroup]="vesselStepper.sectionTwoFormGroup" (ngSubmit)="onSubmit()" autocomplete="off" class="overflow-y-auto max-h-[calc(100vh-16rem)]">
      <ng-template matStepLabel>Section 2</ng-template>
      <div class="overscroll-y-contain object-scale-down">
        <div class="sm:max-w-2xl mb-5">
          <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
            <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'dimension' | translate}}</div>   
            <div class="col-span-2 flex flex-col gap-5">
              <div class="grid grid-cols-2 gap-1">
                <div>
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'totalLength' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" id="totalLength" placeholder="Enter Total Length" formControlName="totalLength"> 
                </div>
                <div>       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'waterLineLength' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" id="waterlineLength" placeholder="Enter Water Line Length" formControlName="waterlineLength">
                </div> 
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'lbp' | translate}}</span>
                <input type="text" pInputText id="LBP" placeholder="Enter LBP" formControlName="LBP" class=" h-12 border border-lColor rounded px-4"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'width' | translate}}</span>
                <input type="text" pInputText id="width" placeholder="Enter Width" formControlName="width" class=" h-12 border border-lColor rounded px-4"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'draft' | translate}}</span>
                <input type="text" pInputText id="draft" placeholder="Enter Draft" formControlName="draft" class=" h-12 border border-lColor rounded px-4"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'mouldedDepth' | translate}}</span>
                <input type="text" pInputText id="mouldedDepth" placeholder="Enter Moulded Depth" formControlName="mouldedDepth" class=" h-12 border border-lColor rounded px-4"/>
              </div>
            </div>
          </div>
        </div>
        <hr class="w-full">
        <div class="sm:max-w-2xl mb-5">
          <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
            <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'tonnage' | translate}}</div>   
            <div class="col-span-2 flex flex-col gap-5">
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'netTonnage' | translate}}</span>
                <input type="text" pInputText id="netTonnage" placeholder="Enter Net Tonnage" formControlName="netTonnage" class=" h-12 border border-lColor rounded px-4"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'grossTonnage' | translate}}</span>
                <input type="text" pInputText id="grossTonnage" placeholder="Enter Gross Tonnage" formControlName="grossTonnage" class=" h-12 border border-lColor rounded px-4"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'deadWeightTonnage' | translate}}</span>
                <input type="text" pInputText id="deadweightTonnage" placeholder="Enter Dead Weight Tonnage" formControlName="deadweightTonnage" class=" h-12 border border-lColor rounded px-4"/>
              </div>
          </div>
        </div>  
        <hr class="w-full">
        <div class="sm:max-w-2xl mb-5">
          <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
            <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'additionalDetails' | translate}}</div>   
            <div class="col-span-2 flex flex-col gap-5">
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'bulbousBow' | translate}}</span>
                <input pInputText type="number" formControlName="bulbousBow" class="h-12 border border-lColor rounded px-4" placeholder="Enter Bulbous Bow"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'meBuiltYear' | translate}}</span>
                <input pInputText type="number" formControlName="meBuiltYear" class="h-12 border border-lColor rounded px-4" placeholder="Enter ME BuiltYear"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'lw' | translate}}</span>
                <input pInputText type="number" formControlName="L_w" class="h-12 border border-lColor rounded px-4" placeholder="Enter L_w"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'cb' | translate}}</span>
                <input pInputText type="number" formControlName="C_B" class="h-12 border border-lColor rounded px-4" placeholder="Enter C_B"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'etaD' | translate}}</span>
                <input pInputText type="number" formControlName="eta_D" class="h-12 border border-lColor rounded px-4" placeholder="Enter ETA_D"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'maxSpeedKn' | translate}}</span>
                <input pInputText type="number" formControlName="maxSpeedKn" class="h-12 border border-lColor rounded px-4" placeholder="Enter Max Speed Kn"/>
              </div>
          </div>
        </div>  
        </div>
        <hr class="w-full">
        <div class="sm:max-w-2xl mb-5">
          <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
            <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'constructionDetails' | translate}}</div>   
              <div class="col-span-2 flex flex-col gap-5">
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'builtPlace' | translate}}</span>
                  <input type="text" pInputText formControlName="builtPlace" id="builtPlace" class="h-12 border border-lColor rounded px-4" placeholder="Enter Built Place"/>
                </div>
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'structuralDesignType' | translate}}</span>
                  <input pInputText [matAutocomplete]="auto" formControlName="structuralDesignType" placeholder="Enter Structural Design Type" class="h-12 border border-lColor rounded px-4" placeholder="Enter Structural Design Type"/>
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of structuralDesignTypes" [value]="option.type">
                      {{ option.type }}
                    </mat-option>
                  </mat-autocomplete>
                </div>
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'purpose' | translate}}</span>
                  <input type="text" pInputText formControlName="purpose" class="h-12 border border-lColor rounded px-4" placeholder="Enter Purpose"/>
                </div>
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'operationalStatus' | translate}}</span>
                  <input id="operationalStatus" type="text" pInputText  [matAutocomplete]="auto1" formControlName="operationalStatus" class="h-12 border border-lColor rounded px-4" placeholder="Enter Operational Status"/>
                  <mat-autocomplete #auto1="matAutocomplete">
                    <mat-option *ngFor="let type of operationalStatus" [value]="type.type">
                        {{ type.type }}
                    </mat-option>
                </mat-autocomplete>
                </div>                
                <br><br>
              </div>
            </div>   
          </div>     
        </div>
      </div>
      <div class="fixed bottom-0 bg-white flex mt-8 mb-1 w-full ">
        <button type="button" class="bg-white font-sans text-lg text-lColor py-2 px-4 cursor-pointer ml-80" matStepperPrevious>{{'back' | translate}}</button>
        <button type="button" class="bg-white text-tColor font-sans text-lg py-2 px-4 cursor-pointer"  matStepperNext>{{'next' | translate}}</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="vesselStepper.sectionThreeFormGroup">
    <form [formGroup]="vesselStepper.sectionThreeFormGroup" (ngSubmit)="onSubmit()" autocomplete="off" class="overflow-y-auto max-h-[calc(100vh-16rem)]">
      <ng-template matStepLabel>Section 3</ng-template>
      <div class="overscroll-y-contain object-scale-down">
        <div class="sm:max-w-2xl mb-5">
          <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
            <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'me' | translate}}</div>   
            <div class="col-span-2 flex flex-col gap-5">
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'mainEngineTypeCylinderLayout' | translate}}</span>
                  <!-- <select class="h-12 border border-lColor rounded px-4" pInputText formControlName="MEEngineType" #singleSelect>
                    <option value="" disabled selected>Select the Engine Type</option>
                    <option *ngFor="let type of engineTypes" [value]="type.type">{{type.type}}</option>
                  </select> -->
                  <p-dropdown 
                    [options]="engineTypes" 
                    [(ngModel)]="selectedMEType" 
                    formControlName="MEEngineType"
                    optionLabel="type" 
                    placeholder="Select the Vessel Type" 
                    [style]="{'background-color': 'white', 'height': '3rem', 'border': '1px solid #828282', 'border-radius': '0.25rem', 'padding': '0 0.5rem', 'width': '100%'}">
                  </p-dropdown>
                </div>
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'mainEngineTypeAdditionalInfo' | translate}}</span>
                  <input type="text" pInputText formControlName="METype" id="METype" class="h-12 border border-lColor rounded px-4" placeholder="Enter any Additional Main Engine Type Information"/>
                </div>                      
              <div class="grid grid-cols-2 gap-1">
                <div>
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'numOfDG' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter No. of D/G" formControlName="NoDG">
                </div>
                <div>       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'numOfShaftGenerators' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter No. of S/G" formControlName="NoSG">
                </div>
              </div>
              <div class="grid grid-cols-2 gap-1">
                <div>
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'cylinderBoreDiameter' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" id="bore" placeholder="Select Bore" formControlName="bore">  
                </div>
                <div>       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'cylinderStrokeLength' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Number of Stroke" id="stroke" formControlName="stroke">
                </div>
              </div>
              <div class="grid grid-cols-2 gap-1">
                <div>
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'cylinder' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" id="cylinder" placeholder="Enter Number of Cylinders" formControlName="cylinder">  
                </div>
                <div>       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'engineStrokeCycle' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Select Engine Stroke Cycle" id="cycle" formControlName="cycle">
                </div>
              </div>  
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'mainEngineMaxKW' | translate}}</span>
                <input type="number" pInputText id="meMaxKw" placeholder="Enter Main Engine Max. KW" formControlName="meMaxKw" class=" h-12 border border-lColor rounded px-4"/>
              </div>
              <div class="grid grid-cols-2 gap-1">
                <div>
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'mainEngineMaxRPM' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" id="meMaxRpm" placeholder="Enter M/E Max. RPM" formControlName="meMaxRpm">  
                </div>
                <div>       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'mainEngineMinRPM' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter M/E Minimum RPM" id="meMinRpm" formControlName="meMinRpm">
                </div>
              </div>
              <div class="grid grid-cols-2 gap-1">
                <div>
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'normalMainEngineMaxKW' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" id="normalMeMaxKw" placeholder="Enter Normal Main Engine Max. Kw" formControlName="normalMeMaxKw">  
                </div>
                <div>       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'normalMainEngineMaxRPM' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Normal Main Engine Max. RPM" id="normalMeMaxRpm" formControlName="normalMeMaxRpm">
                </div>
              </div>
              <div class="grid grid-cols-2 gap-1">
                <div>
                  <span class="text-lColor font-medium text-base leading-normal font-sans mb-5">{{'engineFuelOilUnit' | translate}}</span><br/><br/>
                  <input type="text" pInputText [matAutocomplete]="auto7" class="w-full h-12 border border-lColor rounded px-4" id="engineFoUnit" placeholder="Enter Engine FO Unit" formControlName="engineFoUnit">  
                  <mat-autocomplete #auto7="matAutocomplete">
                    <mat-option *ngFor="let type of engineFoUnitType" [value]="type.value">
                      {{ type.type }}
                    </mat-option>
                  </mat-autocomplete>
                </div>
                <div>       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'engineFuelOilUnitFactor' | translate}}</span><br/>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Engine FO Unit Factor" id="engineFoUnitFactor" formControlName="engineFoUnitFactor">
                </div>
              </div>      
            </div>
          </div>
        </div>
        
        <hr class="w-full">
        <div class="sm:max-w-2xl mb-5">
          <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
            <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'gen' | translate}}</div>   
            <div class="col-span-2 flex flex-col gap-5">
              <div class="grid grid-cols-2 gap-1">
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'sgMaxKW' | translate}}</span>
                  <input type="text" pInputText id="sgMaxKw" placeholder="Enter S/G Max. KW" formControlName="sgMaxKw" class=" h-12 border border-lColor rounded px-4"/>
                </div>
                <div>       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'dgMaxKW' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter D/G Max. KW" id="geMaxKw" formControlName="geMaxKw">
                </div>
              </div> 
          </div>
        </div> <br/> 
        <hr class="w-full">
        <div class="sm:max-w-2xl mb-5">
          <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
            <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'fuel_con_info' | translate}}</div>   
            <div class="col-span-2 flex flex-col gap-5">
              <div class="grid grid-cols-2 gap-1">
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'meFuelConsumptionRate' | translate}}</span>
                  <input type="text" pInputText id="meFuelConsumptionRate" placeholder="Enter ME Fuel Consumption Rate" formControlName="meFuelConsumptionRate" class=" h-12 border border-lColor rounded px-4"/>
                </div>
                <div>       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'geFuelConsumptionRate' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter GE Fuel Consumption Rate" id="geFuelConsumptionRate" formControlName="geFuelConsumptionRate">
                </div>
              </div> 
          </div>
        </div>  <br/>
        <hr class="w-full">
        <div class="sm:max-w-2xl mb-5">
          <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
            <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'rudder&propInfo' | translate}}</div>   
            <div class="col-span-2 flex flex-col gap-5">
              <div class="grid grid-cols-2 gap-1">
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'noRudders' | translate}}</span>
                  <input type="text" pInputText id="NoRudders" placeholder="Enter No Rudders" formControlName="NoRudders" class=" h-12 border border-lColor rounded px-4"/>
                </div>
                <div>       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'rudderType' | translate}}</span>
                  <input type="text" [matAutocomplete]="auto2" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Rudder Type" id="rudderType" formControlName="rudderType">
                  <mat-autocomplete #auto2="matAutocomplete">
                    <mat-option *ngFor="let type of RudderTypes" [value]="type.type">
                        {{ type.type }}
                    </mat-option>
                </mat-autocomplete>
                </div>
              </div> 
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'propellerType' | translate}}</span>
                <input type="text" formControlName="propellerType" pInputText [matAutocomplete]="auto3" id="propellerType" class="h-12 border border-lColor rounded px-4" placeholder="Enter Propeller Type"/>
                <mat-autocomplete #auto3="matAutocomplete">
                  <mat-option *ngFor="let type of PropellerTypes" [value]="type.type">
                      {{ type.type }}
                  </mat-option>
              </mat-autocomplete>
              </div>
              <div class="grid grid-cols-2 gap-1">
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'bladeDiameter' | translate}}</span>
                  <input type="text" pInputText id="bladeDiameter" placeholder="Enter Blade Diameter" formControlName="bladeDiameter" class=" h-12 border border-lColor rounded px-4"/>
                </div>
                <div>       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'numOfBlades' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter No. of Blades" id="NoBlades" formControlName="NoBlades">
                </div>
              </div>
              <div class="grid grid-cols-2 gap-1">
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'minCPPAngle' | translate}}</span>
                  <input type="text" pInputText id="minCPPAngle" placeholder="Enter Min CPP Angle" formControlName="minCPPAngle" class=" h-12 border border-lColor rounded px-4"/>
                </div>
                <div>       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'maxCPPAngle' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Max CPP Angle" id="maxCPPAngle" formControlName="maxCPPAngle">
                </div>
              </div>
              <br><br>
          </div>
        </div>
        </div>
        </div>
        </div>
      </div>
      <div class="fixed bottom-0 bg-white flex mt-8 mb-1 w-full ">
        <button type="button" class="bg-white font-sans text-lg text-lColor py-2 px-4 cursor-pointer ml-80" matStepperPrevious>{{'back' | translate}}</button>
        <button type="button" class="bg-white text-tColor font-sans text-lg py-2 px-4 cursor-pointer"  matStepperNext>{{'next' | translate}}</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="vesselStepper.sectionFourFormGroup">
    <form [formGroup]="vesselStepper.sectionFourFormGroup" (ngSubmit)="onSubmit()" autocomplete="off" class="overflow-y-auto max-h-[calc(100vh-16rem)]">
      <ng-template matStepLabel>Section 4</ng-template>
      <div class="overscroll-y-contain object-scale-down">
        <div class="sm:max-w-2xl mb-5">
          <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
            <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'capacity' | translate}}</div>   
            <div class="col-span-2 flex flex-col gap-5"> 
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'noTank' | translate}}</span>
                <input type="number" pInputText id="NoTank" placeholder="Enter No Tank" formControlName="NoTank" class=" h-12 border border-lColor rounded px-4"/>
              </div>
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'passengerCapacity' | translate}}</span>
                <input type="number" pInputText id="passengerCapacity" placeholder="Enter Passenger Capacity" formControlName="passengerCapacity" class=" h-12 border border-lColor rounded px-4"/>
              </div>    
            </div>
          </div>
        </div>
        
        <hr class="w-full">
        <div class="sm:max-w-2xl mb-5">
          <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
            <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'ccapacity' | translate}}</div>   
            <div class="col-span-2 flex flex-col gap-5">
              <div class="grid grid-cols-2 gap-1">
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'noTank' | translate}}</span>
                  <input type="text" pInputText id="NoTank" placeholder="Enter No Tank" formControlName="NoTank" class=" h-12 border border-lColor rounded px-4"/>
                </div>
                <div>       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'passengerCapacity' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Passenger Capacity" id="passengerCapacity" formControlName="passengerCapacity">
                </div>
              </div> 
              <div class="grid grid-cols-2 gap-1">
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'bale' | translate}}</span>
                  <input type="text" pInputText id="cargoBaleM3" placeholder="Enter Bale" formControlName="cargoBaleM3" class=" h-12 border border-lColor rounded px-4"/>
                </div>
                <div>       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'grain' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Grain" id="cargoGrainM3" formControlName="cargoGrainM3">
                </div>
              </div> 
              <div class="grid grid-cols-2 gap-1">
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'liquid' | translate}}</span>
                  <input type="text" pInputText id="cargoLiquidM3" placeholder="Enter Liquid" formControlName="cargoLiquidM3" class=" h-12 border border-lColor rounded px-4"/>
                </div>
                <div>       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'refrigerated' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Refrigerated" id="cargoRefM3" formControlName="cargoRefM3">
                </div>
              </div> 
              <div class="grid grid-cols-2 gap-1">
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'numOf20FeetContainer' | translate}}</span>
                  <input type="text" pInputText id="cargoNoTeu" placeholder="Enter No. of 20 Feet Container" formControlName="cargoNoTeu" class=" h-12 border border-lColor rounded px-4"/>
                </div>
                <div>       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'numOf40FeetContainer' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter No. of 40 Feet Container" id="cargoNoFeu" formControlName="cargoNoFeu">
                </div>
              </div> 
              <div class="grid grid-cols-2 gap-1">
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'numOfCars' | translate}}</span>
                  <input type="text" pInputText id="cargoNoCar" placeholder="Enter No.of Cars" formControlName="cargoNoCar" class=" h-12 border border-lColor rounded px-4"/>
                </div>
                <div>       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'numOfTrucks' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter No.of Trucks" id="cargoNoTruck" formControlName="cargoNoTruck">
                </div>
              </div> 
              <div class="grid grid-cols-2 gap-1">
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'numOfTrailers' | translate}}</span>
                  <input type="text" pInputText id="cargoNoTrailer" placeholder="Enter No.of Trailers" formControlName="cargoNoTrailer" class=" h-12 border border-lColor rounded px-4"/>
                </div>
                <div>       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'numOfForklifts' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter No.of Forklifts" id="cargoNoForklift" formControlName="cargoNoForklift">
                </div>
              </div> 
              <div class="grid grid-cols-2 gap-1">
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'numOfBulldozers' | translate}}</span>
                  <input type="text" pInputText id="cargoNoBulldozer" placeholder="Enter No.of Bulldozers" formControlName="cargoNoBulldozer" class=" h-12 border border-lColor rounded px-4"/>
                </div>
                <div>       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'numOfChassis' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter No.of Chassis" id="cargoNoChassis" formControlName="cargoNoChassis">
                </div>
              </div> 
              <div class="flex flex-col">
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'numOfTrainCars' | translate}}</span>
                <input type="text" pInputText id="cargoNoTrainCar" placeholder="Enter No.of Train Cars" formControlName="cargoNoTrainCar" class=" h-12 border border-lColor rounded px-4"/>
              </div>
              <div class="grid grid-cols-2 gap-1">
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'customizedUnitForCargo' | translate}}</span>
                  <input type="text" pInputText id="cargoOtherUnit" placeholder="Enter Customized Unit" formControlName="cargoOtherUnit" class=" h-12 border border-lColor rounded px-4"/>
                </div>
                <div>       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'capacity' | translate}}</span><br/><br/>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Cargo Capacity" id="cargoOtherCapacity" formControlName="cargoOtherCapacity"><br/>
                </div>
              </div> 
          </div>
        </div><br/> 
        <hr class="w-full">
        <div class="sm:max-w-2xl mb-5">
          <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
            <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'tcapactiy' | translate}}</div>   
              <div class="col-span-2 flex flex-col gap-5">
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'fuelOilDieselOil' | translate}}</span>
                  <input type="text" pInputText id="tankCapacityFoM3" placeholder="Enter Fuel Oil & Diesel Oil" formControlName="tankCapacityFoM3" class=" h-12 border border-lColor rounded px-4"/>
                </div>
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'freshWater' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Fresh Water" id="tankCapacityFwM3" formControlName="tankCapacityFwM3">
                </div>
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'waterBallast' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Water Ballast" id="tankCapacityWbM3" formControlName="tankCapacityWbM3">
                </div>
              </div><br/><br/>
            </div>  
          </div>
        </div>
      </div>
      <div class="fixed bottom-0 bg-white flex mt-8 mb-1 w-full ">
        <button type="button" class="bg-white font-sans text-lg text-lColor py-2 px-4 cursor-pointer ml-80" matStepperPrevious >{{'back' | translate}}</button>
        <button type="button" class="bg-white text-tColor font-sans text-lg py-2 px-4 cursor-pointer"  matStepperNext>{{'next' | translate}}</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="vesselStepper.sectionFiveFormGroup">
    <form [formGroup]="vesselStepper.sectionFiveFormGroup" (ngSubmit)="onSubmit()" autocomplete="off" class="overflow-y-auto max-h-[calc(100vh-16rem)]">
      <ng-template matStepLabel>Section 5</ng-template>
      <div class="overscroll-y-contain object-scale-down">
        <div class="sm:max-w-2xl mb-5">
          <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
            <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'dates' | translate}}</div> 
              <div class="col-span-2 flex flex-col gap-5">
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'steelCuttingDate' | translate}}</span>
                  <p-inputMask mask="9999-99-99" slotChar="yyyy-mm-dd" class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Steel Cutting Date" id="steelCuttingDate" formControlName="steelCuttingDate"></p-inputMask>
                </div>
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'keelLayingDate' | translate}}</span>
                  <p-inputMask mask="9999-99-99" slotChar="yyyy-mm-dd" class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Keel Laying Date" id="keelLayingDate" formControlName="keelLayingDate"></p-inputMask>
                </div>
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'launchDate' | translate}}</span>
                  <p-inputMask mask="9999-99-99" slotChar="yyyy-mm-dd" class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Launch Date" id="launchDate" formControlName="launchDate"></p-inputMask>
                </div>
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'deliveryDate' | translate}}</span>
                  <p-inputMask mask="9999-99-99" slotChar="yyyy-mm-dd" class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Delivery Date" id="deliveryDate" formControlName="deliveryDate"></p-inputMask>
                </div>
              </div>  
          </div>
        </div>
        <hr class="w-full">
        <div class="sm:max-w-2xl mb-5">
          <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
            <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'c&notation' | translate}}</div> 
              <div class="col-span-2 flex flex-col gap-5">
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'noCrewMembers' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter No Crew Members" id="NoCrewMembers" formControlName="NoCrewMembers">
                </div>
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'notation' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Notation" id="notation" formControlName="notation">
                </div>
          
              </div>  
          </div>
        </div>
      </div>
      <div class="fixed bottom-0 bg-white flex mt-8 mb-1 w-full ">
        <button type="button" class="bg-white font-sans text-lg text-lColor py-2 px-4 cursor-pointer ml-80" matStepperPrevious >{{'back' | translate}}</button>
        <button type="button" class="bg-white text-tColor font-sans text-lg py-2 px-4 cursor-pointer"  matStepperNext>{{'next' | translate}}</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="vesselStepper.sectionSixFormGroup">
    <form [formGroup]="vesselStepper.sectionSixFormGroup" (ngSubmit)="onSubmit()" autocomplete="off" class="overflow-y-auto max-h-[calc(100vh-16rem)]">
      <ng-template matStepLabel>Section 6</ng-template>
      <div class="overscroll-y-contain object-scale-down">
        <div class="sm:max-w-2xl mb-5">
          <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
            <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'sInfo' | translate}}</div> 
              <div class="col-span-2 flex flex-col gap-5">
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'salesRepName' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Sales Representative Name" id="salesRepresentativeName" formControlName="salesRepresentativeName">
                </div>
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'salesAddress' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Sales Address" id="salesAddress" formControlName="salesAddress">
                </div>
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'salesEmail' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Sales Email" id="salesEmail" formControlName="salesEmail">
                </div>
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'salesTelephone' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Sales Telephone" id="salesTelephone" formControlName="salesTelephone">
                </div>
              </div>  
          </div>
        </div>
        
        <hr class="w-full">
        <div class="sm:max-w-2xl mb-5">
          <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
            <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'sainfo' | translate}}</div>   
              <div class="col-span-2 flex flex-col gap-5">
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'salesAgentName' | translate}}</span>
                  <input type="text" pInputText id="salesAgentName" placeholder="Enter Sales Agent Name" formControlName="salesAgentName" class=" h-12 border border-lColor rounded px-4"/>
                </div>
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'salesAgentAddress' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Sales Agent Address" id="salesAgentAddress" formControlName="salesAgentAddress">
                </div>
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'salesAgentEmail' | translate}}</span>
                  <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Sales Agent Email" id="salesAgentEmail" formControlName="salesAgentEmail">
                </div>
              
              <div class="flex flex-col">       
                <span class="text-lColor font-medium text-base leading-normal font-sans">{{'salesAgentTelephone' | translate}}</span>
                <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Sales Agent Telephone" id="salesAgentTelephone" formControlName="salesAgentTelephone">
              </div>
            </div>
            </div>
            </div>  
        </div>
        <hr class="w-full">
        <div class="sm:max-w-2xl mb-5">
          <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
            <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'vwarrantyInfo' | translate}}</div>   
              <div class="col-span-2 flex flex-col gap-5">
                <div class="flex flex-col">
                  <span class="text-lColor font-medium text-base leading-normal font-sans">*{{'vesselWarrantyStartDate' | translate}}</span>
                  <p-inputMask mask="9999-99-99" slotChar="yyyy-mm-dd" formControlName="vesselWarrantyStartDate" placeholder="Enter Vessel Warranty Start Date" [required]="true" [autoFocus] = "false" class="w-full h-12 border border-lColor rounded px-4"></p-inputMask> 
                  <div *ngIf="sectionSixForm.get('vesselWarrantyStartDate')?.hasError('required') && sectionSixForm.get('vesselWarrantyStartDate')?.touched" class="error-message">
                    This Field is Required
                  </div>
                </div>
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">*{{'vesselWarrantyBaseExpDate' | translate}}</span>
                  <p-inputMask mask="9999-99-99" slotChar="yyyy-mm-dd" formControlName="vesselWarrantyBaseExpDate" placeholder="Enter Vessel Warranty Base Exp. Date" [required]="true" [autoFocus] = "false" class="w-full h-12 border border-lColor rounded px-4"></p-inputMask>
                  <div *ngIf="sectionSixForm.get('vesselWarrantyBaseExpDate')?.hasError('required') && sectionSixForm.get('vesselWarrantyBaseExpDate')?.touched" class="error-message">
                    This Field is Required
                  </div>
                </div>
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">*{{'vesselWarrantyExtExpDate' | translate}}</span>
                  <p-inputMask mask="9999-99-99" slotChar="yyyy-mm-dd" formControlName="vesselWarrantyExtExpDate" placeholder="Enter Vessel Warranty Ext.Exp.Date" [required]="true" [autoFocus] = "false" class="w-full h-12 border border-lColor rounded px-4"></p-inputMask>
                  <div *ngIf="sectionSixForm.get('vesselWarrantyExtExpDate')?.hasError('required') && sectionSixForm.get('vesselWarrantyExtExpDate')?.touched" class="error-message">
                    This Field is Required
                  </div>                
                </div>
              <div class="flex flex-col">       
                <span class="text-lColor font-medium text-base leading-normal font-sans">*{{'vesselWarrantyExpDate' | translate}}</span>
                <p-inputMask mask="9999-99-99" slotChar="yyyy-mm-dd" formControlName="vesselWarrantyExpDate" placeholder="Enter Vessel Warranty Exp.Date" [required]="true" [autoFocus] = "false" class="w-full h-12 border border-lColor rounded px-4"></p-inputMask>
              <div *ngIf="sectionSixForm.get('vesselWarrantyExpDate')?.hasError('required') && sectionSixForm.get('vesselWarrantyExpDate')?.touched" class="error-message">
                This Field is Required
              </div>
            </div>
            </div>
          </div>
        </div>
        <hr class="w-full">
        <form [formGroup]="vesselStepper.businesSection" autocomplete="off">
          <div class="sm:max-w-2xl mb-5">
            <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
                <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'bizactivity' | translate}}</div>   
                  <div class="col-span-2 flex flex-col gap-5">
                    <div class="flex flex-col">
                      <span class="text-lColor font-medium text-base leading-normal font-sans">{{'remark' | translate}}</span>
                      <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Remarks" id="remarks" formControlName="remarks">
                    </div>
                    <div class="flex flex-col">       
                      <span class="text-lColor font-medium text-base leading-normal font-sans">{{'update' | translate}}</span>
                      <p-inputMask mask="9999-99-99" slotChar="yyyy-mm-dd" formControlName="update" placeholder="Enter Update" [autoFocus] = "false" class="w-full h-12 border border-lColor rounded px-4"></p-inputMask>
                    </div>
                    <div class="flex flex-col">       
                      <span class="text-lColor font-medium text-base leading-normal font-sans">{{'budgetPeriod' | translate}}</span>
                      <p-inputMask mask="9999-99-99" slotChar="yyyy-mm-dd" formControlName="budgetperiod" placeholder="Enter Budget Period" [autoFocus] = "false" class="w-full h-12 border border-lColor rounded px-4"></p-inputMask>
                    </div>
                  <div class="flex flex-col">       
                    <span class="text-lColor font-medium text-base leading-normal font-sans">{{'activity' | translate}}</span>
                    <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Activities" id="activities" formControlName="activities">
                  </div>
                  <div class="flex flex-col">       
                    <span class="text-lColor font-medium text-base leading-normal font-sans">{{'smonth' | translate}}</span>
                    <p-inputMask mask="9999-99-99" slotChar="yyyy-mm-dd" formControlName="salesmonth" placeholder="Enter Sales Month" [autoFocus] = "false" class="w-full h-12 border border-lColor rounded px-4" ></p-inputMask>
                  </div>
                  <div class="flex flex-col">       
                    <span class="text-lColor font-medium text-base leading-normal font-sans">{{'proposalDetails' | translate}}</span>
                    <input type="text" pInputText class="w-full h-12 border border-lColor rounded px-4" placeholder="Enter Proposal Details" id="proposaldetails" formControlName="proposaldetails">
                  </div>
                  <!--Toogle-->
                  <div class="flex flex-col">       
                    <p style="margin-top: 10px;">
                      <span class="text-lColor font-medium text-base leading-normal font-sans">{{'cInput' | translate}}</span>
                     <mat-button-toggle-group formControlName="completedinput">
                        <mat-button-toggle value="true" >
                          True
                        </mat-button-toggle>
                        <mat-button-toggle value="false">
                          False 
                        </mat-button-toggle>
                      </mat-button-toggle-group>
                    </p>
                    <p>
                    <span class="text-lColor font-medium text-base leading-normal font-sans" style="margin-top: 10px;">{{'istConstruct' | translate}}</span> 
                    <mat-button-toggle-group formControlName="lcstconfirm">
                       <mat-button-toggle value="true" >
                         True
                       </mat-button-toggle>
                       <mat-button-toggle value="false">
                         False 
                       </mat-button-toggle>
                     </mat-button-toggle-group>
                   </p>
                  </div>
                  <!--Toogle End-->
                  <div class="flex flex-col">       
                    <span class="text-lColor font-medium text-base leading-normal font-sans">{{'nextRenewalSurveyDate' | translate}}</span>
                    <p-inputMask mask="9999-99-99" slotChar="yyyy-mm-dd" formControlName="nextrenewalsurveydate" placeholder="Enter Next Renewal Survey Date" [autoFocus] = "false" class="w-full h-12 border border-lColor rounded px-4"></p-inputMask>
                  </div>
                  <div class="flex flex-col">       
                    <span class="text-lColor font-medium text-base leading-normal font-sans">{{'nextRenewalSurveyDetails' | translate}}</span>
                    <input type="text" pInputText  placeholder="Enter Next Renewal Survey Details" id="nextrenewalsurveydetails" formControlName="nextrenewalsurveydetails" class="w-full h-12 border border-lColor rounded px-4">
                  </div>
                  <div class="flex flex-col">       
                    <span class="text-lColor font-medium text-base leading-normal font-sans">{{'nextDockDates' | translate}}</span>
                    <p-inputMask mask="9999-99-99" slotChar="yyyy-mm-dd" formControlName="nextdockdate" placeholder="Enter Next Dock Date" [autoFocus] = "false" class="w-full h-12 border border-lColor rounded px-4"></p-inputMask>
                  </div>
                  <div class="flex flex-col">       
                    <span class="text-lColor font-medium text-base leading-normal font-sans">{{'nextDockDetails' | translate}}</span>
                    <input type="text" pInputText placeholder="Enter Next Dock Details" id="nextdockdetails" formControlName="nextdockdetails" class="w-full h-12 border border-lColor rounded px-4">
                  </div>
                  <br/><br/>
                </div>
            </div>
          </div>
        </form>
        <div class="fixed bottom-0 bg-white flex mt-8 mb-1 w-full ">
          <button type="button" class="bg-white font-sans text-lg text-lColor py-2 px-4 cursor-pointer ml-80" matStepperPrevious>{{'back' | translate}}</button>
          <button type="button" class="bg-white text-tColor font-sans text-lg py-2 px-4 cursor-pointer" (click)="onNext()"
          [disabled]="isSubmitting || !vesselStepper.sectionSixFormGroup.valid">
            <div *ngIf="isSubmitting" class="spinner-container">
              <div class="spinner"></div>
            </div>
            <span *ngIf="!isSubmitting && !moveNext">{{'addThisVessel'|translate}}</span>
          </button>
          <button mat-button matStepperNext *ngIf="moveNext" class="bg-white text-tColor font-sans text-lg py-2 px-4 cursor-pointer">Next</button>
        </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="vesselStepper.sectionSevenFormGroup">
    <ng-template matStepLabel>Section 7</ng-template>
    <div class="sm:max-w-2xl mb-5">
      <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
          <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'uploadVesselPhoto' | translate}}</div>   
            <div class="col-span-2 flex flex-col gap-5">
              <div class="flex flex-col">       
                <div class="uploding-section">
                  <label class="custom-file-upload">
                    <input type="file" (change)="onSelect($event)" accept="image/png" multiple>
                  <i class="pi pi-image"></i> Select Image
                  </label>
                  <button class="upload-button" (click)="uploadnewImage()" ><i class="pi pi-upload"></i> Upload</button>
                </div>
                
                <div style="display: flex; flex-direction: row; margin: 10px;">
                  <ng-container *ngIf="selectedImage">
                    <img [src]="selectedImage" alt="Selected Image" width="80px" height="80px">
                    <p style="margin: 5px;">{{ image?.name }}</p>
                  </ng-container>
                </div>
                <section [ngSwitch]="statusImg">
                  <p *ngSwitchCase="'uploading'">Uploading...</p>
                  <p *ngSwitchCase="'success'">Done!</p>
                  <p *ngSwitchCase="'fail'">Error!</p>
                </section>
              </div>
            </div>
          </div>
    </div>
    <div class="sm:max-w-2xl mb-5">
      <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
          <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'subscription' | translate}}</div>   
            <div class="col-span-2 flex flex-col gap-5">
              <div class="flex flex-col">
                <div style="width: 100px">
                  <p-treeSelect
                    [options]="primeNgDataset"
                    [(ngModel)]="selectedNodes"
                    [ngModelOptions]="{standalone: true}"
                    class="md:w-20px w-full"
                    containerStyleClass="w-full"
                    [panelStyle]="{ 'width': '50pxs' }"
                    placeholder="Select the item"
                    selectionMode="checkbox"
                    [metaKeySelection]="false"
                    [propagateSelectionUp]="false" 
                    [propagateSelectionDown]="false"
                    [showClear]="false"
                    [filter]="false"
                    (onNodeSelect)="onNodeSelect($event)"
                    (onNodeUnselect)="onNodeUnselect($event)"  
                    display="chip"> 
                    <ng-template pTemplate="header" let-node>
                    </ng-template> 
                  </p-treeSelect>
                </div>
              <br><br><br>
              <div style="width: 26.25rem">
                <p-tree class="w-full md:w-26.25rem" 
                selectionMode="checkbox" 
                [value]="primeNgTreeDataSet" 
                [metaKeySelection]="false"
                [propagateSelectionUp]="false" 
                [propagateSelectionDown]="false"
                (onNodeSelect)="editSelection($event)"
                (onNodeUnselect)="editSelection($event)"
                expanded = "true"
                ></p-tree></div>
              </div> 
              <div class="flex flex-col">
                <button class="upload-button" (click)="upload_applications()" style="width: 150px;"><i class="pi pi-plus"></i> Subscribe</button>
                <section [ngSwitch]="statusApp">
                  <p *ngSwitchCase="'uploading'">Uploading...</p>
                  <p *ngSwitchCase="'success'">Done!</p>
                  <p *ngSwitchCase="'fail'">Error!</p>
                  <!-- <p *ngSwitchDefault>Waiting to upload...</p> -->
                </section>
              </div>   
          </div>
      </div>
    </div>
    <div class="sm:max-w-2xl mb-5">
      <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
          <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'vessselMappingFileUpload' | translate}}</div>   
            <div class="col-span-2 flex flex-col gap-5">
              <div class="flex flex-col">
                <label class="custom-file-upload" style="width: 150px;">
                  <input type="file" class="file-inputs" (change)="onChange($event)" />
                <i class="pi pi-file"></i> Select a File
                </label>
                  <div *ngIf="file">
                    <section class="file-info">
                      File details:
                      <ul>
                        <li>Name: {{file.name}}</li>
                        <li>Type: {{file.type}}</li>
                        <li>Size: {{file.size}} bytes</li>
                      </ul>
                    </section>
                  
                    <button (click)="getSelectedVesselId()" class="upload-button" type="button"><i class="pi pi-upload"></i> Upload</button>
                  
                  <section [ngSwitch]="status">
                      <p *ngSwitchCase="'uploading'">Uploading...</p>
                      <p *ngSwitchCase="'success'">Done!</p>
                      <p *ngSwitchCase="'fail'">Error!</p>
                      <p *ngSwitchDefault>Waiting to upload...</p>
                    </section><br /><br /><br /><br /><br />
                    </div>
              </div> 
          </div>
      </div>
    </div>
    <div class="fixed bottom-0 bg-white flex mt-8 mb-1 w-full ">
      <button type="button" class="bg-white text-tColor font-sans text-lg py-2 px-4 cursor-pointer ml-80"  matStepperNext>{{'next' | translate}}</button>
    </div>
  </mat-step>
<mat-step [stepControl]="vesselStepper.sectionEightFormGroup">
  <form [formGroup]="vesselStepper.sectionEightFormGroup" autocomplete="off">
      <ng-template matStepLabel>Section 8</ng-template>
      <div class="overscroll-y-contain object-scale-down">
        <div class="sm:max-w-2xl mb-5">
          <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
            <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'offlineStatusMessage' | translate}}</div>   
              <div class="col-span-2 flex flex-col gap-5">
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'offlineTitle' | translate}}</span>
                  <input type="text" pInputText placeholder="Enter title" id="offlineTitle" formControlName="offlineTitle" class="w-full h-12 border border-lColor rounded px-4">
                </div>
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'offlineContent' | translate}}</span>
                  <input type="text" pInputText placeholder="Enter content" id="offlineContent" formControlName="offlineContent" class="w-full h-12 border border-lColor rounded px-4">
                </div>
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'offlinehtmlContnet' | translate}}</span>
                  <input type="text" pInputText placeholder="Enter HTML Content" id="offlinehtmlContnet" formControlName="offlinehtmlContnet" class="w-full h-12 border border-lColor rounded px-4">
                </div>
              </div>
            </div>
        </div>
        <hr class="w-full">

        <div class="sm:max-w-2xl mb-5">
          <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
            <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'onlineStatusMessage' | translate}}</div>   
              <div class="col-span-2 flex flex-col gap-5">
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'onlineTitle' | translate}}</span>
                  <input type="text" pInputText placeholder="Enter title" id="onlineContent" formControlName="onlineContent" class="w-full h-12 border border-lColor rounded px-4">
                </div>
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'onlineContent' | translate}}</span>
                  <input type="text" pInputText placeholder="Enter content" id="onlineContent" formControlName="onlineContent" class="w-full h-12 border border-lColor rounded px-4">
                </div>
                <div class="flex flex-col">       
                  <span class="text-lColor font-medium text-base leading-normal font-sans">{{'onlinehtmlContnet' | translate}}</span>
                  <input type="text" pInputText placeholder="Enter HTML Content" id="onlinehtmlContnet" formControlName="onlinehtmlContnet" class="w-full h-12 border border-lColor rounded px-4">
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="fixed bottom-0 bg-white flex mt-8 mb-1 w-full ">
        <button type="button" class="bg-white font-sans text-lg text-lColor py-2 px-4 cursor-pointer ml-80" matStepperPrevious >{{'back' | translate}}</button>
        <button type="button" class="bg-white text-tColor font-sans text-lg py-2 px-4 cursor-pointer"  matStepperNext (click)="createAlert()">{{'next' | translate}}</button>
      </div>
    </form>
</mat-step>
<mat-step>
  <ng-template matStepLabel>Done</ng-template>
  <p>{{'yourDone' | translate}}</p>
 
  <div class="fixed bottom-0 bg-white flex mt-8 mb-1 w-full ">
    <button type="button" class="bg-white font-sans text-lg text-lColor py-2 px-4 cursor-pointer ml-80" (click)="stepper.reset()">{{'finish' | translate}}</button>
  </div>
</mat-step>
</mat-stepper>