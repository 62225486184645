<div class="flex mt-16 ml-16" style="margin-top: 125px;">
    <img class="icon"/>
    <div class="ml-3">
      <h1 class="font-sans text-tColor font-inter text-3xl font-bold black-text">{{'userOverView' | translate}}</h1>
      <h4 class="font-sans text-xs font-normal text-lColor">{{userCounter}} {{'totals' | translate}}</h4>
  </div>
</div>
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" class="custom-tab-bar">
    <mat-tab label="{{ 'allUsers' | translate }}"></mat-tab>
    <mat-tab label="{{ 'recentUsers' | translate }}"></mat-tab>
  </mat-tab-group>
<hr class="styled-line">
<div *ngIf="loadingFlag" class="black-text">Now Loading</div>
<router-outlet (activate)="onActivate($event)"></router-outlet>
  