import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { VesselService } from 'src/app/_service/vessel.service';

import { getVesselData, postVesselData} from 'src/app/_model/vessel';
import * as routing from '../../../_model/routing';
import { CompanyService } from 'src/app/_service/company.service';
import { MatTableDataSource } from '@angular/material/table';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-vessel-detail',
  templateUrl: './vessel-detail.component.html',
  styleUrls: ['./vessel-detail.component.css']
})
export class VesselDetailComponent implements OnInit {
  @Output() dataChange = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private companyService: CompanyService,
    private vesselService: VesselService,
  ) { }

  vesselID = '';
  vesselData!: postVesselData;
  applicationID = '';
  idNameStorage: {key:string, displayName:string ,id:string, name: string}[] = [];
  columnName = ['key', 'value'];
  tableData: object[] = [];
  dataSource: any;



  ngOnInit(): void {
    // this.getVesselID();
    // this.getVesselData(this.vesselID);
  }

  // getVesselID() {
  //   let id = this.route.snapshot.paramMap.get('id');
  //   if(id===null){
  //     this.location.back();
  //   }else{
  //    this.vesselID = id;
  //    this.vesselService.selectedVesselID = this.vesselID; 
  //   }
  // }

  // getVesselData(id:string) {
  //   const data = this.vesselService.vesselList.length ? this.vesselService.vesselList.find(e => e.id === id) : this.vesselService.associateVesselList.find(e => e.id === id);
  //   if(data === undefined){
  //     this.location.back();
  //   }else{
  //     this.changeGetToPostVesselData(data);
  //     this.getVesselApplication().then(() => this.setTableData());
  //   }
  // }

  // async getVesselApplication() {
  //   const finalValue = await firstValueFrom(this.vesselService.getVesselApplication());
  //   finalValue.forEach((e) => {
  //     if(e.vessel_id === this.vesselID){
  //       this.applicationID = e.application_id;
  //     };
  //   });
  // }

  // editVesselData() {
  //   this.vesselService.editVesselFlag = true;
  //   this.vesselService.editVesselData = this.vesselData;
  //   this.router.navigate([routing.VesselRegister]);
  // }
  
  // editVesselApplication() {
  //   this.router.navigate([routing.VesselApplicationRegister, {vesselID: this.vesselID, applicationID: this.applicationID}]);
  // }

  // deleteVesselData() {
  //   if(this.checkSubmit()){
  //     this.vesselService.deleteVesselData()
  //     .subscribe({
  //       complete: () => {this.dataChange.emit();}
  //     })
  //   }
  // }

  // changeGetToPostVesselData(data: getVesselData) {
  //   this.vesselData = {
  //     name: data.name,
  //     otherName: data.otherName,
  //     imo: data.imo,
  //     vesselNumber: data.vesselNumber,
  //     firstClass: data.firstClass,
  //     secondClass: data.secondClass,
  //     thumbnailExtension: data.thumbnailExtension,
  //     owner_id: data.owner_id,
  //     manager_id: data.manager_id,
  //     builder_id: data.builder_id,
  //     maintener_id: data.maintener_id,
  //     METype: data.METype,
  //     NoME: data.NoME,
  //     NoDG: data.NoDG,
  //     NoSG: data.NoSG,
  //     MEEngineType: data.MEEngineType,
  //     bore: data.bore,
  //     stroke: data.stroke,
  //     cylinder: data.cylinder,
  //     cycle: data.cycle,
  //     meMaxKw: data.meMaxKw,
  //     normalMeMaxKw: data.meMaxKw,
  //     meMaxRpm: data.meMaxRpm,
  //     normalMeMaxRpm: data.normalMeMaxRpm,
  //     meMinRpm: data.meMinRpm,
  //     geMaxKw: data.geMaxKw,
  //     sgMaxKw: data.sgMaxKw,
  //     engineFoUnit: data.engineFoUnit,
  //     engineFoUnitFactor: data.engineFoUnitFactor,
  //     vesselType: data.vesselType,
  //     NoLeg: data.NoLeg,
  //     NoCrane: data.NoCrane,
  //     NoThruster: data.NoThruster,
  //     MEngine: data?.MEngine
  //   }

  //   this.idNameStorage = [
  //     {key: 'owner_id', displayName: 'Owner', id: data.owner_id, name: data.vesselOwner?.name ? data.vesselOwner?.name : ''},
  //     {key: 'manager_id', displayName: 'Manager', id: data.manager_id, name: data.vesselManagement?.name ?  data.vesselManagement?.name : ''},
  //     {key: 'builder_id', displayName: 'Builer', id: data.builder_id, name: data.vesselBuilder?.name ? data.vesselBuilder?.name : ''},
  //     {key: 'maintener_id', displayName: 'Maintainer', id: data.maintener_id, name: data.vesselMaintainer?.name ? data.vesselMaintainer?.name : ''}
  //   ]
  // }

  // setTableData() {
  //   const keyArray = Object.keys(this.vesselData);
  //   const valueArray = Object.values(this.vesselData);
  //   for(let i=0; i<keyArray.length; i++){
  //     for(let j=0;  j<this.idNameStorage.length; j++){
  //       if(keyArray[i]===this.idNameStorage[j].key){
  //         this.tableData.push({'key': this.idNameStorage[j].displayName, 'value': this.idNameStorage[j].name});
  //       }
  //     };
  //     this.tableData.push({'key': keyArray[i], 'value': valueArray[i]});
  //   }
  //   this.tableData.push({'key': 'application_id', 'value': this.applicationID});
  //   console.log(this.tableData);
  //   this.dataSource = new MatTableDataSource(this.tableData);
  // }

  // goBackList() {
  //   this.location.back();
  // }

  // checkSubmit() {
  //   if(window.confirm('Can I delete this Vessel Data?')){
  //     return true;
  //   }else{
  //     return false;
  //   }
  // }

  // onRowClick(row: any) {
  //   for(let i=0; i<this.idNameStorage.length; i++){
  //     if(row.key === this.idNameStorage[i].key || row.key === this.idNameStorage[i].displayName){
  //       this.companyService.skipListFlag = true;
  //       this.router.navigate([routing.CompanyDetail, this.idNameStorage[i].id]);
  //     }
  //   }
  // }
}
