import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotRegisteredComponent } from '../not-registered/not-registered.component';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(
    private router: Router,
    private dialog: MatDialog
  ) { }
isBlocked: boolean = false;
  routingDashboard(notRegistered: boolean) {
    if (notRegistered) {this.isBlocked=true;
      this.router.navigate(['/not-registered']);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }
}
