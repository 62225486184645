<div class="main">
  <div class="btn-area">
    <div *ngIf="editMode" style="display: flex;flex-direction: row;">
      <button type="button" (click)="onClose()" class="cancel">{{'cancel' | translate}}</button>
      <button type="button" (click)="onSubmit()" class="submit">{{'save' | translate}}</button>
    </div>
  </div>

      <form [formGroup]="vesselForm" (ngSubmit)="onSubmit()" autocomplete="off">
        <div class="content" class="scrollable-section" [ngClass]="{'edit-mode-styles': editMode}">
          <div class="image-container">
            <div class="img-upload">
              <label>
                <img [src]="selectedImage" alt="!" class="upload-icon" [ngClass]="{'curved-image': isImageChanged}">
                <input type="file" id="avatar" accept="image/png" (change)="onSelect($event)" name="avatar" class="file-input"/>
              </label>
            </div>
            <label class="upload-label">{{'uploadVesselPhoto'|translate}}</label>
          </div>
          <br>

          <mat-expansion-panel style="background-color: rgba(0,0,0,0)">
            <mat-expansion-panel-header [collapsedHeight]="'80px'" style="color: var(--text-color)">
              {{'vesselInformation'|translate}}
            </mat-expansion-panel-header>
            <div class="field-class">
              <span class="field-label">*{{'vesselName'|translate}}</span>
              <input type="text" class="input-field" matInput required id="name" placeholder="Enter Vessel Name" formControlName="name">
                <div *ngIf="vesselForm.get('name')?.invalid && vesselForm.get('name')?.touched" class="error-message">
                  <div *ngIf="vesselForm.get('name')?.hasError('required')">This Field is Required</div>
                </div>
            </div>
            <div class="field-class">
              <span class="field-label">{{'otherName'|translate}}</span>
              <input type="text" class="input-field" matInput id="otherName" placeholder="Enter Vessel Other Name" formControlName="otherName">
            </div>
            <div class="field-class">
              <span class="field-label">{{'imo'|translate}}</span>
              <input type="text" class="input-field" matInput id="imo" placeholder="Enter IMO Number" formControlName="imo">
            </div>
            <div class="field-class">
              <span class="field-label">{{'vesselNumber'|translate}}</span>
              <input type="text" class="input-field" matInput id="vesselNumber" placeholder="Enter Vessel Shipyard Number" formControlName="vesselNumber">
            </div>
            <div class="field-class">
              <span class="field-label">{{'vesselType'|translate}}</span>
              <mat-select class="input-field" formControlName="vesselType" #singleSelect placeholder="Select Vessel Type">
                <mat-option  *ngFor="let types of vesselTypes" [value]="types.type">{{types.type}}</mat-option>
              </mat-select>
            </div>
            <div class="field-class">
              <span class="field-label">{{'shipType'|translate}}</span>
              <input matInput type="text" class="input-field" id="shipType"  placeholder="Enter Ship Type" formControlName="shipType">
            </div>
            <div class="field-class">
              <span class="field-label">{{'builtYear'|translate}}</span>
              <input type="text" class="input-field" matInput id="builtYear" placeholder="Enter Vessel Built Year" formControlName="builtYear">
            </div>
            <div class="field-class">
              <span class="field-label">{{'callSign'|translate}}</span>
              <input type="text" class="input-field" matInput id="callsign" placeholder="Enter Call Sign" formControlName="callsign">
            </div>
            <div class="field-class">
              <span class="field-label">{{'hotelPower'|translate}}</span>
              <input type="text" class="input-field" matInput id="hotelPower" placeholder="Enter Hotel Power" formControlName="hotelPower" >
            </div>
            <div class="field-class">
              <span class="field-label">{{'numOfThrusters'|translate}}</span>
              <input type="text" class="input-field" matInput id="NoThruster" placeholder="Enter No. of Thruster(s)" formControlName="NoThruster">
            </div>
            <div class="field-class">
              <span class="field-label">{{'numOfCrane'|translate}}</span>
              <input type="text" class="input-field" matInput id="NoCrane"  placeholder="Enter No. of Crane(s)" formControlName="NoCrane">
            </div>
            <div class="field-class">
              <span class="field-label">{{'numOfLeg'|translate}}</span>
              <input type="text" class="input-field" matInput id="NoLeg" placeholder="Enter No. of Leg(s)" formControlName="NoLeg">
            </div>
            <div class="field-class">
              <span class="field-label">{{'numOfMainEngines'|translate}}</span>
              <input type="text" class="input-field" matInput id="NoME" placeholder="Enter No. of Main Engine(s)" formControlName="NoME">
            </div>
            <div class="field-class">
              <span class="field-label">{{'firstClassificationSociety'|translate}}</span>
              <input matInput type="tel" class="input-field" id="firstClass" [matAutocomplete]="auto4" formControlName="firstClass" placeholder="Enter Vessel First Class"> 
                  <mat-autocomplete #auto4="matAutocomplete">
                    <mat-option *ngFor="let type of classificationSocietyType" [value]="type.type">
                      {{ type.type }}
                    </mat-option>
                  </mat-autocomplete>
            </div>
            <div class="field-class">
              <span class="field-label">{{'secondClassificationSociety'|translate}}</span>
                  <input matInput type="tel" class="input-field" id="secondClass" [matAutocomplete]="auto5" formControlName="secondClass" placeholder="Enter Vessel Second Class">    
                  <mat-autocomplete #auto5="matAutocomplete">
                    <mat-option *ngFor="let type of classificationSocietyType" [value]="type.type">
                      {{ type.type }}
                    </mat-option>
                  </mat-autocomplete>   
            </div>
          </mat-expansion-panel>
          <hr class="styled-line"> 
            <!-- ID Information -->
          <mat-expansion-panel style="background-color: rgba(0,0,0,0)">
            <mat-expansion-panel-header [collapsedHeight]="'80px'" style="color: var(--text-color)">
              {{'idInfo' | translate}}
            </mat-expansion-panel-header>
            <div class="field-class">
              <span class="field-label">* {{'ownerId'|translate}}</span>
              <mat-select class="input-field" formControlName="owner_id" #singleSelect (selectionChange)="ownerSelection($event)" placeholder="Select a Company" required>
                <input type="text" (input)="filterCompany($event, 'owner')" id="owner_search" class="searches" placeholder="Search by name">
                <mat-option *ngFor="let company of ownerList" [value]="company.id">{{company.name}}</mat-option>
              </mat-select>
            </div>
            <div class="field-class">
              <span class="field-label">* {{'managerId'|translate}}</span>
              <mat-select class="input-field" formControlName="manager_id" #singleSelect (selectionChange)="onmanagerSelection($event)" placeholder="Select a Company" required>
                <input type="text" (input)="filterCompany($event, 'manager')" id="manager_search" class="searches" placeholder="Search by name">
                <mat-option *ngFor="let company of managerList" [value]="company.id">{{company.name}}</mat-option>
              </mat-select>
            </div>
            <div class="field-class">
              <span class="field-label">{{'exOwnerId'|translate}}</span>
              <mat-select class="input-field" formControlName="exowner_id" #singleSelect (selectionChange)="exownerSelection($event)" placeholder="Select a Company">
                <input type="text" (input)="filterCompany($event, 'exowner')" id="exowner_search" class="searches" placeholder="Search by name">
                <mat-option *ngFor="let company of exownerList" [value]="company.id">{{company.name}}</mat-option>
              </mat-select>
            </div>
            <div class="field-class">
              <span class="field-label">{{'exManagerId'|translate}}</span>
              <mat-select class="input-field" formControlName="exmanager_id" #singleSelect (selectionChange)="exmanagerSelection($event)" placeholder="Select a Company">
                <input type="text" (input)="filterCompany($event, 'exmanager')" id="exmanager_search" class="searches" placeholder="Search by name">
                <mat-option *ngFor="let company of exmanagerList" [value]="company.id">{{company.name}}</mat-option>
              </mat-select>
            </div>
            <div class="field-class">
              <span class="field-label">{{'builderId'|translate}}</span>
              <mat-select class="input-field" formControlName="builder_id" #singleSelect (selectionChange)="builderSelection($event)" placeholder="Select a Company">
                <input type="text" (input)="filterCompany($event, 'builder')" id="builder_search" class="searches" placeholder="Search by name">
                <mat-option *ngFor="let company of builderList" [value]="company.id">{{company.name}}</mat-option>
              </mat-select>
            </div>
            <div class="field-class">
              <span class="field-label">{{'maintainerId'|translate}}</span>
              <mat-select class="input-field" formControlName="maintener_id" #singleSelect (selectionChange)="maintainerSelection($event)" placeholder="Select a Company">
                <input type="text" (input)="filterCompany($event, 'maintainer')" id="maintainer_search" class="searches" placeholder="Search by name">
                <mat-option *ngFor="let company of maintainerList" [value]="company.id">{{company.name}}</mat-option>
              </mat-select>
            </div>
          </mat-expansion-panel>
          <hr class="styled-line">
          <!-- Vessel Warranty Information -->
          <mat-expansion-panel style="background-color: rgba(0,0,0,0)"> 
            <mat-expansion-panel-header [collapsedHeight]="'80px'" style="color: var(--text-color)">
              {{'vwarrantyInfo' | translate}}
            </mat-expansion-panel-header>
            <div class="field-class">
              <span class="field-label">*{{'vesselWarrantyStartDate'|translate}}</span>
              <p-inputMask mask="9999-99-99" slotChar="yyyy-mm-dd" formControlName="vesselWarrantyStartDate" placeholder="Enter Vessel Warranty Start Date" [required]="true" [autoFocus] = "false"></p-inputMask>
              <div *ngIf="vesselForm.get('vesselWarrantyStartDate')?.invalid && vesselForm.get('vesselWarrantyStartDate')?.touched" class="error-message">
                <div *ngIf="vesselForm.get('vesselWarrantyStartDate')?.hasError('required')">This Field is Required</div>
              </div>     
            </div>
            <div class="field-class">
              <span class="field-label">*{{'vesselWarrantyBaseExpDate'|translate}}</span>
              <p-inputMask mask="9999-99-99" slotChar="yyyy-mm-dd" formControlName="vesselWarrantyBaseExpDate" placeholder="Enter Vessel Warranty Base Exp. Date" [required]="true" [autoFocus] = "false"></p-inputMask>
              <div *ngIf="vesselForm.get('vesselWarrantyBaseExpDate')?.invalid && vesselForm.get('vesselWarrantyBaseExpDate')?.touched" class="error-message">
                <div *ngIf="vesselForm.get('vesselWarrantyBaseExpDate')?.hasError('required')">This Field is Required</div>
              </div> 
            </div>
            <div class="field-class">
              <span class="field-label">*{{'vesselWarrantyExtExpDate'|translate}}</span>
              <p-inputMask mask="9999-99-99" slotChar="yyyy-mm-dd" formControlName="vesselWarrantyExtExpDate" placeholder="Enter Vessel Warranty Ext. Exp. Date" [required]="true" [autoFocus] = "false"></p-inputMask>
              <div *ngIf="vesselForm.get('vesselWarrantyExtExpDate')?.invalid && vesselForm.get('vesselWarrantyExtExpDate')?.touched" class="error-message">
                <div *ngIf="vesselForm.get('vesselWarrantyExtExpDate')?.hasError('required')">This Field is Required</div>
              </div> 
            </div>
            <div class="field-class">
              <span class="field-label">*{{'vesselWarrantyExpDate'|translate}}</span>
              <p-inputMask mask="9999-99-99" slotChar="yyyy-mm-dd" formControlName="vesselWarrantyExpDate" placeholder="Enter Vessel Warranty Exp. Date" [required]="true" [autoFocus] = "false"></p-inputMask>
              <div *ngIf="vesselForm.get('vesselWarrantyExpDate')?.invalid && vesselForm.get('vesselWarrantyExpDate')?.touched" class="error-message">
                <div *ngIf="vesselForm.get('vesselWarrantyExpDate')?.hasError('required')">This Field is Required</div>
              </div> 
            </div>
          </mat-expansion-panel>  
        <hr class="styled-line"> 
          
        <mat-expansion-panel style="background-color: rgba(0,0,0,0)">
          <mat-expansion-panel-header [collapsedHeight]="'80px'" style="color: var(--text-color)">
            {{'me' | translate}}
          </mat-expansion-panel-header>
            <div class="content">
              <div class="field-class" >
                <span class="field-label">{{'mainEngineTypeCylinderLayout'|translate}}</span>
                <mat-select class="input-field" formControlName="MEEngineType" #singleSelect placeholder="Select Main Engine Type">
                  <mat-option  *ngFor="let type of engineTypes" [value]="type.type">{{type.type}}</mat-option>
                </mat-select>
              </div>
              <div class="field-class" >
                <span class="field-label">{{'mainEngineTypeAdditionalInfo'|translate}}</span>
                <input type="text" class="input-field" matInput id="METype"  placeholder="Enter Additional Main Engine Type Information" formControlName="METype">
              </div>
              <div class="field-class-two-column">
                <div class="field-class-sub-left">
                  <span class="field-label">{{'numOfDG'|translate}}</span>
                  <input type="text" class="input-field" matInput placeholder="Enter No. of D/G" formControlName="NoDG"> 
                </div>
                <div class="field-class-sub-right">
                  <span class="field-label">{{'numOfShaftGenerators'|translate}}</span>
                  <input type="text" class="input-field" matInput placeholder="Enter No. of S/G" formControlName="NoSG">
                </div>
              </div>
              <div class="field-class-two-column">
                <div class="field-class-sub-left">
                  <span class="field-label">{{'cylinderBoreDiameter'|translate}}</span>
                  <input type="text" class="input-field" matInput id="bore" placeholder="Select Bore" formControlName="bore">  
                </div>
                <div class="field-class-sub-right">
                  <span class="field-label">{{'cylinderStrokeLength'|translate}}</span>
                  <input type="text" class="input-field" matInput placeholder="Enter No. of Stroke(s)" id="stroke" formControlName="stroke">
                </div>
              </div>
              <div class="field-class-two-column">
                <div class="field-class-sub-left">
                  <span class="field-label">{{'cylinder'|translate}}</span>
                  <input type="text" class="input-field" matInput id="cylinder" placeholder="Enter No. of Cylinder(s)" formControlName="cylinder">  
                </div>
                <div class="field-class-sub-right">       
                  <span class="field-label">{{'engineStrokeCycle'|translate}}</span>
                  <input type="text" class="input-field" matInput placeholder="Enter Engine Stroke Cycle" id="cycle" formControlName="cycle">
                </div>
              </div>
              <div class="field-class">
                <span class="field-label">{{'mainEngineMaxKW'|translate}}</span>
                <input matInput type="number" formControlName="meMaxKw" id="meMaxKw" class="input-field" placeholder="Enter M/E Max KW">
              </div>
              <div class="field-class-two-column">
                <div class="field-class-sub-left">
                  <span class="field-label">{{'mainEngineMaxRPM'|translate}}</span>
                  <input matInput type="number" id="meMaxRpm" formControlName="meMaxRpm" class="input-field" placeholder="Enter M/E Max RPM">
                </div>
                <div class="field-class-sub-right">
                  <span class="field-label">{{'mainEngineMinRPM'|translate}}</span>
                  <input matInput type="number" id="meMinRpm" formControlName="meMinRpm" class="input-field" placeholder="Enter M/E Minimum RPM">
                </div>
              </div>
              <div class="field-class">
                <span class="field-label">{{'normalMainEngineMaxKW'|translate}}</span>
                <input matInput type="number" formControlName="normalMeMaxKw" class="input-field" placeholder="Enter Normal Main Engine Max. Kw">
              </div>
              <div class="field-class">
                <span class="field-label">{{'normalMainEngineMaxRPM'|translate}}</span>
                <input matInput type="number" formControlName="normalMeMaxRpm" class="input-field" placeholder="Enter Normal Main Engine Max. RPM">
              </div>
              <div class="field-class-two-column">
                <div class="field-class-sub-left">
                  <span class="field-label">{{'engineFuelOilUnit'|translate}}</span>
                  <input matInput type="text" formControlName="engineFoUnit" class="input-field" [matAutocomplete]="auto7"  placeholder="Enter Engine FO Unit" >
                  <mat-autocomplete #auto7="matAutocomplete">
                    <mat-option *ngFor="let type of engineFoUnitType" [value]="type.value">
                      {{ type.type }}
                    </mat-option>
                  </mat-autocomplete>
                </div>
                <div class="field-class-sub-right">
                  <span class="field-label">{{'engineFuelOilUnitFactor'|translate}}</span>
                  <input matInput type="number" formControlName="engineFoUnitFactor" class="input-field" placeholder="Enter Engine FO Unit Factor" >
                </div>
              </div>
            </div>           
        </mat-expansion-panel>
        <hr class="styled-line">

        <mat-expansion-panel style="background-color: rgba(0,0,0,0)">
          <mat-expansion-panel-header [collapsedHeight]="'80px'" style="color: var(--text-color)">
            {{'gen'|translate}}
          </mat-expansion-panel-header> 
          <ng-template matExpansionPanelContent>
            <div class="content">
              <div class="field-class-two-column">
                <div class="field-class-sub-left">
                  <span class="field-label">{{'sgMaxKW'|translate}}</span>
                  <input matInput type="number" formControlName="sgMaxKw" class="input-field" placeholder="Enter S/G Max. KW">
                </div>
                <div class="field-class-sub-right">
                  <span class="field-label">{{'dgMaxKW'|translate}}</span>
                  <input matInput type="number" formControlName="geMaxKw" id="geMaxKw" class="input-field" placeholder="Enter D/G Max. KW">
                </div>
              </div>
            </div>
          </ng-template>
        </mat-expansion-panel>
        <hr class="styled-line">

        <mat-expansion-panel style="background-color: rgba(0,0,0,0)">
          <mat-expansion-panel-header [collapsedHeight]="'80px'" style="color: var(--text-color)">
            {{'vesselIdentification' | translate}}
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <div class="content">
              <div class="field-class">
                <span class="field-label">{{'exName'|translate}}</span>
                <input matInput type="text" autocomplete="off" formControlName="exName" class="input-field" placeholder="Enter Ex. Name">
              </div>
              <div class="field-class">
                <span class="field-label">{{'mmsi'|translate}}</span>
                <input matInput id="mmsi" type="text" autocomplete="off" formControlName="mmsi" class="input-field" placeholder="Enter MMSI">
              </div>
              <div class="field-class">
                <span class="field-label">{{'officialNumber'|translate}}</span>
                <input matInput type="text" autocomplete="off" formControlName="officialNumber" class="input-field" placeholder="Enter Official Number">
              </div>  
              <div class="field-class">
                <span class="field-label">{{'signalLetter'|translate}}</span>
                <input matInput id="signalLetters" type="text" autocomplete="off" formControlName="signalLetters" class="input-field" placeholder="Enter Signal Letters">
              </div>  
              <div class="field-class">
                <span class="field-label">{{'flag'|translate}}</span>
                <input matInput type="text" autocomplete="off" formControlName="flag" class="input-field" placeholder="Enter Flag">
              </div>
              <div class="field-class">
                <span class="field-label">{{'portOfRegistry'|translate}}</span>
                <input matInput id="portOfRegistry" type="text" autocomplete="off" formControlName="portOfRegistry" class="input-field" placeholder="Enter Port of Registry">
              </div>
            </div>
          </ng-template>
        </mat-expansion-panel>
        <hr class="styled-line">
        <mat-expansion-panel style="background-color: rgba(0,0,0,0)">
          <mat-expansion-panel-header [collapsedHeight]="'80px'" style="color: var(--text-color)">
            {{'constructionDetails' | translate}}
           </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <div class="content">
              <div class="field-class">
                <span class="field-label">{{'builtPlace'|translate}}</span>
                <input matInput type="text" autocomplete="off" formControlName="builtPlace" id="builtPlace" class="input-field" placeholder="Enter Built Place">
              </div>
              <div class="field-class">
                <span class="field-label">{{'structuralDesignType'|translate}}</span>       
                <input matInput [matAutocomplete]="auto" formControlName="structuralDesignType" placeholder="Enter Structural Design Type" class="input-field">
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let option of structuralDesignTypes" [value]="option.type">
                    {{ option.type }}
                  </mat-option>
                </mat-autocomplete>
              </div>
              <div class="field-class">
                <span class="field-label">{{'purpose'|translate}}</span>
                <input matInput type="text" autocomplete="off" formControlName="purpose" class="input-field" placeholder="Enter Purpose">
              </div>
          
              <div class="field-class">
                <span class="field-label">{{'operationalStatus'|translate}}</span>
                <input matInput id="operationalStatus" [matAutocomplete]="auto1" type="text" autocomplete="off" formControlName="operationalStatus" class="input-field" placeholder="Enter Operational Status">
                <mat-autocomplete #auto1="matAutocomplete">
                    <mat-option *ngFor="let type of operationalStatus" [value]="type.type">
                        {{ type.type }}
                    </mat-option>
                </mat-autocomplete>
              </div>
            </div>
          </ng-template>
        </mat-expansion-panel>
        <hr class="styled-line">
        <mat-expansion-panel style="background-color: rgba(0,0,0,0)">
          <mat-expansion-panel-header [collapsedHeight]="'80px'" style="color: var(--text-color)">
            {{'c&notation' | translate}}
          </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <div class="content">
                <div class="field-class">
                  <span class="field-label">{{'noCrewMembers'|translate}}</span>
                  <input matInput type="number" autocomplete="off" formControlName="NoCrewMembers" class="input-field" placeholder="Enter No Crew Members">
                </div>
                <div class="field-class">
                  <span class="field-label">{{'notation'|translate}}</span>
                  <input matInput id="notation" type="text" autocomplete="off" formControlName="notation" class="input-field" placeholder="Enter Notation">
                </div>
              </div>
            </ng-template>
          </mat-expansion-panel>
          <hr class="styled-line">
          <mat-expansion-panel style="background-color: rgba(0,0,0,0)">         
            <mat-expansion-panel-header [collapsedHeight]="'80px'" style="color: var(--text-color)">
              {{'dates' | translate}}
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <div class="content">    
                <div class="field-class">
                  <span class="field-label">{{'steelCuttingDate'|translate}}</span>
                  <p-inputMask mask="9999-99-99" slotChar="yyyy-mm-dd" formControlName="steelCuttingDate" placeholder="Enter Steel Cutting Date"></p-inputMask>
                </div>
                <div class="field-class">
                  <span class="field-label">{{'keelLayingDate'|translate}}</span>
                  <p-inputMask mask="9999-99-99" slotChar="yyyy-mm-dd" formControlName="keelLayingDate" placeholder="Enter Keel Laying Date"></p-inputMask>
                </div>
                <div class="field-class">
                  <span class="field-label">{{'launchDate'|translate}}</span>
                  <p-inputMask mask="9999-99-99" slotChar="yyyy-mm-dd" formControlName="launchDate" placeholder="Enter Launch Date"></p-inputMask>

                </div>
                <div class="field-class">
                  <span class="field-label">{{'deliveryDate'|translate}}</span>
                  <p-inputMask mask="9999-99-99" slotChar="yyyy-mm-dd" formControlName="deliveryDate" placeholder="Enter Delivery Date"></p-inputMask>
                </div>
              </div>
            </ng-template>
        </mat-expansion-panel>
        <hr class="styled-line">
        <mat-expansion-panel style="background-color: rgba(0,0,0,0)">
          <mat-expansion-panel-header [collapsedHeight]="'80px'" style="color: var(--text-color)">
            {{'fuel_con_info' | translate}}
          </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <div class="content">
                <div class="field-class-two-column">
                  <div class="field-class-sub-left">
                    <span class="field-label">{{'meFuelConsumptionRate'|translate}}</span>
                    <input matInput type="number" autocomplete="off" formControlName="meFuelConsumptionRate" class="input-field" placeholder="Enter ME Fuel Consumption Rate">
                  </div>
                  <div class="field-class-sub-right">
                    <span class="field-label">{{'geFuelConsumptionRate'|translate}}</span>
                    <input matInput id="geMaxKw" type="number" autocomplete="off" formControlName="geFuelConsumptionRate" class="input-field" placeholder="Enter GE Fuel Consumption Rate">
                  </div>
                </div>
              </div>
            </ng-template>
        </mat-expansion-panel>
        <hr class="styled-line">
        <mat-expansion-panel style="background-color: rgba(0,0,0,0)">
          <mat-expansion-panel-header [collapsedHeight]="'80px'" style="color: var(--text-color)">
            {{'rudder&propInfo' | translate}}
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <div class="content">
              <div class="field-class-two-column">
                <div class="field-class-sub-left">
                  <span class="field-label">{{'noRudders'|translate}}</span>
                  <input matInput type="number" autocomplete="off" formControlName="NoRudders" class="input-field" placeholder="Enter No Rudders">
                </div>
                <div class="field-class-sub-right">
                  <span class="field-label">{{'rudderType'|translate}}</span>
                  <input matInput id="rudderType" type="text" [matAutocomplete]="auto2" autocomplete="off" formControlName="rudderType" class="input-field" placeholder="Enter Rudder Type">
                  <mat-autocomplete #auto2="matAutocomplete">
                    <mat-option *ngFor="let type of RudderTypes" [value]="type.type">
                      {{ type.type }}
                    </mat-option>
                  </mat-autocomplete>
                </div>
              </div>
              <div class="field-class">
                <span class="field-label">{{'propellerType'|translate}}</span>
                <input matInput type="text" [matAutocomplete]="auto3" autocomplete="off" formControlName="propellerType" class="input-field" placeholder="Enter Propeller Type">
                <mat-autocomplete #auto3="matAutocomplete">
                  <mat-option *ngFor="let type of PropellerTypes" [value]="type.type">
                    {{ type.type }}
                  </mat-option>
                </mat-autocomplete>
              </div>
              <div class="field-class-two-column">
                <div class="field-class-sub-left">
                  <span class="field-label">{{'bladeDiameter'|translate}}</span>
                  <input matInput id="bladeDiameter" type="number" autocomplete="off" formControlName="bladeDiameter" class="input-field" placeholder="Enter Blade Diameter">
                </div>
                <div class="field-class-sub-right">
                  <span class="field-label">{{'numOfBlades'|translate}}</span>
                  <input matInput type="number" autocomplete="off" formControlName="NoBlades" class="input-field" placeholder="Enter No. of Blades">
                </div>
              </div>
              <div class="field-class-two-column">
                <div class="field-class-sub-left">
                  <span class="field-label">{{'minCPPAngle'|translate}}</span>
                  <input matInput id="minCPPAngle" type="number" autocomplete="off" formControlName="minCPPAngle" class="input-field" placeholder="Enter Min CPP Angle">
                </div>
                <div class="field-class-sub-right">
                  <span class="field-label">{{'maxCPPAngle'|translate}}</span>
                  <input matInput id="maxCPPAngle" type="number" autocomplete="off" formControlName="maxCPPAngle" class="input-field" placeholder="Enter Max CPP Angle">
                </div>
              </div>
            </div>
          </ng-template>
        </mat-expansion-panel>
        <hr class="styled-line">
        <mat-expansion-panel style="background-color: rgba(0,0,0,0)">
          <mat-expansion-panel-header [collapsedHeight]="'80px'" style="color: var(--text-color)">
            {{'vmeasurements' | translate}}
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <div class="content">
              <div class="field-class">
                <span class="field-label">{{'noTank'|translate}}</span>
                <input matInput type="text" autocomplete="off" formControlName="NoTank" class="input-field" placeholder="Enter No Tank">
              </div>
              <div class="field-class-two-column">
                <div class="field-class-sub-left">
                  <span class="field-label">{{'totalLength'|translate}}</span>
                  <input matInput id="totalLength" type="number" autocomplete="off"  formControlName="totalLength" class="input-field" placeholder="Enter Total Length">
                </div>
                <div class="field-class-sub-right">
                  <span class="field-label">{{'waterLineLength'|translate}}</span>
                  <input matInput type="number" autocomplete="off" id="waterlineLength" formControlName="waterlineLength" class="input-field" placeholder="Enter Water Line Length">
                </div>
              </div>
              <div class="field-class">
                <span class="field-label">{{'lbp'|translate}}</span>
                <input matInput type="number" autocomplete="off" formControlName="LBP" class="input-field" placeholder="Enter LBP">
              </div>
              <div class="field-class">
                <span class="field-label">{{'width'|translate}}</span>
                <input matInput type="number" autocomplete="off" formControlName="width" class="input-field" placeholder="Enter Width">
              </div>
              <div class="field-class">
                <span class="field-label">{{'draft'|translate}}</span>
                <input matInput type="number" autocomplete="off" formControlName="draft" class="input-field" placeholder="Enter Draft">
              </div>
              <div class="field-class">
                <span class="field-label"> {{'mouldedDepth'|translate}}</span>
                <input matInput type="number" autocomplete="off" formControlName="mouldedDepth" class="input-field" placeholder="Enter Moulded Depth">
              </div>
            </div>
          </ng-template>
        </mat-expansion-panel>
        <hr class="styled-line">
        <mat-expansion-panel style="background-color: rgba(0,0,0,0)">
          <mat-expansion-panel-header [collapsedHeight]="'80px'" style="color: var(--text-color)">
            {{'tonnage' | translate}}
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <div class="content">
              <div class="field-class">
                <span class="field-label">{{'netTonnage'|translate}}</span>
                <input matInput type="number" autocomplete="off" formControlName="netTonnage" class="input-field" placeholder="Enter Net Tonnage">
              </div>
              <div class="field-class">
                <span class="field-label">{{'grossTonnage'|translate}}</span>
                <input matInput type="number" autocomplete="off" formControlName="grossTonnage" class="input-field" placeholder="Enter Gross Tonnage">
              </div>
              <div class="field-class">
                <span class="field-label">{{'deadWeightTonnage'|translate}}</span>
                <input matInput type="number" autocomplete="off" formControlName="deadweightTonnage" class="input-field" placeholder="Enter Dead Weight Tonnage">
              </div>
            </div>
          </ng-template>
        </mat-expansion-panel>
        <hr class="styled-line">
        <mat-expansion-panel style="background-color: rgba(0,0,0,0)">
          <mat-expansion-panel-header [collapsedHeight]="'80px'" style="color: var(--text-color)">
            {{'passengerCapacity'|translate}}
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <div class="content">
              <label class="infoes"></label>  
              <div class="field-class">
                <span class="field-label">{{'passengerCapacity'|translate}}</span>
                <input matInput type="number" autocomplete="off" formControlName="passengerCapacity" class="input-field" placeholder="Enter Passenger Capacity">
              </div>
            </div> 
          </ng-template>
        </mat-expansion-panel>
        <hr class="styled-line">
        <mat-expansion-panel style="background-color: rgba(0,0,0,0)">
          <mat-expansion-panel-header [collapsedHeight]="'80px'" style="color: var(--text-color)">
            {{'ccapacity' | translate}}
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <div class="content">
              <div class="field-class-two-column">
                <div class="field-class-sub-left">
                  <span class="field-label">{{'bale'|translate}}</span>
                  <input matInput type="number" autocomplete="off" formControlName="cargoBaleM3" class="input-field" placeholder="Enter Bale">
                </div>
                <div class="field-class-sub-right">
                  <span class="field-label">{{'grain'|translate}}</span>
                  <input matInput type="number" autocomplete="off" formControlName="cargoGrainM3" class="input-field" placeholder="Enter Grain">
                </div>
              </div>
              <div class="field-class-two-column">
                <div class="field-class-sub-left">
                  <span class="field-label">{{'liquid'|translate}}</span>
                  <input matInput type="number" autocomplete="off" formControlName="cargoLiquidM3" class="input-field" placeholder="Enter Liquid">
                </div>
                <div class="field-class-sub-right">
                  <span class="field-label">{{'refrigerated'|translate}}</span>
                  <input matInput type="number" autocomplete="off" formControlName="cargoRefM3" class="input-field" placeholder="Enter Refrigerated">
                </div>
              </div>
              <div class="field-class-two-column">
                <div class="field-class-sub-left">
                  <span class="field-label">{{'numOf20FeetContainer'|translate}}</span>
                  <input matInput type="number" autocomplete="off" formControlName="cargoNoTeu" class="input-field" placeholder="Enter No. of 20 Feet Container">
                </div>
                <div class="field-class-sub-right">
                  <span class="field-label">{{'numOf40FeetContainer'|translate}}</span>
                  <input matInput type="number" autocomplete="off" formControlName="cargoNoFeu" class="input-field" placeholder="Enter No. of 40 Feet Container">
                </div>
              </div>
              <div class="field-class-two-column">
                <div class="field-class-sub-left">
                  <span class="field-label">{{'numOfCars'|translate}}</span>
                  <input matInput type="number" autocomplete="off" formControlName="cargoNoCar" class="input-field" placeholder="Enter No.of Cars">
                </div>
                <div class="field-class-sub-right">
                  <span class="field-label">{{'numOfTrucks'|translate}}</span>
                  <input matInput id="waterlineLength" type="number" autocomplete="off" formControlName="cargoNoTruck" class="input-field" placeholder="Enter No.of Trucks">
                </div>
              </div>
              <div class="field-class-two-column">
                <div class="field-class-sub-left">
                  <span class="field-label">{{'numOfTrailers'|translate}}</span>
                  <input matInput type="number" autocomplete="off" formControlName="cargoNoTrailer" class="input-field" placeholder="Enter No.of Trailers">
                </div>
                <div class="field-class-sub-right">
                  <span class="field-label">{{'numOfForklifts'|translate}}</span>
                  <input matInput id="waterlineLength" type="number" autocomplete="off" formControlName="cargoNoForklift" class="input-field" placeholder="Enter No.of Forklifts">
                </div>
              </div>
              <div class="field-class-two-column">
                <div class="field-class-sub-left">
                  <span class="field-label">{{'numOfBulldozers'|translate}}</span>
                  <input matInput type="number" autocomplete="off" formControlName="cargoNoBulldozer" class="input-field" placeholder="Enter No.of Bulldozers">
                </div>
                <div class="field-class-sub-right">
                  <span class="field-label">{{'numOfChassis'|translate}}</span>
                  <input matInput id="waterlineLength" type="number" autocomplete="off" formControlName="cargoNoChassis" class="input-field" placeholder="Enter No.of Chassis">
                </div>
              </div>
              <div class="field-class">
                <span class="field-label">{{'numOfTrainCars'|translate}}</span>
                <input matInput type="number" autocomplete="off" formControlName="cargoNoTrainCar" class="input-field" placeholder="Enter No.of Train Cars">
              </div>
              <div class="field-class-two-column">
                <div class="field-class-sub-left">
                  <span class="field-label">{{'customizedUnitForCargo'|translate}}</span>
                  <input matInput type="text" autocomplete="off" formControlName="cargoOtherUnit" class="input-field" placeholder="Enter Customized Unit">
                </div>
                <div class="field-class-sub-right">
                  <span class="field-label">{{'capacity'|translate}}</span>
                  <input matInput id="waterlineLength" type="number" autocomplete="off" formControlName="cargoOtherCapacity" class="input-field" placeholder="Enter Cargo Capacity">
                </div>
              </div>
            </div>
          </ng-template>
        </mat-expansion-panel>
        <hr class="styled-line">
        <mat-expansion-panel style="background-color: rgba(0,0,0,0)">
          <mat-expansion-panel-header [collapsedHeight]="'80px'" style="color: var(--text-color)">
            {{'tcapactiy' | translate}}
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <div class="content">
              <div class="field-class">
                <span class="field-label">{{'fuelOilDieselOil'|translate}}</span>
                <input matInput type="text" autocomplete="off" formControlName="tankCapacityFoM3" class="input-field" placeholder="Enter Fuel Oil & Diesel Oil">
              </div>
              
              <div class="field-class">
                <span class="field-label">{{'freshWater'|translate}}</span>
                <input matInput type="text" autocomplete="off" formControlName="tankCapacityFwM3" class="input-field" placeholder="Enter Fresh Water">
              </div>
              
              <div class="field-class">
                <span class="field-label">{{'waterBallast'|translate}}</span>
                <input matInput type="text" autocomplete="off" formControlName="tankCapacityWbM3" class="input-field" placeholder="Enter Water Ballast">
              </div>
            </div>
          </ng-template>
        </mat-expansion-panel>
        <hr class="styled-line">
        <mat-expansion-panel style="background-color: rgba(0,0,0,0)">
          <mat-expansion-panel-header [collapsedHeight]="'80px'" style="color: var(--text-color)">
            {{'additionalDetails' | translate}}
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <div class="content">
              <div class="field-class">
                <span class="field-label">{{'bulbousBow'|translate}}</span>
                <input matInput type="number" autocomplete="off" formControlName="bulbousBow" class="input-field" placeholder="Enter Bulbous Bow">
              </div>
              
              <div class="field-class">
                <span class="field-label">{{'meBuiltYear'|translate}}</span>
                <input matInput type="number" autocomplete="off" formControlName="meBuiltYear" class="input-field" placeholder="Enter ME BuiltYear">
              </div>
              
              <div class="field-class">
                <span class="field-label">{{'lw'|translate}}</span>
                <input matInput type="number" autocomplete="off" formControlName="L_w" class="input-field" placeholder="Enter L_w">
              </div>
              
              <div class="field-class">
                <span class="field-label">{{'cb'|translate}}</span>
                <input matInput type="number" autocomplete="off" formControlName="C_B" class="input-field" placeholder="Enter C_B">
              </div>
              
              <div class="field-class">
                <span class="field-label">{{'etaD'|translate}}</span>
                <input matInput type="number" autocomplete="off" formControlName="eta_D" class="input-field" placeholder="Enter ETA_D">
              </div>
              
              <div class="field-class">
                <span class="field-label">{{'maxSpeedKn'|translate}}</span>
                <input matInput type="number" autocomplete="off" formControlName="maxSpeedKn" class="input-field" placeholder="Enter Max Speed Kn">
              </div> 
            </div>
            </ng-template>
        </mat-expansion-panel>
        <hr class="styled-line">
        <mat-expansion-panel style="background-color: rgba(0,0,0,0)">
          <mat-expansion-panel-header [collapsedHeight]="'80px'" style="color: var(--text-color)">
            {{'sInfo' | translate}}
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <div class="content">
              <div class="field-class">
                <span class="field-label">{{'salesRepName'|translate}}</span>
                <input matInput type="text" autocomplete="off" formControlName="salesRepresentativeName" class="input-field" placeholder="Enter Sales Representative Name">
              </div>            
              <div class="field-class">
                <span class="field-label">{{'salesAddress'|translate}}</span>
                <input matInput type="text" autocomplete="off" formControlName="salesAddress" class="input-field" placeholder="Enter Sales Address">
              </div>       
              <div class="field-class">
                <span class="field-label">{{'salesEmail'|translate}}</span>
                <input matInput type="text" autocomplete="off" formControlName="salesEmail" class="input-field" placeholder="Enter Sales Email">
              </div>         
              <div class="field-class">
                <span class="field-label">{{'salesTelephone'|translate}}</span>
                <input matInput type="tele" autocomplete="off" formControlName="salesTelephone" class="input-field" placeholder="Enter Sales Telephone">
              </div>
            </div>
        </ng-template>
        </mat-expansion-panel>
        <hr class="styled-line">
        <mat-expansion-panel style="background-color: rgba(0,0,0,0)">
          <mat-expansion-panel-header [collapsedHeight]="'80px'" style="color: var(--text-color)">
            {{'sainfo' | translate}}
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <div class="content">
                <div class="field-class">
                  <span class="field-label">{{'salesAgentName'|translate}}</span>
                  <input matInput type="text" autocomplete="off" formControlName="salesAgentName" class="input-field" placeholder="Enter Sales Agent Name">
                </div>            
                <div class="field-class">
                  <span class="field-label">{{'salesAgentAddress'|translate}}</span>
                  <input matInput type="text" autocomplete="off" formControlName="salesAgentAddress" class="input-field" placeholder="Enter Sales Agent Address">
                </div>       
                <div class="field-class">
                  <span class="field-label">{{'salesAgentEmail'|translate}}</span>
                  <input matInput type="text" autocomplete="off" formControlName="salesAgentEmail" class="input-field" placeholder="Enter Sales Agent Email">
                </div>         
                <div class="field-class">
                  <span class="field-label">{{'salesAgentTelephone'|translate}}</span>
                  <input matInput type="text" autocomplete="off" formControlName="salesAgentTelephone" class="input-field" placeholder="Enter Sales Agent Telephone">
                </div>
            </div>
          </ng-template>
        </mat-expansion-panel>
        <hr class="styled-line">        
        <br>
        <div style="margin-left: 10%; width: 80%">
          <div class="label-class">{{'subscription'|translate}}</div>
            <div>
              <div style="width: 100px">
                <p-treeSelect
                  [options]="primeNgDataset"
                  [(ngModel)]="selectedNodes"
                  [ngModelOptions]="{standalone: true}"
                  class="md:w-20px w-full"
                  containerStyleClass="w-full"
                  [panelStyle]="{ 'width': '50pxs' }"
                  placeholder="Select the item"
                  selectionMode="checkbox"
                  [metaKeySelection]="false"
                  [propagateSelectionUp]="false" 
                  [propagateSelectionDown]="false"
                  [showClear]="false"
                  [filter]="false"
                  (onNodeSelect)="onNodeSelect($event)"
                  (onNodeUnselect)="onNodeUnselect($event)"  
                  display="chip"> 
                  <ng-template pTemplate="header" let-node>
                  </ng-template> 
                </p-treeSelect>
            </div>
            <br>
            <div style="width: 26.25rem">
              <p-tree class="w-full md:w-26.25rem" 
              [value]="primeNgTreeDataSet" 
              [metaKeySelection]="false"
              [propagateSelectionUp]="false" 
              [propagateSelectionDown]="false"
              expanded = "true"
              ></p-tree></div>
              
            </div>
          </div>
          <br><br><br>
          
          <div class="content" style="margin-left: 10%; width: 80%" *ngIf="editMode">
            <div class="label-class">{{'vessselMappingFileUpload'|translate}}</div>
            <div>
                <input type="file" class="file-inputs" (change)="onChange($event)"/>
               
                  <div *ngIf="file">
                    <section class="file-info">
                      File details:
                      <ul>
                        <li>Name: {{file.name}}</li>
                        <li>Type: {{file.type}}</li>
                        <li>Size: {{file.size}} bytes</li>
                      </ul>
                    </section>
                  
                    <button (click)="onUpload()" type="button">Upload the file</button>
                  
                   <section [ngSwitch]="status">
                      <p *ngSwitchCase="'uploading'">Uploading...</p>
                      <p *ngSwitchCase="'success'">Done!</p>
                      <p *ngSwitchCase="'fail'">Error!</p>
                      <p *ngSwitchDefault>Waiting to upload...</p>
                    </section>
                  
                </div> 
            </div>
          </div>
        </div>
      </form>
  </div>
  
  