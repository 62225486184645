<div class="table-auto">
  <p-table 
  #dt1 
  [columns]="cols" 
  [value]="userList" 
  [tableStyle]="{ 'min-width': '50rem', 'width': '100rem', 'margin-left': '10px', 'margin-right': '10px'}" 
  styleClass="p-datatable-striped p-datatable-gridlines"
  dataKey="id"
  selectionMode="single"
  [(selection)]="selected"
  (onRowSelect)="onRowClick($event)"
  [paginator]="true"
  [rows]="10"
  responsiveLayout="stack" 
  [breakpoint]="'960px'"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"

  class="bg-white">
  <ng-template pTemplate="caption">
    <div class="relative mb-3 ml-10">
      <input pInputText type="text" (input)="dt1.filterGlobal($any($event.target).value, 'contains')" placeholder="search by name, info, ID.." class="pl-4 pr-10 py-2 rounded-full border border-gray-300 focus:border-blue-500 outline-none font-sans text-lg font-light">
      <i class="pi pi-search  object-right items-center -ml-6 text-gray-400"></i>
    <button class=" items-center justify-center ml-96 text-center bg-blue w-40 h-10 rounded-full text-white font-inter font-bold text-lg cursor-pointer" (click)="jumpCreateVessel()">
        {{"addUser" | translate}}</button> </div>
    <hr>
  </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
          <th pSortableColumn="name" style="display:flex;" class="bg-black text-white rounded-tl-lg 
          font-sans text-base font-bold">{{"userName" | translate}} <p-sortIcon field="name"></p-sortIcon></th>
            <th class="bg-black text-white font-sans text-base font-bold">{{"informationSummary" | translate}}</th>
            <th class="bg-black text-white font-sans text-base font-bold">{{"address" | translate}}</th>
            <th class="bg-black text-white font-sans text-base font-bold">{{"company" | translate}}</th>
            <th class="bg-black text-white font-sans text-base font-bold">{{"role" | translate}}</th>
            <th class="bg-black text-white font-sans text-base font-bold">{{"lastUpdated" | translate}}</th>
            <th class="bg-black rounded-tr-lg"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td class="truncate w-40 font-sans text-sm font-extralight">{{rowData.name}}</td>
        <td class="font-sans text-xs font-extralight flex flex-col">
          <div class="flex items-center">
            <img src="../assets/images/icons/icon-mail.png" class="font-sans text-tColor ml-1"/>
            <span class="ml-1">{{rowData.email}}</span>
          </div>
          <div class="flex items-center">
            <img src="../assets/images/icons/icon-telephone.png" class="font-sans text-tColor ml-1"/>
            <span class="ml-1">{{rowData.telephone}}</span>
          </div>
          <div class="flex items-center">
            <span class="font-bold font-sans">FAX</span>
            <span class="ml-1">{{rowData.fax}}</span>
          </div>
        </td>
        <td class="font-sans text-sm font-extralight">{{rowData.address}}</td>
        <td class="font-sans text-sm font-extralight">{{rowData.cmn_company.name}}</td>
        <td class="font-sans text-sm font-extralight">{{rowData.position}}</td>
        <td class="font-sans text-sm font-extralight">{{rowData.updatedAt | date}}</td>
        <td><i class="pi pi-ellipsis-v"></i></td>
      </tr>
  
    </ng-template>
  </p-table>
</div>