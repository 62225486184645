import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from 'src/app/_service/user.service';
import { UserData} from 'src/app/_model/user_data';
import * as routing from '../../_model/routing';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  @Output() dataChange = new EventEmitter();
  iszoom: boolean = false;
  loadingFlag = true;
  selectedTab: string = 'tab1';
  inkBarPosition: string = '0%';
  userCounter = 0;
  constructor(
    private userService: UserService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getUserList();
    this.getuserCount();
    if(this.userService.skipListFlag){
      this.loadingFlag = false;
      this.userService.skipListFlag = false;
    }else{
      this.loadingFlag = false;
      this.router.navigate([routing.UserList]);
      }
  }
  getuserCount() {
    this.userService.getUserCount()
    .subscribe({
      next: (value) => {this.userCounter = value.count;}
    })
  }
  getUserList() { 
    this.router.navigate([routing.UserList]);
    this.loadingFlag = true;
    this.userService.userList = [];
    this.userService.getUserList()
    .subscribe({
      next: (value) => {
        value.forEach((e) => {
          if(e.name=== '' || e.name=== undefined || e.name === null ){

          }else{
            this.userService.userList.push(e);
          }
        })
      },
      complete: () => {
        this.loadingFlag = false;
        this.dataChange.emit();
        // this.goUserList();
      }
    });
  }

  jumpCreateUser() {
    this.userService.skipListFlag = true;
    this.router.navigate([routing.UserRegister]);
  }
  jumpCreateVessel() {
  this.router.navigate([routing.UserRegistration]);
}
  goUserList() { 
    this.router.navigate([routing.UserList]);
  }
  selectTab(tab: string): void {
    this.selectedTab = tab;
    if (tab === 'tab1') {
      this.inkBarPosition = '0%';
      // this.router.navigate([routing.AllEntries]);
    } else{
      this.inkBarPosition = '100%';
    }
  }
  onActivate(elementRef: any) {
    elementRef.dataChange.subscribe((event:any) => {
      this.getUserList();
    })
  }

}
