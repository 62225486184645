import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IportalHomeComponent } from './iportal-home/iportal-home.component';

import { MsalGuard } from '@azure/msal-angular';
import { AdminGuard } from './_guard/admin.guard';

import { DashboardComponent } from './dashboard/dashboard.component';
import { NotRegisteredComponent } from './not-registered/not-registered.component';
import { CompaniesComponent } from './dashboard/companies/companies.component';
import { VesselsComponent } from './dashboard/vessels/vessels.component';
import { CompanyDetailComponent } from './dashboard/companies/company-detail/company-detail.component';
import { CompanyRegisterComponent } from './dashboard/companies/company-register/company-register.component';
import { CompanyListComponent } from './dashboard/companies/company-list/company-list.component';
import { UserComponent } from './dashboard/user/user.component';
import { UserListComponent } from './dashboard/user/user-list/user-list.component';
import { UserDetailComponent } from './dashboard/user/user-detail/user-detail.component';
import { UserRegisterComponent } from './dashboard/user/user-register/user-register.component';
import { UserEditComponent } from './dashboard/user/user-edit/user-edit.component';
import { VesselDetailComponent } from './dashboard/vessels/vessel-detail/vessel-detail.component';
import { VesselEditComponent } from './dashboard/vessels/vessel-edit/vessel-edit.component';
import { VesselRegisterComponent } from './dashboard/vessels/vessel-register/vessel-register.component';
import { VesselListComponent } from './dashboard/vessels/vessel-list/vessel-list.component';
import { VesselApplicationRegisterComponent } from './dashboard/vessels/vessel-application-register/vessel-application-register.component';

const routes: Routes = [
  {path: 'home', component: IportalHomeComponent},
  {path: 'dashboard', component: DashboardComponent, canActivate: [MsalGuard], canActivateChild: [AdminGuard], children: [
    {path: 'company', component: CompaniesComponent, canActivate: [MsalGuard], children: [
      {path: 'company-detail/:id', component: CompanyDetailComponent, canActivate: [MsalGuard]},
      {path: 'company-register', component: CompanyRegisterComponent, canActivate: [MsalGuard]},
      {path: 'company-list', component: CompanyListComponent, canActivate: [MsalGuard]}
    ]},
    {path: 'vessel', component: VesselsComponent, canActivate: [MsalGuard], children: [
      {path: 'vessel-detail/:id', component: VesselDetailComponent, canActivate: [MsalGuard]},
      {path: 'vessel-register', component: VesselRegisterComponent, canActivate: [MsalGuard]},
      {path: 'vessel-list', component: VesselListComponent, canActivate: [MsalGuard]},
      {path: 'vessel-edit', component: VesselEditComponent, canActivate: [MsalGuard]},
      {path: 'vessel-application-register', component: VesselApplicationRegisterComponent, canActivate: [MsalGuard]},
    ]},
    {path: 'user', component: UserComponent, canActivate: [MsalGuard], children: [
      {path: 'user-list', component: UserListComponent, canActivate: [MsalGuard]},
      {path: 'user-edit', component: UserEditComponent, canActivate: [MsalGuard]},
    ]}
  ]},
  {path: 'dashboard/company/company-registration', component: CompanyRegisterComponent, canActivate: [MsalGuard]},
  {path: 'dashboard/user/user-registration', component: UserRegisterComponent, canActivate: [MsalGuard]},
  {path: 'dashboard/vessel/vessel-registration', component: VesselRegisterComponent, canActivate: [MsalGuard]},
  {path: 'not-registered', component: NotRegisteredComponent}
];
Option
const isIframe = window != window.parent && !window.opener;
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled', // Don't perform initial navigation in iframes
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
