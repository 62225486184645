import { FormControl, FormGroup, Validators } from "@angular/forms"
export interface companyType {
  value: string,
  display: string,
}
export interface getCompanyData {
  id: string,
  name: string,
  otherName: string,
  ceo?: string,
  address?: string,
  addressLineTwo?: string,
  city?: string,
  zipCode?: string,
  telephone?: string,
  fax?: string,
  email?: string,
  thumbnailExtension?: string,
  businessContents?: string,
  rank?: string,
  country?: string
  typeOwner: number,
  typeManagement: number,
  typeBuilder: number,
  typeManufacturer: number,
  typeMaintainer: number,
  typeExOwner:number,
  typeExManagement:number
}

export interface postCompanyData {
    name: string,
    otherName: string,
    ceo: string | undefined,
    address: string | undefined,
    addressLineTwo: string | undefined,
    city: string | undefined,
    zipCode: string | undefined,
    telephone: string | undefined,
    fax: string | undefined,
    email: string | undefined,
    thumbnailExtension: string | undefined,
    businessContents: string | undefined,
    rank: string | undefined,
    country: string | undefined,
    types: string[],
    typeOwner: number,
    typeManagement: number,
    typeBuilder: number,
    typeManufacturer: number,
    typeMaintainer: number,
    typeExOwner:number,
    typeExManagement:number
}

export class companyForm {
  defaultForm = new FormGroup({
  name: new FormControl('', [Validators.required]),
  otherName: new FormControl(''),
  ceo: new FormControl(''),
  address: new FormControl(''),
  addressLineTwo: new FormControl(''),
  city: new FormControl(''),
  zipCode: new FormControl(''),
  telephone: new FormControl(''),
  fax: new FormControl(''),
  email: new FormControl(''),
  thumbnailExtension: new FormControl(''),
  businessContents: new FormControl(''),
  types: new FormControl(['']),
  country:new FormControl(''),
  typeOwner: new FormControl(0),
  typeManagement: new FormControl(0),
  typeBuilder: new FormControl(0),
  typeManufacturer: new FormControl(0),
  typeMaintainer: new FormControl(0),
  typeExOwner: new FormControl(0),
  typeExManagement: new FormControl(0),
  })
}

