import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as constants from '../_model/string_literal';
import { getCompanyData, postCompanyData } from '../_model/company';
import { BehaviorSubject } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';

@Injectable({
  providedIn: 'root'
})

export class CompanyService {
  companyList:getCompanyData[] = []
  _companyDataChanged = new BehaviorSubject<boolean>(false);
  companyDataChanged: boolean = false
  selectedCompanyID = '';
  editCompanyData!:postCompanyData;
  editCompanyFlag = false;
  skipListFlag = false;
  isCompanyPage = new BehaviorSubject<boolean>(false);

  token = localStorage.getItem('token');

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  httpOptionsAuth = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token ? `Bearer ${JSON.parse(this.token)["token"]}` : ''
    })
  };

  constructor(
    private http: HttpClient,
  ) { }

  getStream = async(response: any) => {
    let stringBuffer:string = '';
    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf8');
    let result = await reader.read();
    while (!result.done) {
      const textValue = decoder.decode(result.value);
      stringBuffer += textValue
      .replace('[', '')
      .replace(']', ',');
      result = await reader.read()
    }
    stringBuffer = '[' + stringBuffer.slice(0, stringBuffer.length - 1) + ']';
    // console.log(stringBuffer);
    const companies:getCompanyData[] = JSON.parse(stringBuffer);
    return companies;
  }

  getCompanyList() {
    const $data = fromFetch(constants.IAPI_ENDPOINT + '/company', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.token ? `Bearer ${JSON.parse(this.token)["token"]}` : ''
      },
    });
    return $data;
    // return this.http.get(constants.IAPI_ENDPOINT + '/company', this.httpOptionsAuth);
  }
  getCompanyChanged() {
    return this._companyDataChanged.asObservable();
  }
  companyListUpdate() {
    this.companyDataChanged = !this.companyDataChanged;
    this._companyDataChanged.next(this.companyDataChanged);
  }
  //with offset and limit
  getCompanyWithLimitAndOffset(offset: any, limit:any){
    return this.http.get(constants.IAPI_ENDPOINT + `/company?offset=${offset}&limit=${limit}&order=name%3Aasc`, this.httpOptionsAuth);
  }
  getCompany(){
    return this.http.get(constants.IAPI_ENDPOINT + '/company', this.httpOptionsAuth);
  }
  getCompanyByName(name: string) {
    return this.http.get(constants.IAPI_ENDPOINT + `/company?name=${name}`, this.httpOptionsAuth);
  }
  getCompanyCount() {
    return this.http.get<{count: number}>(constants.IAPI_ENDPOINT + '/company/count', this.httpOptionsAuth);
  }

  submitNewCompany(companyData: any) {
    return this.http.post(constants.IAPI_ENDPOINT + '/company', JSON.stringify(companyData), this.httpOptionsAuth);
  }

  submitExistingCompany(companyData: any){
    return this.http.put(constants.IAPI_ENDPOINT + '/company/' + `${this.selectedCompanyID}`, JSON.stringify(companyData), this.httpOptionsAuth);
  }

  deleteCompanyData() {
    return this.http.delete(constants.IAPI_ENDPOINT + '/company/' + `${this.selectedCompanyID}`, this.httpOptionsAuth);
  }

  get isCompanyPageChanges() {
    return this.isCompanyPage.asObservable();
  }

  checkVisitCompanyPage(value: boolean) {
    this.isCompanyPage.next(value);
  }
  //maybe this is no need
  resetEditCompany(){
    this.editCompanyData = {
      name: '',
      otherName: '',
      ceo: '',
      address: '',
      addressLineTwo: '',
      city: '',
      zipCode: '',
      telephone: '',
      fax: '',
      email: '',
      thumbnailExtension: '',
      businessContents: '',
      rank: '',
      country: '',
      types: [''],
      typeOwner: 0,
      typeManagement: 0,
      typeBuilder: 0,
      typeManufacturer: 0,
      typeMaintainer: 0,
      typeExOwner: 0,
      typeExManagement: 0,
    };
    this.editCompanyFlag = false;
  }

  resetSelectedCompanyID() {
    this.selectedCompanyID = '';
  }

  getCompanyType(companyData: getCompanyData) {
    const types: string[] = [];
    if(companyData.typeOwner===1){
      types.push('owner');
    }
    if(companyData.typeManagement===1){
      types.push('manager');
    }
    if(companyData.typeBuilder===1){
      types.push('builder');
    }
    if(companyData.typeManufacturer===1){
      types.push('manufacturer');
    }
    if(companyData.typeMaintainer===1){
      types.push('maintainer');
    }
    if(companyData.typeExOwner===1){
      
      types.push('exowner');
    }
    if(companyData.typeExManagement===1){
      
      types.push('exmanager');
    }
    return types;

  }
}
