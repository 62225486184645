import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { CompanyService } from 'src/app/_service/company.service';
import * as routing from '../../_model/routing';
import { getCompanyData } from 'src/app/_model/company';
import { StreamingHttpUtil } from 'src/app/_common/common.streaminghttp';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit, OnDestroy {
  @Output() dataChange = new EventEmitter();

  loadingFlag = true;
  selectedTab: string = 'tab1';
  inkBarPosition: string = '0%';
  companyCounter = 0;
  constructor(
    private companyService: CompanyService,
    private router: Router,
  ) {  }

  ngOnInit(): void {
    this.getCompanyCount();
    this.companyService.checkVisitCompanyPage(true);
    if(this.companyService.skipListFlag){
      this.loadingFlag = false;
      this.companyService.skipListFlag = false;
    }else{
      this.loadingFlag = false;
      this.router.navigate([routing.CompanyList]);
    }
  }

  ngOnDestroy(): void {
    this.companyService.checkVisitCompanyPage(false);
  }

  getCompanyCount() {
    this.companyService.getCompanyCount()
    .subscribe({
      next: (value) => {this.companyCounter = value.count;}
    })
  }
  getCompanyList() {
    this.router.navigate([routing.CompanyList]);
    this.loadingFlag = true;
    this.companyService.getCompanyList()
    .subscribe({
      next: async(value:any) => {
        this.companyService.companyList = [];
        const streamingJson = await StreamingHttpUtil.getStream(value);
        const companies:getCompanyData[] = JSON.parse(streamingJson);
        this.companyService.companyList.push(...companies.filter((company) => company.name));      
      },
      complete: () => {
        this.loadingFlag = false;
        this.dataChange.emit();
      }
    });
    this.goCompanyList();
  }

  goCompanyList() {
    this.router.navigate([routing.CompanyList]);
  }
  jumpCreateVessel() {
  this.router.navigate([routing.CompanyRegistration]);
}
  goCompanyRegister() {
    this.router.navigate([routing.CompanyRegister]);
  }
  selectTab(tab: string): void {
    this.selectedTab = tab;
    if (tab === 'tab1') {
      this.inkBarPosition = '0%';
      // this.router.navigate([routing.AllEntries]);
    } else{
      this.inkBarPosition = '100%';
    }
  }
  onActivate(elementRef: any) {
    elementRef.dataChange.subscribe((event:any) => {
      this.getCompanyList();
    });
  }
}
