import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/_service/user.service';
import { VesselService } from 'src/app/_service/vessel.service';

import * as routing from '../../../_model/routing';
import { UserData, postUserData } from 'src/app/_model/user_data';
import { getVesselData } from 'src/app/_model/vessel';
import { StreamingHttpUtil } from 'src/app/_common/common.streaminghttp';
import { MatDialog } from '@angular/material/dialog';
import { UserRegisterComponent } from '../user-register/user-register.component';
import { UserEditComponent } from '../user-edit/user-edit.component';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

interface Column {
  field: string;
  header: string;
}
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  @Output() dataChange = new EventEmitter();
  @ViewChild('table', {read: ElementRef}) table!: ElementRef;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource!: MatTableDataSource<UserData>;
  userList!: UserData[];
  columnName: string[];
  selectedRowIndex: number = -1;
  dataload: boolean = true;
  cols!: Column[];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private vesselService: VesselService,
    public dialog: MatDialog,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    this.iconRegistry.addSvgIcon(
      'mail',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/mail.svg')
    );
    this.iconRegistry.addSvgIcon(
      'call',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/call.svg')
    );
    // this.userList = this.userService.userList;
    this.dataSource = new MatTableDataSource();
    this.columnName = ['name','tele','address','company','position', 'lastupdated', '*'];
  }
  

  userData!: postUserData;
  userId='';
  selected: any;
  loading: boolean = false;
  ngOnInit(): void {
    this.userService.getUserChanged().subscribe(userChanged => {
      if (userChanged) {
        this.initUserList();
        this.userService.userListUpdate();
      }
    });
    this.initUserList();
    // this.initVesselList();
    this.userService.resetSelectedUserID();
    this.cols = [
      { field: 'name', header: 'User Name' },
      { field: 'tele', header: 'Information Summary' },
      { field: 'owner_id', header: 'Address' },
      { field: 'manager_id', header: 'Company' },
      { field: 'vesselNumber', header: 'Role' },
      { field: 'updatedAt', header: 'Last Updated' }
  ];
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  initUserList() {
    this.loading = true;

    this.userService.getUserList()
    .subscribe({
      next: (value) => {
        this.userList = value;
        this.loading = false;
      },
      complete: () => {}
    })
  }

  initVesselList() {
    this.vesselService.vesselList = [];
    this.vesselService.getVesselList()
    .subscribe({
      next: async(value) => {
        const streamingJson = await StreamingHttpUtil.getStream(value);
        const vessels:getVesselData[] = JSON.parse(streamingJson);
        this.vesselService.vesselList.push(...vessels.filter((vessel) => vessel.name));
      },
      complete: () => {
        this.dataChange.emit();
      }
    })
  }

  findByID() {
  }

  onRowClick(event: any) {
    this.selectedRowIndex = event.data.id;
    this.userId=event.data.id;
    this.userService.selectedUserID=this.userId;
    const data =this.userService.userList.find(e=>e.id === this.userId);
    if(data != undefined){
      this.addToUserData(data);
    }
  }
  jumpCreateVessel() {
  this.router.navigate([routing.UserRegistration]);
}
  addToUserData(data:UserData){
    this.userData = {
      name: data!.name,
      first_name: data!.first_name,
      last_name: data!.last_name,
      pincode: data!.pincode,
      position: data!.position,
      address: data!.address,
      telephone: data!.telephone,
      company_id: data!.company_id,
      role_id: data!.role_id,
      fax: data!.fax,
      email: data!.email,
      thumbnailExtension: data!.thumbnailExtension,
      oid: data!.oid,
      // company
    };
    this.userService.editUserFlag=true;
    this.userService.editUserData =this.userData;
    this.editUserData();
  }
  editUserData(){
  this.dialog.open(UserEditComponent,{
      width:'744px',
      height: '85vh',
      // panelClass: 'fullscreen-dialog'
    })

  }
}
