<div>
  <p-table 
  #dt1 
  [columns]="cols" 
  [value]="companyList" 
  [tableStyle]="{ 'min-width': '50rem', 'width': '100rem', 'margin-left': '10px', 'margin-right': '10px'}" 
  styleClass="p-datatable-striped p-datatable-gridlines"
  dataKey="id"
  selectionMode="single"
  [(selection)]="selected"
  (onRowSelect)="onRowClick($event)"
  responsiveLayout="stack" 
  [breakpoint]="'960px'"
  [loading]="loading"

  class="bg-white">
  <ng-template pTemplate="caption">
    <div class="relative mb-3 ml-10">
      <input
        #companyName
        pInputText
        style="float: left; width: 25%; background-color: var(--list-bg-color); color: var(--text-color);"
        type="text"
        placeholder="Name"
        class="pl-3 pr-3 border border-gray-300 focus:border-blue-500 h-10 outline-none font-sans text-lg font-light"
      >
      <button
        style="float: left; margin-left: 1%; width: 100px"
        class="items-center justify-center text-center bg-blue h-10 text-white font-inter font-bold text-lg cursor-pointer rounded" 
        (click)="searchVessel(companyName)"
      >
        {{"search" | translate}}
      </button>
      <button
        style="margin-top: 8px; margin-left: 5px; color: var(--text-color);"
        (click)="clearSearch(companyName)"
      >
        {{"clear" | translate}}
      </button>
    <button class=" items-center justify-center ml-96 text-center bg-blue w-40 h-10 rounded-full text-white font-inter font-bold text-lg cursor-pointer" (click)="jumpCreateVessel()">
        {{"addCompany" | translate}}</button> </div>
    <hr>
  </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th pSortableColumn="name" class="bg-black text-white rounded-tl-lg font-sans text-base font-bold w-11">
              <div class="flex items-center">
                <span>{{"companyName" | translate}}</span>
                <p-sortIcon field="name"></p-sortIcon>
              </div>
            </th>
            <th class="bg-black text-white font-sans text-base font-bold">{{"companyNameJP" | translate}}</th>
            <th class="bg-black text-white font-sans text-base font-bold">{{"informationSummary" | translate}}</th>
            <th class="bg-black text-white font-sans text-base font-bold">{{"address" | translate}}</th>
            <th class="bg-black text-white font-sans text-base font-bold">{{"businessArea" | translate}}</th>
            <th class="bg-black text-white font-sans text-base font-bold">{{"companyType" | translate}}</th>
            <th class="bg-black text-white font-sans text-base font-bold">{{"lastUpdated" | translate}}</th>

            <th class="bg-black rounded-tr-lg"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td class="font-sans text-xs font-extralight ">
          <p class="truncate">{{rowData.name}}</p> 
        </td>
        <td class="font-sans text-xs font-extralight ">
          <p class="truncate" style="font-weight: 400">{{rowData.otherName}}</p> 
        </td>
        <td class="font-sans text-xs font-extralight flex flex-col">
          <div class="flex items-center">
            <img src="../assets/images/icons/icon-mail.png" class="font-sans text-tColor ml-1"/>
            <span class="ml-1">{{rowData.email}}</span>
          </div>
          <div class="flex items-center">
            <img src="../assets/images/icons/icon-telephone.png" class="font-sans text-tColor ml-1"/>
            <span class="ml-1">{{rowData.telephone}}</span>
          </div>
          <div class="flex items-center">
            <span class="font-bold font-sans">FAX</span>
            <span class="ml-1">{{rowData.fax}}</span>
          </div>
        </td>
        <td class="font-sans text-sm font-extralight">{{rowData.address}}</td>
        <td class="font-sans text-sm font-extralight">{{rowData.businessContents}}</td>
        <td class="font-sans text-sm font-extralight">{{rowData.typeOwner === 1 ? 'Owner' : 'Other' }}</td>
        <td class="font-sans text-sm font-extralight">{{rowData.updatedAt | date}}</td>
        <td><i class="pi pi-ellipsis-v"></i></td>
      </tr>
  
    </ng-template>
  </p-table>
  <div *ngIf="isSearched && !isSearching && companyList.length==0" class="noCompanyMsg">{{"noCompanies" | translate}}</div>
  <br />
  <mat-paginator
    *ngIf="!isSearched && companyList.length != 0"
    [length]="length"
    [pageIndex]="pageIndex"
    [showFirstLastButtons]=true
    [pageSize]=50
    (page)="pageEvent = onPageChange($event)"
    [style]="{'font-size': '15px', 'margin-right': '50px'}"
    hidePageSize="true"
  >
  </mat-paginator>
</div>