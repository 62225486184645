export class StreamingHttpUtil {

  static async getStream(response: any): Promise<string> {
    let stringBuffer:string = '';
    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf8');
    let result = await reader.read();
    while (!result.done) {
      const textValue = decoder.decode(result.value);
      stringBuffer += textValue.replace('[','').replace(']', ',');
      result = await reader.read()
    }
    stringBuffer = '[' + stringBuffer.slice(0, stringBuffer.length - 1) + ']';
    // console.log(stringBuffer);
    return stringBuffer;
  }
}
