import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { UserService } from 'src/app/_service/user.service';

import { UserData, postUserData } from 'src/app/_model/user_data';
import * as routing from 'src/app/_model/routing';

import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {
  @Output() dataChange = new EventEmitter();
  
  userID = '';
  userData!: postUserData;
  columnName = ['key', 'value'];

  tableData: Array<object> = [];
  dataSource:any;
  finishCounter = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private userService: UserService,
  ) {  }

  ngOnInit(): void {
    this.userService.skipListFlag = false;
    this.getUserID();
    this.getUserData(this.userID);
  }

  getUserID() {
    let id = this.route.snapshot.paramMap.get('id');
    if(id===null){
      this.location.back();
    }else{
      this.userID = id;
      this.userService.selectedUserID = this.userID;
    }
  }

  getUserData(id:string){
    const data = this.userService.userList.find(e => e.id === id);
    if(data===undefined){
      this.location.back();
    }else{
      this.changeGetToPostUserData(data);
    }
  }

 
  editUserData() {
    this.userService.editUserFlag = true;
    this.userService.editUserData = this.userData;
  
    this.router.navigate([routing.UserEdit]);
    //this.router.navigate([routing.UserRegister]);
  }

  deleteUserData() {
      if(this.checkSubmit()){
        this.userService.deleteUserData(this.userID)
        .subscribe({
          error: (err) => {window.alert('error occurred');},
          complete: () => {
            this.dataChange.emit();
          }
        })
    }
  }

  changeGetToPostUserData(data: UserData) {
    // this.userData = {
    //     // name: data.name,
    //     // first_name: data.first_name,
    //     // last_name: data.last_name,
    //     // pincode: data.pincode,
    //     // position: data.position,
    //     // address: data.address,
    //     // telephone: data.telephone,
    //     // fax: data.fax,
    //     // email: data.email,
    //     // thumbnailExtension: data.thumbnailExtension,
    //     // oid: data.oid,
    //     // companyName: data.cmn_company,
    // };
    this.setTableData();
  }

  setTableData() {
    const keyArray = Object.keys(this.userData);
    const valueArray =  Object.values(this.userData);
    for(let i=0; i<keyArray.length;i++){
      this.tableData.push({'key': keyArray[i], 'value': valueArray[i]});
    }
    this.dataSource = new MatTableDataSource(this.tableData);
  }

  goBackList() {
    this.location.back();
  }

  checkSubmit() {
    if(window.confirm('Can I delete this User Data?')){
      return true;
    }else{
      return false;
    }
  }

}
