export const Dashboard = 'dashboard';

export const Company = '/dashboard/company'
export const CompanyList = '/dashboard/company/company-list';
export const CompanyDetail = '/dashboard/company/company-detail';
export const CompanyRegister = '/dashboard/company/company-register';
export const CompanyRegistration = '/dashboard/company/company-registration';

export const Vessel = '/dashboard/vessel';
export const VesselList = '/dashboard/vessel/vessel-list';
export const VesselDetail = '/dashboard/vessel/vessel-detail';
export const VesselRegister = '/dashboard/vessel/vessel-register';
export const VesselEdit = '/dashboard/vessel/vessel-edit';
export const VesselApplicationRegister = '/dashboard/vessel/vessel-application-register'
export const VesselRegistration = '/dashboard/vessel/vessel-registration';

export const User = '/dashboard/user';
export const UserList = '/dashboard/user/user-list';
export const UserDetail = '/dashboard/user/user-detail';
export const UserRegister = '/dashboard/user/user-register';
export const UserEdit = '/dashboard/user/user-edit';
export const UserRegistration = '/dashboard/user/user-registration';