<div class="table-auto" style="margin-top: 125px;">
  <p-table 
  #dt1 
  [columns]="cols" 
  [value]="vesselList" 
  [tableStyle]="{ 'width': '75%', 'margin-left': 'auto', 'margin-right': 'auto'}" 
  styleClass="p-datatable-gridlines"
  dataKey="id"
  [rows]="50"
  responsiveLayout="stack" 
  [breakpoint]="'500px'"
  [loading]="loading"
  
  class="bg-white">
    <ng-template pTemplate="caption">
      <div class="header-area">
        <input
          #vesselName
          pInputText
          style="float: left; margin-left: 11.8%; width: 12%; background-color: var(--list-bg-color); color: var(--text-color);"
          type="text"
          placeholder="Name"
          class="pl-3 pr-3 border border-gray-300 focus:border-blue-500 h-10 outline-none font-sans text-lg font-light"
        >
        <input 
          #vesselOwner
          pInputText
          style="float: left; margin-left: 1%; width: 12%; background-color: var(--list-bg-color); color: var(--text-color);"
          type="text" 
          placeholder="Owner"
          class="pl-3 pr-3 border border-gray-300 focus:border-blue-500 h-10 outline-none font-sans text-lg font-light"
        >
        <input
          #vesselManager
          pInputText
          style="float: left; margin-left: 1%; width: 12%; background-color: var(--list-bg-color); color: var(--text-color);"
          type="text" 
          placeholder="Manager"
          class="pl-3 pr-3 border border-gray-300 focus:border-blue-500 h-10 outline-none font-sans text-lg font-light"
        >
        <button
          style="float: left; margin-left: 1%; width: 100px"
          class="items-center justify-center text-center bg-blue h-10 text-white font-inter font-bold text-lg cursor-pointer rounded" 
          (click)="searchVessel(vesselName, vesselOwner, vesselManager)"
        >
          {{"search" | translate}}
        </button>
        <button
          style="margin-top: 8px; margin-left: 5px; color: var(--text-color);"
          (click)="clearSearch(vesselName, vesselOwner, vesselManager)"
        >
          {{"clear" | translate}}
        </button>
        <button
          style="float: right; margin-right: 11.8%; width: 145px"
          class="items-center justify-center text-center bg-blue h-10 text-white font-inter font-bold text-lg cursor-pointer rounded" 
          (click)="jumpCreateVessel()"
        >
          {{"addVessel" | translate}}
        </button>
      </div>
    </ng-template>
  
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData" style="border: 1px solid var(--list-border-color); background-color: var(--list-bg-color)">
        <td class="truncate font-sans text-sm">
          <div class="name-area">
            <div class="vessel-name" style="overflow: hidden; text-overflow: ellipsis; max-width: 800px;"> {{rowData.name}} </div>
            <div class="header" style="overflow: hidden; text-overflow: ellipsis; max-width: 800px"> {{rowData.otherName}} </div>
          </div>
          <div class="vessel-id-area"> 
            ID: {{rowData.id}} 
            <button
              style="width: 45px; display: inline-block; border: 1px solid; margin-left: 3px;"
              class="items-center justify-center text-center font-inter cursor-pointer rounded"
              (click)="copyVesselId(rowData.id)"
            >
              {{"copy" | translate}}
            </button>
          </div> 
          <hr style="width: 120%; margin-left: -30px; border-color: var(--list-border-color);">
          <div style="float: top;">
            <div class="id-area-narrow">
              <div class="id"> 
                <div class="tooltiptext" *ngIf="rowData.imo"> {{rowData.imo}} </div>
                {{rowData.imo}} 
              </div> 
              <div class="header"> IMO No. </div>
            </div>
            <div class="id-area-wide">
              <div class="id">
                <div class="tooltiptext" *ngIf="rowData.owner_id"> {{getCompanyNameById(rowData.owner_id)}} </div>
                {{getCompanyNameById(rowData.owner_id)}}
              </div>
              <div class="header"> Owner </div> 
            </div>
            <div class="id-area-wide">
              <div class="id">
                <div class="tooltiptext" *ngIf="rowData.manager_id"> {{getCompanyNameById(rowData.manager_id)}} </div>
                {{getCompanyNameById(rowData.manager_id)}}
              </div>
              <div class="header"> Manager </div> 
            </div>
            <div class="id-area-wide">
              <div class="id">
                <div class="tooltiptext" *ngIf="rowData.builder_id"> {{getCompanyNameById(rowData.builder_id)}} </div>
                {{getCompanyNameById(rowData.builder_id)}}
              </div>
              <div class="header"> Shipyard </div>
            </div>
          </div>
          <div>
            <div class="btn">
              <button mat-raised-button style="background-color: #00a0e9; width: 105px" (click)="onButtonClick(rowData)">
                {{"viewEdit" | translate}}
              </button>
            </div>
            <div class="update-time">Updated {{rowData.updatedAt.replace('T', ' ').replace('Z', '')}} UTC</div>
          </div>
        </td>
      </tr>
      <br>
  
    </ng-template>
  </p-table>
  <div *ngIf="isSearched && !isSearching && vesselList.length==0" class="noVesselMsg">{{"noVessels" | translate}}</div>
  <mat-paginator
    *ngIf="!isSearched && vesselList.length != 0"
    [length]="length"
    [pageIndex]="pageIndex"
    [showFirstLastButtons]=true
    [pageSize]=50
    (page)="pageEvent = onPageChange($event)"
    [style]="{'font-size': '15px', 'margin-right': '50px', 'color': 'var(--text-color)', 'background-color': 'rgba(0,0,0,0)'}"
    hidePageSize="true"
  >
  </mat-paginator>
</div>