import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as constants from '../_model/string_literal';
import { UserData, postUserData, inviteUserData } from '../_model/user_data';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userList: UserData[] = [];
  _userDataChanged = new BehaviorSubject<boolean>(false);
  userDataChanged: boolean = false
  associateUserList: any[] = [];
  //userApplicationList: userApplication[] = [];
  selectedUserID = '';
  editUserData!: postUserData;
  editUserFlag = false;
  skipListFlag = false;
  zoomFlag = false; 
  token = localStorage.getItem('token') || JSON.stringify({'token':''}); 

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  httpOptionsAuth = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization':  `Bearer ${JSON.parse(String(this.token))["token"]}`
    })
  };

  constructor(
    private http: HttpClient
  ) { }

  //userList: any[] = [];
//defulat admin list
  getUserList() {
    return this.http.get<UserData[]>(constants.IAPI_ENDPOINT + '/user',this.httpOptionsAuth);
  }

  UserData(id:any) {
    return this.http.get(constants.IAPI_ENDPOINT + `/user/${id}`,this.httpOptionsAuth);
  }

  getUserCount() {
    return this.http.get<{count:number}>(constants.IAPI_ENDPOINT + '/user/count',this.httpOptionsAuth);
  }

  getUserChanged() {
    return this._userDataChanged.asObservable();
  }
  userListUpdate() {
    this.userDataChanged = !this.userDataChanged;
    this._userDataChanged.next(this.userDataChanged);
  }

  getAuth(){
    return this.http.get<{userId: string}>(constants.IAPI_ENDPOINT + '/auth/check',this.httpOptionsAuth);
  }

  submitExistingUser(userData: any){
    return this.http.put(constants.IAPI_ENDPOINT + '/user/' + `${this.selectedUserID}`, JSON.stringify(userData), this.httpOptionsAuth);
  }

  submitNewUser(inviteUserData: any){
    return this.http.post(constants.IAPI_ENDPOINT + '/user/jinvite' , JSON.stringify(inviteUserData), this.httpOptionsAuth);
  }

  deleteUserData(userId : any){
    return this.http.post(constants.IAPI_ENDPOINT + '/user/juserdel', JSON.stringify({"userId": userId}), this.httpOptionsAuth); 
  }

  resetEditUser(){
    this.editUserData = {
      name: '',
      first_name: '',
      last_name: '',
      pincode: '',
      position: '',
      address: '',
      telephone: '',
      fax: '',
      email: '',
      thumbnailExtension: '',
      oid: '',
      company_id: '',
      role_id: 1,
    };
    this.editUserFlag = false;
  }

  resetSelectedUserID() {
    this.selectedUserID = '';
  }

}
