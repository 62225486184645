import { Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {FormControl} from '@angular/forms';

import { CompanyService } from 'src/app/_service/company.service';
import { VesselService } from 'src/app/_service/vessel.service';
import { UserService } from 'src/app/_service/user.service';
import { UserData, postUserData, editUserForm } from 'src/app/_model/user_data';
import { getCompanyData } from 'src/app/_model/company';
import { getVesselData } from 'src/app/_model/vessel';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserListComponent } from '../user-list/user-list.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import * as routing from 'src/app/_model/routing'


@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css'],
})

export class UserEditComponent implements OnInit, OnDestroy {
  @Output() dataChange = new EventEmitter();
  form = new editUserForm;
  editUserForm = this.form.defaultForm;
  editUserData!:postUserData;
  editMode = false;
  ismax:boolean = false;
  userID = '';
  userData!: postUserData;
  visible: boolean = false;

  constructor(
    private location: Location,
    private userService: UserService,
    private companyService: CompanyService,
    private vesselService: VesselService,
    private dialogRef: MatDialogRef<UserListComponent>,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public router: Router,
    private dialog: MatDialog,

  ) {
    this.companyList = this.companyService.companyList;
    this.vesselList = this.vesselService.vesselList;
    this.iconRegistry.addSvgIcon(
      'zoom',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/zoom.svg')
    );
    this.iconRegistry.addSvgIcon(
      'zoomin',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/zoomin.svg')
    );
  }

  companyList:getCompanyData[];
  vesselList:getVesselData[];
  roles = [
    {
      "id": 0,
      "name": "Admin"
    },
    {
      "id": 1,
      "name": "User"
    },
    {
      "id": 2,
      "name": "Third Party"
    },
    {
      "id": 3,
      "name": "Global Admin"
    },
  ];

  ngOnInit(): void {
    if(this.userService.editUserFlag){
      this.setEditUserData(this.userService.editUserData);
      this.editMode = true;
    }else{
      this.editMode = false;
    }
  }

  ngOnDestroy(): void {
    this.userService.resetSelectedUserID();
    this.userService.resetEditUser();
    this.editUserForm.reset();
  }

  filterCompany(searchValue: any): void{
    this.companyList = this.companyService.companyList;
    this.companyList = this.companyList.filter(company => company.name.toLowerCase().startsWith(searchValue.target.value.toLowerCase()))
  }

  filterVessel(searchValue: any): void{
    this.vesselList = this.vesselService.vesselList;
    this.vesselList = this.vesselList.filter(vessel => vessel.name.toLowerCase().startsWith(searchValue.target.value.toLowerCase()))
  }

  setEditUserData(editUserData: postUserData){
    this.editUserForm.patchValue(<any>editUserData);
    this.editUserForm.get("pincode")?.setValue("");
  }

  onSubmit() {
    if(this.checkSubmit()){
      if(this.editMode){
        //update existing user
        this.userService.userListUpdate();
        this.userService.submitExistingUser(this.editUserForm.value)
        .subscribe({
          error: (err) => {window.alert('Error occurred');},
          complete: () => {
            this.editUserForm.reset();
            this.dialogRef.close();
            this.dataChange.emit();
          }
        })
      }else{
        return;
      }
    }
  }

  onDelete() {
    if (this.checkDelete()) {
      this.userService.userListUpdate();
      this.userService.deleteUserData(this.userService.selectedUserID)
      .subscribe({
        error: () => {
          window.alert('Error occurred');
        },
        complete: () => {
          window.alert('User ' + this.editUserForm.value.name + ' deleted');
          this.dialogRef.close();
        }
      });
    }
  }

  checkDelete() {
    if (window.confirm('Are you sure you want to delete the user?')) {
      return true;
    } else {
      return false;
    }
  }

  checkSubmit() {
    if (window.confirm('Can I edit this?')) {
      return true;
    } else {
      return false;
    }
  }
  onClose() {
    this.userService.editUserFlag= false;
  }
  goBack() {
    this.userService.editUserFlag= false;
    this.location.back();
  }
}
