import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { VesselService } from 'src/app/_service/vessel.service';

import * as routing from '../../_model/routing';
import { getVesselData } from 'src/app/_model/vessel';
import { StreamingHttpUtil } from 'src/app/_common/common.streaminghttp';

@Component({
  selector: 'app-vessels',
  templateUrl: './vessels.component.html',
  styleUrls: ['./vessels.component.css']
})
export class VesselsComponent implements OnInit, OnDestroy {
  @Output() dataChange = new EventEmitter();

  loadingFlag = true;
  selectedTab: string = 'tab1';
  inkBarPosition: string = '0%';
  vesselCounter = 0;
  constructor(
    private router: Router,
    private vesselService: VesselService
  ) { }

  ngOnInit(): void {
    this.vesselService.checkVisitVesselPage(true);
    this.getVesselCount();
    if(this.vesselService.skipListFlag){
      this.loadingFlag = false;
      this.vesselService.skipListFlag = false;
    }else{
      this.loadingFlag = false;
      this.router.navigate([routing.VesselList]);
    }
  }

  ngOnDestroy(): void {
    this.vesselService.checkVisitVesselPage(false);
  }
  getVesselCount() {
    this.vesselService.getVesselCount()
    .subscribe({
      next: (value) => {this.vesselCounter = value.count;}
    })
  }
  getVesselList() {
    this.router.navigate([routing.Vessel]);
    this.loadingFlag = true;
    this.vesselService.getVesselList()
    .subscribe({
      next: async(value) => {
        this.vesselService.vesselList = [];
        const streamingJson = await StreamingHttpUtil.getStream(value);
        const vessels:getVesselData[] = JSON.parse(streamingJson);
        this.vesselService.vesselList.push(...vessels.filter((vessel) => vessel.name));
      },
      complete: () => {
        this.loadingFlag = false;
        this.dataChange.emit();
      }
    });
    this.goVesselList();
  }

  goVesselRegister() {
    this.router.navigate([routing.VesselRegister]);
  }

  jumpCreateVessel() {
    this.router.navigate([routing.VesselRegistration]);
  }

  goVesselList() {
    this.router.navigate([routing.VesselList]);
  } 
  selectTab(tab: string): void {
    this.selectedTab = tab;
    if (tab === 'tab1') {
      this.inkBarPosition = '0%';
      // this.router.navigate([routing.AllEntries]);
    } else{
      this.inkBarPosition = '100%';
    }
  }

  onActivate(elementRef: any) {
    elementRef.dataChange.subscribe((event:any) => {
      this.getVesselList();
    });
  }
}
