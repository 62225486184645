import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-not-registered',
  templateUrl: './not-registered.component.html',
  styleUrls: ['./not-registered.component.css']
})
export class NotRegisteredComponent implements OnInit {

  constructor(private dialog: MatDialog,private authService: MsalService,) { }

  ngOnInit(): void {
  }
  logout() {
    this.authService.logoutRedirect({
      postLogoutRedirectUri: environment.msalConfig.auth.redirectUri
    });
  }
}
