import { Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';

import { CompanyService } from 'src/app/_service/company.service';
import { getCompanyData, postCompanyData, companyForm, companyType } from 'src/app/_model/company';
import { FormArray, FormControl,ReactiveFormsModule } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CompanyEditComponent } from '../company-edit/company-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-company-register',
  templateUrl: './company-register.component.html',
  styleUrls: ['./company-register.component.css'],
})
export class CompanyRegisterComponent implements OnInit, OnDestroy {
  @Output() dataChange = new EventEmitter();
  form = new companyForm;
  companyForm = this.form.defaultForm;
  editCompanyData!: postCompanyData;
  editMode = false;
  isSubmitting = false;
  companyTypes: companyType[] = [];

  
  
  types: string[] = [];
  constructor(
    private location: Location,
    private companyService: CompanyService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) { 
    this.translateService.setDefaultLang('en');
    this.iconRegistry.addSvgIcon(
    'company',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/company.svg')
    );
    this.iconRegistry.addSvgIcon(
    'zoomin',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/zoomin.svg')
    );
    this.iconRegistry.addSvgIcon(
      'zoom',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/zoom.svg')
    );
  }

  ngOnInit(): void {
    if(this.companyService.editCompanyFlag){
      this.setEditCompanyData(this.companyService.editCompanyData);
      this.addToCompanyData(this.companyService.editCompanyData)
      this.editMode = true;
    }else{
      this.editMode = false;
      this.companyForm.patchValue({types: []});
    }
    this.companyTypes=[
      { value: 'owner', display: this.translateService.instant('owner') },
      { value: 'exowner', display: this.translateService.instant('exOwner') },
      { value: 'manager', display: this.translateService.instant('manager') },
      { value: 'exmanager', display: this.translateService.instant('exManagement') },
      { value: 'builder', display: this.translateService.instant('builder') },
      { value: 'manufacturer', display: this.translateService.instant('manufacturer') },
      { value: 'maintainer', display: this.translateService.instant('maintainer') }
    ];
  }
  
  ngOnDestroy(): void {
    this.companyService.resetSelectedCompanyID();
    this.companyService.resetEditCompany();
    this.companyForm.reset();
  }

  setEditCompanyData(editCompanyData: postCompanyData){
    this.companyForm.patchValue(editCompanyData);
  }

  onSubmit() {
    this.companyForm.get('types')?.setValue(this.types);
    // console.log(this.companyForm.value);
    if(this.checkSubmit()){
      if(this.editMode){
        //update existing company
        this.companyService.submitExistingCompany(this.companyForm.value)
        .subscribe({
          error: (err) => {window.alert('error occurred');},
          complete: () => {
            window.alert('Company data updated');
            this.companyForm.reset();
            this.dataChange.emit();
          
          }
        })
      }else{
        // console.log(this.types,this.companyForm.value)
        // register new company
        this.isSubmitting = true;
        this.companyService.submitNewCompany(this.companyForm.value)
        .subscribe({
          error: (err) => {
            window.alert('error occurred');
            this.isSubmitting = false;

          },
          complete: () => {
            window.alert(' company registered');
            this.companyForm.reset();
            this.location.back();
            this.isSubmitting= false;
            this.dataChange.emit();
          }
        })
      }
    }
  }
  tempdata!:postCompanyData;
  addToCompanyData(data: any):any {
    this.tempdata = {
      name: data.name,
      otherName: data.otherName,
      ceo: data.ceo,
      address: data.address,
      addressLineTwo: data.addressLineTwo,
      city: data.city,
      zipCode: data.zipCode,
      telephone: data.telephone,
      fax: data.fax,
      email: data.email,
      thumbnailExtension: data.thumbnailExtension,
      businessContents: data.businessContents,
      rank: data.rank,
      country:data.country,
      types: data.types,
      typeOwner: data.typeOwner,
      typeManagement: data.typeManagement,
      typeBuilder: data.typeBuilder,
      typeManufacturer: data.typeManufacturer,
      typeMaintainer: data.typeMaintainer,
      typeExOwner: data.typeExOwner,
      typeExManagement: data.typeExManagement,
    };
    
  }
  Onclick(){
    // this.router.navigate([routing.UserEdit]);
    this.companyService.editCompanyFlag=true;
    this.companyService.editCompanyData =this.tempdata;  
    // this.router.navigate([routing.UserList]);

    this.dialog.open(CompanyEditComponent,{
      width:'744px',
      height: '85vh',
      // panelClass: 'fullscreen-dialog'
    })
  }
  onChange($event:any) {
    // console.log($event.target.value,$event.target.checked)
    let values = $event.target.value;
    let isChecked = $event.target.checked;
    let index = this.types.indexOf(values);
    if (isChecked && index === -1) {
      this.types.push(values);
    } else if (!isChecked && index !== -1) {
      this.types.splice(index, 1);
    }
  }
  onClose(){
    this.companyService.editCompanyFlag= false;
  }
  checkSubmit() {
    if(window.confirm('Can I register this?')){
      return true;
    }else{
      this.companyForm.get('types')?.setValue(this.types);
      return false;
    }
  }

  goBack(){
    this.location.back();
  }
}
