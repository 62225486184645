<!-- <div class="header">
   <img src="../assets/images/side-nav/icon-torso.png" alt="icon"> 
  <mat-icon svgIcon="user" class="icon"></mat-icon>
  <h1 *ngIf="!editMode">{{'addNewUser' | translate}}</h1>
  <h1 *ngIf="editMode">{{'editUser' | translate}}</h1>
  <mat-icon svgIcon="zoomin" *ngIf="!editMode" class="zoom-out"></mat-icon>
  <button *ngIf="editMode" style="background-color: transparent; border: none;" (click)="Onclick()"><mat-icon svgIcon="zoomin"  class="zoom-out"></mat-icon></button>

</div> -->
<div class="sticky inset-x-0 top-0  bg-white flex mb-4 container">
  <mat-icon  svgIcon="user" class="icon mt-16 ml-16"></mat-icon>
  <h1 *ngIf="!editMode" class="text-tColor font-sans text-3xl font-bold mt-16 ">{{'addNewUser' | translate}}</h1>
  <h1 *ngIf="editMode" class="text-tColor font-sans text-3xl font-bold mt-16 ">{{'editUser' | translate}}</h1>
</div>
<hr>
  <form [formGroup]="userForm" (ngSubmit)="onSubmit()" autocomplete="off" [ngClass]="{'edit-mode-styles': editMode}">
    <div class="overscroll-y-contain object-scale-down">
      <div class="sm:max-w-2xl">
        <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
          <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'userInfo' | translate}}</div>   
          <div class="col-span-2 flex flex-col gap-5">
            <div class="flex flex-col">
              <span class="text-lColor font-medium text-base leading-normal font-sans">{{'* ' + ('userName' | translate)}}</span>
              <input type="text" pInputText placeholder="User Name" id="name" formControlName="name" class=" h-12 border border-lColor rounded px-4"/>
              <div *ngIf="userForm.get('name')?.invalid && userForm.get('name')?.touched" class="error-message">
                <div *ngIf="userForm.get('name')?.hasError('required')">This Field is Required</div>
              </div>  
            </div>
            <div class="flex flex-col">
              <span class="text-lColor font-medium text-base leading-normal font-sans">{{'* ' + ('firstName' | translate)}}</span>
              <input type="text" pInputText placeholder="First name" id="first_name" formControlName="first_name" class=" h-12 border border-lColor rounded px-4"/>
              <div *ngIf="userForm.get('name')?.invalid && userForm.get('name')?.touched" class="error-message">
                <div *ngIf="userForm.get('name')?.hasError('required')">This Field is Required</div>
              </div>  
            </div>
            <div class="flex flex-col">
              <span class="text-lColor font-medium text-base leading-normal font-sans">{{'* ' + ('lastName' | translate)}}</span>
              <input type="text" pInputText class=" h-12 border border-lColor rounded px-4"  id="last_name" placeholder="Last name" formControlName="last_name">
              <div *ngIf="userForm.get('first_name')?.invalid && userForm.get('first_name')?.touched" class="error-message">
                <div *ngIf="userForm.get('first_name')?.hasError('required')">This Field is Required</div>
              </div>
            </div>
            <div class="flex flex-col">
              <span class="text-lColor font-medium text-base leading-normal font-sans">{{'* ' + ('email' | translate)}}</span>
              <input type="email"pInputText class=" h-12 border border-lColor rounded px-4" id="email" placeholder="example@email.com" formControlName="email">
              <div *ngIf="userForm.get('email')?.invalid && userForm.get('email')?.touched" class="error-message">
                <div *ngIf="userForm.get('email')?.hasError('required')">This Field is Required</div>
              </div>            
            </div>
          </div>
        </div>
      </div>  
    <br/>  
    <hr class="w-full">
    <div class="sm:max-w-2xl">
      <div class="content mt-8 ml-14 grid grid-cols-3 gap-x-8 gap-y-5">
        <div class="col-span-1 text-tColor font-bold leading-normal font-sans">{{'userInfo' | translate}}2</div>   
        <div class="col-span-2 flex flex-col gap-5">
            <div class="flex flex-col">
              <span class="text-lColor font-medium text-base leading-normal font-sans">{{'* ' + ('pincode' | translate)}}</span>
              <input type="text" pInputText id="pincode"  formControlName="pincode" placeholder="Enter a Pincode" class=" h-12 border border-lColor rounded px-4"/>
              <div *ngIf="userForm.get('pincode')?.invalid && userForm.get('pincode')?.touched" class="error-message">
                <div *ngIf="userForm.get('pincode')?.hasError('required')">This Field is Required</div>
              </div>
          </div>
          <div class="flex flex-col">
              <span class="text-lColor font-medium text-base leading-normal font-sans">*{{'iPortalProductRole' | translate}}</span>
              <p-dropdown 
                [options]="roles" 
                placeholder="Select Role" 
                formControlName="role_id"
                [showClear]="true" 
                displayProperty = "id"
                [(ngModel)]="selectedRole" 
                optionLabel="id"
                optionValue="name"
                [required]="true"
                [style]="{'background-color': 'white', 'height': '3rem', 'border': '1px solid #828282', 'border-radius': '0.25rem', 'padding': '0 0.5rem', 'width': '100%'}">
                <ng-template pTemplate="selectedItem" let-selectedOption >
                  <div>{{ selectedRole }}</div>
                </ng-template>
                <ng-template let-role pTemplate="item" >
                  {{role.name}}
                </ng-template>
            </p-dropdown>
          </div>
          <div class="flex flex-col">
            <span class="text-lColor font-medium text-base leading-normal font-sans">{{'company' | translate}}</span>
            <p-dropdown 
                [options]="companyList" 
                placeholder="Select Company" 
                [filter]="true" 
                formControlName="company_id"
                filterBy="name" 
                [showClear]="true" 
                displayProperty = "id"
                [(ngModel)]="selectedCompany" 
                optionLabel="id"
                optionValue="name"
                [virtualScroll]="true" 
                [virtualScrollItemSize]="38"
                [required]="false"
                [style]="{'background-color': 'white', 'height': '3rem', 'border': '1px solid #828282', 'border-radius': '0.25rem', 'padding': '0 0.5rem', 'width': '100%'}">
                <ng-template pTemplate="selectedItem" let-selectedOption >
                  <div>{{ selectedCompany }}</div>
                </ng-template>
                <ng-template let-company pTemplate="item" >
                  {{company.name}}
                </ng-template>
            </p-dropdown>
        </div>
          <div class="flex flex-col">
            <span class="text-lColor font-medium text-base leading-normal font-sans">{{'vessel' | translate}}</span>
            <p-dropdown 
                [options]="vesselList" 
                placeholder="Select Item" 
                [filter]="true" 
                formControlName="vessel_id"
                filterBy="name" 
                [showClear]="true" 
                displayProperty = "id"
                [(ngModel)]="selectedVessel" 
                optionLabel="id"
                optionValue="name"
                [virtualScroll]="true" 
                [virtualScrollItemSize]="38"
                [required]="false"
                [style]="{'background-color': 'white', 'height': '3rem', 'border': '1px solid #828282', 'border-radius': '0.25rem', 'padding': '0 0.5rem', 'width': '100%'}">
                <ng-template pTemplate="selectedItem" let-selectedOption >
                  <div>{{ selectedVessel }}</div>
                </ng-template>
                <ng-template let-vessel pTemplate="item" >
                  {{vessel.name}}
                </ng-template>
            </p-dropdown>
          </div>
        </div>
      </div>
    </div> 
  </div>

  <div class="sticky inset-x-0 bottom-0 h-28 max-w-2xl space-x-4 al-end bg-white flex justify-end pl-21.20vw mt-1">
    <button type="button" *ngIf="!editMode" (click)="goBack()" class="bg-white outline-none text-tColor h-10">{{ 'cancel' | translate }}</button>
    <button type="submit" *ngIf="!editMode" [disabled]="!userForm.valid" class="text-white font-sans text-lg font-bold rounded-full bg-blue p-3 h-10 flex items-center justify-center cursor-pointer">{{ 'addNewUser' | translate }}</button>
  </div>
  </form>
  
  