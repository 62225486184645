<p>ID: {{companyID}}</p>
<div class="company-detail">
  <table class="company-table" mat-table [dataSource]="dataSource">
    <!-- Key Column -->
    <ng-container matColumnDef="key">
      <th mat-header-cell *matHeaderCellDef> Property </th>
      <td mat-cell *matCellDef="let element"> {{element.key}} </td>
    </ng-container>

    <!-- Value Column -->
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef> Data </th>
      <td mat-cell *matCellDef="let element"> {{element.value}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnName"></tr>
    <tr mat-row (click)="onRowClick(row)" *matRowDef="let row; columns: columnName;"></tr>
  </table>
</div>
<div class="button-area">
  <button mat-raised-button color="primary" (click)="editCompanyData()">edit data</button>
  <button mat-raised-button color="basic" (click)="goBackList()">back</button>
  <button mat-raised-button color="warn" (click)="deleteCompanyData()">delete</button>
</div>