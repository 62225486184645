import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyService } from 'src/app/_service/company.service';
import { VesselService } from 'src/app/_service/vessel.service';
import { UserService } from 'src/app/_service/user.service';
import { HttpClient } from '@angular/common/http';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { getAddons, getapplications, ids, postVesselApplications, postVesselData, vesselForm } from 'src/app/_model/vessel';
import { getCompanyData } from 'src/app/_model/company';
import { TreeNodeSelectEvent } from 'primeng/tree/tree.interface';
import * as routing from 'src/app/_model/routing';  
import { Location } from '@angular/common';

@Component({
  selector: 'app-vessel-edit',
  templateUrl: './vessel-edit.component.html',
  styleUrl: './vessel-edit.component.css'
})
export class VesselEditComponent implements OnInit {
  @Output() dataChange = new EventEmitter();
  token = localStorage.getItem('token');

  constructor(
    private location: Location,
    private companyService: CompanyService,
    private vesselService: VesselService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private router: Router,
  ) { 
    this.ownerList = this.companyService.companyList;
    this.managerList = this.companyService.companyList;
    this.exownerList = this.companyService.companyList;
    this.exmanagerList = this.companyService.companyList;
    this.builderList = this.companyService.companyList;
    this.maintainerList = this.companyService.companyList;
    this.iconRegistry.addSvgIcon(
      'vessel',
         sanitizer.bypassSecurityTrustResourceUrl('/assets/vessel.svg')
    );
    this.iconRegistry.addSvgIcon(
      'zoom',
         sanitizer.bypassSecurityTrustResourceUrl('/assets/zoom.svg')
    );
    this.iconRegistry.addSvgIcon(
      'zoomin',
         sanitizer.bypassSecurityTrustResourceUrl('/assets/zoomin.svg')
    );
  }
  
  form = new vesselForm;
  vesselForm = this.form.defaultForm;
  editVesselData!: postVesselData;
  editMode = false;
  shortID ='';
  status: "initial" | "uploading" | "success" | "fail" = "initial"; 
  file: File | null = null;
  image: File | null = null;
  selectedImage: string = '../assets/images/icon-photo.png';
  isImageChanged = false;
  ownerList: getCompanyData[];
  managerList: getCompanyData[];
  exownerList: getCompanyData[];
  exmanagerList: getCompanyData[];
  builderList: getCompanyData[];
  maintainerList: getCompanyData[];
  selectedManager!: '';
  selectedOwner!: '';
  selectedBuilder!: '';
  selectedMaintainer!: '';
  selectedexowner!: '';
  selectedexmanager!: '';
  isReadOnly: boolean = true;
  selectedVesselType: string = '';
  isSubmitting = false;
  updated = false;
  mainList!: any[];
  primeNgDataset:  any[]=[]
  primeNgTreeDataSet:  any[]=[]
  isCompleted: boolean = false;
  applicationList: getapplications[]= [];
  addonList : getAddons[] = [];
  nodes: any[] = [];
  selectedNodes:any[] = [];
  condition = true;

  // selectedList = new Map();
  selectedList: Map<string, string> = new Map<string, string>();
  selectedcapacityList: Map<string, string> = new Map<string, string>();
  vesselTypes= [
    {"type": "Vessel"},
    {"type": "SEP"}];
  engineTypes = [
    {"type":"V"},
    {"type":"S"}
  ]
  structuralDesignTypes=[
    {"type": "Monohull"},
    {"type":"Multihull"},
    {"type": "Double Hull"},
  ]
  operationalStatus=[
    {"type": "In operation"},
    {"type":"Not in operation"}
  ]
  RudderTypes=[
    {"type": "Balanced"},
    {"type": "Spade"},
    {"type": "Skeg Hung"},
    {"type": "Full Spade"}
  ]
  PropellerTypes =[
    {"type": "CPP"},
    {"type": "VPP"},
  ]
  classificationSocietyType=[
    {"type": "NK"},
    {"type": "DNV"},          
    {"type": "ABS"},
    {"type": "BV"},
    {"type": "CCS"},
    {"type": "CR"},
    {"type": "JG"},
    {"type": "KR"},
    {"type": "LR"},
    {"type": "RINA"},
    {"type": "Other"},
  ]
  engineFoUnitType=[
    {"type": "l (liter)", "value": "l"},
    {"type": "gal (gallon)", "value": "gal"},
    {"type": "bar (barrel)", "value": "bar"},
    {"type": "drum", "value": "drum"},
  ]  
  ngOnInit(): void {
    this.getApplicationList();
    this.getAddons();
    if(this.vesselService.editVesselFlag){
      this.setEditVesselData(this.vesselService.editVesselData);
      this.editMode = true;
      this.getSelectedVesselId();
      this.getImage();
      this.getVesselApplications();
    }else{
      this.editMode = false;
      // this.getUserList()
    }
  }

  ngOnDestroy(): void {
    this.vesselForm.reset();
  }
  compareVesselTypes(type1: any, type2: any): boolean {
    return type1 && type2 ? type1.name === type2.name : type1 === type2;
  }
  
  setEditVesselData(editData: postVesselData) {
    this.vesselForm.patchValue(editData);
  }

  getSelectedVesselId() {
    this.vesselService.uuidlongtoshort(this.selectedIds).subscribe({
      next: (value:any) => {
        this.shortID = value.shortuuid;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
  onOpenedChange(status: any, type: String) {

  }
  filterCompany(searchValue: any, type: String): void{
    const filteredCompanies = this.companyService.companyList.filter(company => company.name.toLowerCase().startsWith(searchValue.target.value.toLowerCase()));
    switch (type) {
      case 'owner':
        this.ownerList = filteredCompanies;
      break;
      case 'manager':
        this.managerList = filteredCompanies;
      break;
      case 'exowner':
        this.exownerList = filteredCompanies;
      break;
      case 'exmanager':
        this.exmanagerList = filteredCompanies;
      break;
      case 'builder':
        this.builderList = filteredCompanies;
      break;
      case 'maintainer':
        this.maintainerList = filteredCompanies;
      break;
    }
  }
  // manager selection
  onmanagerSelection(event: any): void {
    this.selectedManager = event.value;
    this.vesselForm.patchValue({
      manager_id: this.selectedManager,
    })
  }
//owner selection
ownerSelection(event:any){
  this.selectedOwner = event.value;
  this.vesselForm.patchValue({
    owner_id: this.selectedOwner,
  })
}
onClickClear(event:any){
  this.selectedOwner = "";
  this.vesselForm.patchValue({
    owner_id: null
  })
  event.stopPropagation();
}
//builder selection
builderSelection(event:any){
  this.selectedBuilder = event.value;
  this.vesselForm.patchValue({
    builder_id: this.selectedBuilder
  })
}
//maintainer selection
maintainerSelection(event:any){
  this.selectedMaintainer = event.value;
  this.vesselForm.patchValue({
    maintener_id: this.selectedMaintainer
  })
}
//exowner selection
exownerSelection(event:any){
  this.selectedexowner = event.value;
  this.vesselForm.patchValue({
    exowner_id: this.selectedexowner
  })
}
//exmanager selection
exmanagerSelection(event:any){
  this.selectedexmanager = event.value;
  this.vesselForm.patchValue({
    exmanager_id: this.selectedexmanager
  })
}
//Upload image
  onSelect(event:any){
    const file: File = event.target.files[0];
    if(file){
      this.image = file;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedImage = e.target.result;
        this.isImageChanged =true;
      };
      reader.readAsDataURL(file);

    }
  }
  onImageUpload(){
    if(this.image){
      const uploadImage = this.vesselService.putVesselThumbnail(this.image);
      uploadImage.subscribe({
        error:(err) =>{
          console.log("Error Occured: " + err);
        },
        complete:() =>{
          console.log("Image Uploaded");
        },
        
      })
    }
  }
  getImage() {
    this.vesselService.getVesselThumbnail().subscribe(
      {
        next: (response: any) => {
          const blob = new Blob([response], { type: 'image/png' });
          const url = window.URL.createObjectURL(blob);
  
          const img = new Image();
          img.src = url;
          this.selectedImage= url;
          const container = document.getElementById('imageContainer');
          if (container && img) {
            container.innerHTML = '';
            container.appendChild(img);
          }
          if(img){
            this.isImageChanged =true;
          }
        },
        error: (error: any) => {
          console.error('Error loading image', error);
        },
      }
    );
  }
  
    
  
  //vessel mapping
  onChange(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      this.status = "initial";
      this.file = file;
    }
  }
  onUpload() {
    if (this.file && this.shortID) {
      const upload$ = this.vesselService.uploadFile(this.file, this.shortID);
      this.status = 'uploading';

      upload$.subscribe({
        next: () => {
          this.status = 'success';
        },
        error: () => {
          this.status = 'fail';
        },
      });
    }
  } 

  // get appplication list from api
  getApplicationList(){
    this.vesselService.getApplications().subscribe({
      next: (value) => {
        // console.log(value);
        value.forEach(element => {this.applicationList.push(element)});
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => { 
        // console.log('complete');
        this.isCompleted=true
         
      }
    })
  }

  //get addon list from the api
  getAddons(){
    this.vesselService.getAddon().subscribe({
      next: (value:any) => {
        // console.log(value);
        value.forEach((element:any) => {this.addonList.push(element)});
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        if(this.isCompleted){
          this.mainList= this.createTreeFormat(this.applicationList,this.addonList);
          this.primeNgDataset = this.createTreeFormatPrimeNg(this.applicationList,this.addonList);        
        }
      }
    })
  }

 //create a tree structure from the application list and addon- for dropdown
createTreeFormat(applicationList: getapplications[], addonList: getAddons[]) {
  let main_len = applicationList.length;
  const treeRoots: any[] = [];

  for (let i = 0; i < main_len; i++) {
    let treeRoot = {
      key: i.toString(),
      id: applicationList[i].id,
      name: applicationList[i].name,
      description: applicationList[i].description,
      parent:'Subscription',
      children: addonList.map((addon, j) => ({
        key: `${i}${j}`,
        id: addon.id,
        name: addon.name,
        description: addon.description,
        company: addon.company,
      })),
    };

    treeRoots.push(treeRoot);
  }

  return treeRoots;
};

//for primeNg- for dropdown
createTreeFormatPrimeNg(applicationList: getapplications[], addonList: getAddons[]) {
  let main_len = applicationList.length;
  const treeRoots: any[] = [];

  for (let i = 0; i < main_len; i++) {
    let treeRoot = {
      key: i.toString(),
      data: applicationList[i].id,
      label: applicationList[i].name,
      parent:'Subscription',
      children: addonList.map((addon, j) => ({
        key: `${i}${j}`,
        data: addon.id,
        label: addon.name,
        company: addon.company,
      })),
    };
    treeRoots.push(treeRoot);
  }
  return treeRoots;
};

//emitters
onNodeSelect(event: TreeNodeSelectEvent) {
  const selectedNode = event.node;
  // Check if parent label is not null
  if (selectedNode.parent?.label != null) {
      const newRoot = {
          key: `${this.primeNgTreeDataSet.length}`,
          label: selectedNode.parent.label,
          data: selectedNode.parent.data,
          children: [
              {
                  key: `${this.primeNgTreeDataSet.length}-0`,
                  label: selectedNode.label,
                  data: selectedNode.data,
              }
          ]
      };

      // If the dataset is empty, push the new root directly
      if (this.primeNgTreeDataSet.length === 0) {
          this.primeNgTreeDataSet.push(newRoot);
      } else {
          // Check the availability of the parent
          const position = this.primeNgTreeDataSet.findIndex(item => item.label === selectedNode.parent?.label);

          if (position !== -1) {
              // Parent is available, check availability of the child
              const childPosition = this.primeNgTreeDataSet[position].children.findIndex((item: any) => item.label === selectedNode.label);

              if (childPosition === -1) {
                  // Child is not available, add the child
                  const childRoot = {
                      key: `${position}-${this.primeNgTreeDataSet[position].children.length}`,
                      label: selectedNode.label,
                      data: selectedNode.data,
                  };
                  this.primeNgTreeDataSet[position].children.push(childRoot);
              }
          } else {
              // Parent is not available, add the new root
              this.primeNgTreeDataSet.push(newRoot);
          }
      }
  } else {
    const newRoot = {
      key: `${this.primeNgTreeDataSet.length}`,
      label: selectedNode.label,
      data: selectedNode.data,
      children: []
    };
    this.primeNgTreeDataSet.push(newRoot);
  }
}

//when chnages in selected list
editSelection(event: TreeNodeSelectEvent) {
  const selectedNode = event.node;
  const parentIndex = this.primeNgTreeDataSet.findIndex(item => item.label === selectedNode.parent?.label);
  console.log(selectedNode)
  console.log(parentIndex)
  if (parentIndex !== -1) {
      const childIndex = this.primeNgTreeDataSet[parentIndex].children.findIndex(
          (child: any) => child.label === selectedNode.parent?.children?.[0]?.label
      );
      if (childIndex !== -1) {
          // Get the label of the item being removed
          const removedLabel = this.primeNgTreeDataSet[parentIndex].children[childIndex].label;
          console.log(removedLabel)
          // Remove the item from the children array
          this.primeNgTreeDataSet[parentIndex].children.splice(childIndex, 1);
          // Remove the parent if it has no children
          if (this.primeNgTreeDataSet[parentIndex].children.length === 0) {
              this.primeNgTreeDataSet.splice(parentIndex, 1);
          }
          // Remove the item from selectedNodes array
        if (this.selectedNodes.length > 0) {
          const selectedNodesChildIndex = this.selectedNodes.findIndex(node => node.parent?.label === selectedNode.parent?.label);
        
          this.selectedNodes = this.selectedNodes.filter(e =>
            !(e.parent?.label === selectedNode.parent?.label && e.label === removedLabel)
          );     
          if (this.primeNgTreeDataSet.length === 0) {
            this.selectedNodes = [];
          }
        }
      }
  }  
}

//outputs from the UIsx
selectedIds: ids[] = [];  
selectedItemSetup(dataset: any[]) {
  dataset.forEach(item => {

      let childrenIds: any[] = [];
    
      if (item.children && item.children.length > 0) {
          childrenIds = item.children.map((child: any) => child.data);
      }

      const data  = {
           application_id: item.data,
          addon: childrenIds
      };
      this.selectedIds.push(data)
     return this.selectedIds;

  });
}

// add application to the vesselapi
  
addApplications(newVesselId: string,) {
  this.selectedIds.forEach((applicationId) => {
    const data: postVesselApplications = {
      vessel_id: newVesselId,
      application_id: applicationId.application_id,
      addon: applicationId.addon
    };
    this.vesselService.submitVesselApplications(data).subscribe({
      next: (value) => {

      },
      error: (error) => {
        console.error(error);
      },
    });
  });
}
receiveData: any[] =[];
getVesselApplications(){
  this.vesselService.getvesselApplicationList().subscribe({
    next: (value:any) => {
      value.forEach((a:any) => {
        this.receiveData.push(a)
      });
    },
    error: (error) => {
      console.error(error);
    },
    complete: () => {
      this.getDetails()
    }
  })
}
getDetails() {
  this.receiveData.forEach((a: any, i) => {  
    let label;   
    const matchedApplication = this.applicationList.find((b: any) => b.id === a.application_id);
    if (matchedApplication) {
    label = matchedApplication.name;
    } 

    const matchingAddonsLabels = a.addon.map((c: any) => {
      const matchingAddon = this.addonList.find((d: any) => d.id === c);
      return matchingAddon ? matchingAddon.name : null;
    });

    let temp:any[]=[]
    matchingAddonsLabels.forEach((b: any, index:any) => {
      const children = {
        key: `${i}-${index}`,
        label: b,
        data: a.addon[index],
      }
      temp.push(children)
    })
    const node = {
      key: `${i}`,
      label: label,
      data: a.application_id,
      children: temp
    }
    this.primeNgTreeDataSet.push(node);
    this.selectedNodes.push(node);
  });
}

onNodeUnselect(event: TreeNodeSelectEvent) {
  const unselectedNode = event.node;

  // Handle node unselection and update the tree dataset
  const parentIndex = this.primeNgTreeDataSet.findIndex(item => item.label === unselectedNode.parent?.label);
  
  if (parentIndex !== -1) {
    const childIndex = this.primeNgTreeDataSet[parentIndex].children.findIndex(
      (child: any) => child.label === unselectedNode.label
    );
    
    if (childIndex !== -1) {
      // Remove the unselected item from the children array
      this.primeNgTreeDataSet[parentIndex].children.splice(childIndex, 1);
    }
  } else {
    const idx = this.primeNgTreeDataSet.findIndex(item => item.label === unselectedNode.label);
    this.primeNgTreeDataSet.splice(idx, 1);
  }

}

jumpCreateVessel() {
  this.router.navigate([routing.VesselRegistration]);
}


  onSubmit() {
    if (this.checkSubmit()) {
      if (this.editMode) {
        this.onImageUpload();
        // update existing vessel
        this.selectedItemSetup(this.primeNgTreeDataSet);
        this.addApplications(this.vesselService.selectedVesselID)
        this.vesselService.submitExistingVessel(this.vesselForm.value)
          .subscribe({
            error: (err) => {  window.alert('Error Occurred'); },
            complete: () => {
              window.alert('Vessel Updated');
              this.updated = true;
              this.dataChange.emit();
              this.vesselService.vesselListUpdate();
            }
          });
      } else {
        // register new vessel
        this.isSubmitting = true;
        this.selectedItemSetup(this.primeNgTreeDataSet);
        this.vesselService.submitNewVessel(this.vesselForm.value)
          .subscribe({
            next: (value: any) => {
              // this.isSubmitting= true;
              const newVesselId = value.id;
              this.uploadnewImage(newVesselId);
              this.addApplications(newVesselId);
            },
            error: (err) => { window.alert('Error Occurred');this.isSubmitting= false; },
            complete: () => {
              this.isSubmitting= false;
              window.alert('Vessel Registered');
              this.vesselForm.reset();
              this.selectedNodes = [];
              this.primeNgTreeDataSet= [];
              this.dataChange.emit();
            }
          });
      }
    }
  }
  
  uploadnewImage(newVesselId: string) {
    this.vesselService.selectedVesselID = newVesselId;
    this.onImageUpload();
  }
  
  checkSubmit() {
    if(window.confirm('Can I register this?')){
      return true;
    }else{
      return false;
    }
  }
  onClose(){
    this.vesselService.editVesselFlag= false;
    this.location.back();
  }
  
  goBack() {
    this.location.back();
  }

  onDelete() {
    if (this.checkDelete()) {
      this.vesselService.deleteVesselData()
      .subscribe({
        error: () => {
          window.alert('Error occurred');
        },
        complete: () => {
          window.alert('Vessel ' + this.vesselForm.value.name + ' deleted');
          this.location.back();
        }
      });
    }
  }

  checkDelete() {
    if (window.confirm('Are you sure you want to delete the vessel?')) {
      return true;
    } else {
      return false;
    }
  }

}
