<div color="primary" class="mat-toolbar" [class.toolbar-night]="mode == 'night'" *ngIf="loginDisplay">
  <!-- <a class="title" href="/">{{ title }}</a> -->
  <img src="../assets/images/defaults/iportal_logo.svg" style="height: 30px; margin-left: -25px; margin-top: 3px;" *ngIf="mode=='day'"/>
  <img src="../assets/images/defaults/iportal_logo_night.svg" style="height: 53px; margin-left: -25px; margin-top: -8px;" *ngIf="mode=='night'"/>
  <div class="toolbar-spacer"></div>
  <label class="switch">
    <input type="checkbox" (click)="switchMode()">
    <span class="slider round"></span>
  </label>
  <button type="button" (click)="switchLanguage()" [class.selectedlan]="selectedLanguage === 'jp' && mode === 'day'"
  [class.selectedlan-night]="selectedLanguage === 'jp' && mode === 'night'"
  [ngClass]="mode == 'day' ? 'btn-lng' : 'btn-lng-night'">{{'jp' | translate}}</button>
  <button type="button" (click)="switchLanguage()" [class.selectedlan]="selectedLanguage === 'en' && mode === 'day'"
  [class.selectedlan-night]="selectedLanguage === 'en' && mode === 'night'"
  [ngClass]="mode == 'day' ? 'btn-lng' : 'btn-lng-night'">{{'EN' | translate}}</button>
  <div *ngIf="loginDisplay" class="username">{{userName}}</div> 
  <div class="logout-button" [class.logout-button-night]="mode=='night'">
    <button mat-raised-button *ngIf="loginDisplay" (click)="logout()">{{'logOut' | translate}}</button>
  </div>
</div> 
<hr *ngIf="mode=='day'" style="z-index: 100; margin-top: 60px; width: 100%; position: fixed;"/>
<hr style="border-color: #161a1e; z-index: 100; margin-top: 60px; width: 100%; position: fixed;" *ngIf="mode=='night'"/>
<mat-sidenav-container [class.logged-in]="loginDisplay">
  <mat-sidenav mode="side" [opened]="isSidenavOpened" *ngIf="loginDisplay" [class.mat-sidenav-night]="mode=='night'" style="overflow: hidden">
    <!-- sidenav area -->
    <div class="sidenav-content">
      <span (click)="goDashboardPage()" [class.selected]="selectedTab === 'dashboard'">
        <!-- <img src="../assets/images/side-nav/group-9.png" alt="Dashboard" class="icon" > -->
          <mat-icon svgIcon="home" class="icon" [class.icon-night]="mode=='night'"></mat-icon>
        <span class="text" [class.text-night]="mode=='night'">{{ 'home' | translate }}</span>
      </span>
      <span (click)="goCompanyPage()" [class.selected]="selectedTab === 'companies'">
        <!-- <img src="../assets/images/side-nav/icon-building.png" alt="Companies" class="icon"> -->
          <mat-icon svgIcon="company" class="icon" [class.icon-night]="mode=='night'"></mat-icon>
        <span class="text" [class.text-night]="mode=='night'">{{ 'companies' | translate }}</span>
      </span>
      <span (click)="goVesselPage()" [class.selected]="selectedTab === 'vessel'">
        <!-- <img src="../assets/images/side-nav/vessel-tug-pilot.png" alt="Vessel" class="icon"> -->
          <mat-icon svgIcon="vessel" class="icon" [class.icon-night]="mode=='night'"></mat-icon>
        <span class="text" [class.text-night]="mode=='night'">{{ 'vessels' | translate }}</span>
      </span>
      <span (click)="goUserPage()" [class.selected]="selectedTab === 'users'">
        <!-- <img src="../assets/images/side-nav/icon-torso.png" alt="Users" class="icon"> -->
          <mat-icon svgIcon="user_select" class="icon" [class.icon-night]="mode=='night'"></mat-icon>
        <span class="text" [class.text-night]="mode=='night'">{{ 'users' | translate }}</span>
      </span>
      <!-- <span (click)="goDashboardPage()">Dashboard</span>
      <span (click)="goCompanyPage()">Companies</span>
      <span (click)="goVesselPage()">Vessel</span>
      <span (click)="goUserPage()">Users</span> -->
    </div>    
    <div class="user-container" [class.user-container-night]="mode=='night'">
        <!-- <img src="../assets/images/side-nav/icon-torso.png" alt="User Image" class="user-image"> -->
      <div style="float: left; width: 30%">
        <mat-icon svgIcon="user" style="height: 56px; width: 56px; margin-top: 28px; margin-left: 28px; border-radius: 50%;" [class.user-icon-night]="mode=='night'"></mat-icon>
      </div>
      <div style="float: left; width: 45%" style="margin-top: 32px;">
        <div style="float: top; height: 25px" class="user-name" [class.user-name-night]="mode=='night'">{{userName}}</div>
        <div style="float: top; height: 25px" class="user-email" [class.user-email-night]="mode=='night'">{{userEmail}}</div>
      </div>
      <div style="float: right; width: 25%; margin-top:-38px; margin-right: 10px">
        <mat-icon svgIcon="exit" class="exit-icon" [class.exit-icon-night]="mode=='night'" *ngIf="loginDisplay" (click)="logout()"></mat-icon>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content [class.logged-in]="loginDisplay" [class.night]="mode=='night'">
    <div class="container">
      <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
      <div class="landing-page">
        <div class="home-logo" *ngIf="!loginDisplay">
          iPortal
        </div>
        <div class="login" *ngIf="!loginDisplay">
          <div class="welcome-text">Welcome to iPortal</div>
          <button mat-raised-button class="signinbtn" *ngIf="!loginDisplay" (click)="login()">&nbsp;サインイン / Sign in</button>
          <div class="infoceanus">
            <a target="_blank" class="redirect-infoceanus" href="https://infoceanus.com" rel="noreferrer">
                <img src="../assets/images/defaults/infoceanus.png">
            </a>
          </div>
        </div>
      </div>
      <!-- <button mat-raised-button *ngIf="loginDisplay" (click)="logout()">Logout</button> -->
      <router-outlet *ngIf="!isIframe"></router-outlet>
    </div>
    
    <div class="contents" style="overflow: scroll;">
      <router-outlet *ngIf="!isIframe"></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>