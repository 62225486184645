import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { CompanyService } from 'src/app/_service/company.service';
import { VesselService } from 'src/app/_service/vessel.service';

import * as routing from '../../../_model/routing';
import { getCompanyData, postCompanyData } from 'src/app/_model/company';
import { MatDialog } from '@angular/material/dialog';
import { CompanyRegisterComponent } from '../company-register/company-register.component';
import { StreamingHttpUtil } from 'src/app/_common/common.streaminghttp';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CompanyEditComponent } from '../company-edit/company-edit.component';
import { Subscription } from 'rxjs';
interface Column {
  field: string;
  header: string;
}
@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.css']
})
export class CompanyListComponent implements OnInit {
  @Output() dataChange = new EventEmitter();
  @ViewChild('table', {read: ElementRef}) table!: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort ;
  companyList: any = [];
  companyCache: any = {};
  dataSource:MatTableDataSource<getCompanyData>;
  columnName:string[];
  dataload: boolean = true;
  selectedRowIndex: number = -1;
  isSearched: boolean = false;
  isSearching: boolean = false;
  constructor(
    private router: Router,
    private companyService: CompanyService,
    private vesselService: VesselService,
    public dialog: MatDialog,
  ) {
    // this.companyList = this.companyService.companyList;
    this.dataSource = new MatTableDataSource();
    this.columnName = ['name','tele','address','businessContents','types','lastupdated', 'editform'];
  }
  companyData!: postCompanyData;
  companyId ='';
  cols!: Column[];
  selected: any;
  pageEvent: any;
  pageIndex: any;
  limit = 50;
  length: number = 0;
  loading: boolean = false;
  completed: boolean = false;
  private companyCountSubscription!: Subscription;
  private companyListSubscription!: Subscription;
  companyCount!: number;
  companyCounter = 0;
  ngOnInit(): void {
    this.cols = [
      { field: 'name', header: 'User Name' },
      { field: 'tele', header: 'Information Summary' },
      { field: 'address', header: 'Address' },
      { field: 'businessContents', header: 'Business Contents' },
      { field: 'types', header: 'Tyes' },
      { field: 'updatedAt', header: 'Last Updated' }
  ];
    this.vesselService.skipListFlag = false;
    this.pageIndex = 0;
    // this.vesselService.vesselList = [];
    this.companyService.resetSelectedCompanyID();
    this.getCompanyListPageOne();
    this.companyService.getCompanyChanged().subscribe(companyChanged => {
      if (companyChanged) {
        this.companyCache = {};
        this.updatePageData(this.pageIndex, this.limit);
        this.companyService.companyListUpdate();
      }
    });
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  getCompanyListPageOne() {
    this.companyService.getCompanyCount().subscribe({
      next: (value) => {
        this.companyCount = value.count;
        this.length = value.count;
        this.fetchOnePageData(0, this.limit);
      }
    });
  }

  onPageChange(event?: PageEvent) {
    this.pageIndex = event?.pageIndex;
    this.fetchOnePageData(this.pageIndex, this.limit);
  }

  updatePageData(pageIndex: number, limit: number) {
    const offset = pageIndex * limit;
    this.companyListSubscription = this.companyService.getCompanyWithLimitAndOffset(offset, limit).subscribe({
      next: async(value:any) => {
        this.loading = false;
        this.dataload = true;
        // const streamingJson = await StreamingHttpUtil.getStream(value);
        // const newCompanies: getCompanyData[] = JSON.parse(streamingJson);
        // const Companys: getCompanyData[]= value.company
        this.companyList = value
        this.companyCache[pageIndex] = value;
      },
      error: (error) => {
        console.error('Error fetching companies:', error);
        this.loading = false;
      },
    });
  }

  fetchOnePageData(pageIndex: number, limit: number) {
    if (pageIndex in this.companyCache) {
      this.companyList = this.companyCache[pageIndex];
    }
    else {
      if (this.isSearched)
        this.loading = true;
      const offset = pageIndex * limit;
      this.companyListSubscription = this.companyService.getCompanyWithLimitAndOffset(offset, limit).subscribe({
        next: async(value:any) => {
          this.loading = false;
          this.dataload = true;
          // const streamingJson = await StreamingHttpUtil.getStream(value);
          // const newCompanies: getCompanyData[] = JSON.parse(streamingJson);
          // const Companys: getCompanyData[]= value.company
          if (!this.isSearched)
            this.companyList = value
          this.companyCache[pageIndex] = value;
        },
        error: (error) => {
          console.error('Error fetching companies:', error);
          this.loading = false;
        },
      });
    }
  }
  
  jumpCreateVessel() {
    this.router.navigate([routing.CompanyRegistration]);
  }
  onRowClick(event: any) {
    this.selectedRowIndex = event.data.id;
    this.companyId = event.data.id;
    this.companyService.selectedCompanyID = this.companyId;
    const data = this.companyList.find((e: any) => e.id === this.companyService.selectedCompanyID);
    if(data != undefined){
      this.addToCompanyData(data);
    }
  }
  addToCompanyData(data: getCompanyData) {
    this.companyData = {
      name: data.name,
      otherName: data.otherName,
      ceo: data.ceo,
      address: data.address,
      addressLineTwo: data.addressLineTwo,
      city: data.city,
      zipCode: data.zipCode,
      telephone: data.telephone,
      fax: data.fax,
      email: data.email,
      thumbnailExtension: data.thumbnailExtension,
      businessContents: data.businessContents,
      rank: data.rank,
      country:data.country,
      types: this.companyService.getCompanyType(data),
      typeOwner: data.typeOwner,
      typeManagement: data.typeManagement,
      typeBuilder: data.typeBuilder,
      typeManufacturer: data.typeManufacturer,
      typeMaintainer: data.typeMaintainer,
      typeExOwner: data.typeExOwner,
      typeExManagement: data.typeExManagement,
    };
    this.companyService.editCompanyFlag = true;
    this.companyService.editCompanyData = this.companyData;
    this.editCompanyData();
  }
  editCompanyData(){
    // this.dialog.open(CompanyRegisterComponent,{width:'744px',height: '90vh'})
    this.dialog.open(CompanyEditComponent,{width:'744px',height: '90vh'})
  }

  searchVessel(companyName: HTMLInputElement) {
    if (!companyName.value) {
      if (this.companyCache[0]) 
        this.companyList = this.companyCache[0];
      else
        this.fetchOnePageData(0, this.limit);
      this.isSearched = false;
      return;
    }
    this.loading = true;
    this.isSearching = true;
    this.isSearched = true;
    this.companyService.getCompanyByName(companyName.value).subscribe({
      next: async(value:any) => {
        this.dataload = true;
        this.companyList = value
      },
      error: (error) => {
        console.error('Error fetching companies:', error);
      },
      complete: () => {
        this.isSearching = false;
        this.loading = false;
      }
    });
  }
  clearSearch(companyName: HTMLInputElement) {
    companyName.value = '';
    if (this.companyCache[this.pageIndex])
      this.companyList = this.companyCache[this.pageIndex];
    else
      this.fetchOnePageData(this.pageIndex, this.limit);
    this.isSearched = false;
  }
 
  //unsubscribe for to avoid memory leak
  ngOnDestroy():void{
    if (this.companyCountSubscription) {
      this.companyCountSubscription.unsubscribe();
    }
    if (this.companyListSubscription) {
      this.companyListSubscription.unsubscribe();
    }
  }
}
